import React from 'react';
import './Recursos.css';
import { Table, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import {Link, withRouter} from "react-router-dom";
import { SueniosAPDF } from './PDF/CreadorPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {Button as ButtonUI, Switch, FormControlLabel, Avatar, ButtonGroup} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import NatureIcon from '@material-ui/icons/Nature';
import { RobotIcon } from "@material-ui-extra/icons/RobotIcon";
import { blue } from '@material-ui/core/colors';
import Filtros from './Filtros';

class Suenios extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        authAxios: this.props.authAxios,
        paciente: null,
        suenios: [],
        cargado: false,
        permisos: true,
        descargarPDF: false,
        sonianteSinPaciente: false,
        drawer: false,
        ordenar: 'fecha',
        ordenAscendente: false,
        hayFiltro: false,
        permisoGPT3: false,
      }
    
    //Hacer get de paciente para obtener nombre, si rol es analista
    if(sessionStorage.rol !== 'Soñante') {
      this.state.authAxios.get(`/pacientes/${this.props.match.params.id}/`)
      .then((Response) => {
        this.setState({
          paciente: Response.data.nombre
        });
      })
      .catch((error) => {
        this.setState({permisos: false})
      });
    }

    //Determinar si el usuario tiene permiso para usar GPT3
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      this.setState({permisoGPT3: Response.data.rol.includes('PermisoGPT3')})
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }
  
  actualizarSuenios() {
    //Borrar cualquier filtro que se hubiera guardado
    sessionStorage.removeItem('filtro')

    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.match.params.id}/suenios/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/`;
    //Hacer get
    this.state.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        suenios: Response.data,
        cargado: true,
        hayFiltro: false,
      }, this.redirigidoDesdeNotificacion);
    })
    .catch((error) => {
      if (sessionStorage.rol !== 'Soñante')
        this.setState({permisos: false});
      else if (error.response.status === 404)
        this.setState({sonianteSinPaciente: true});
      else if (error.response.status === 403)
        this.setState({permisos: false});
      else
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    });  
  }

  componentDidMount(){
    let filtro = sessionStorage.getItem('filtro')
    //Si hay un filtro guardado de antes, volver a realizarlo y mostrar esos sueños
    if (filtro !== null) {
      filtro = JSON.parse(filtro)
      //Determinar rol (soñante o analista)
      let endpoint = `/pacientes/${this.props.match.params.id}/suenios/filtrar_suenios/`;
      if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/filtrar_suenios/`;
      this.props.authAxios.post(endpoint, filtro).then((Response => {
        this.setState({suenios: Response.data, hayFiltro: true, cargado: true})
      }))
      .catch((error => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      }))
    } else {
      this.actualizarSuenios()
    }
  }

  toggleDrawer() {
    this.setState({drawer: !this.state.drawer});
  }

  sueniosFiltrados(suenios) {
    if (suenios.length !== this.state.suenios.length) {
      this.setState({suenios: suenios, hayFiltro: true})
    }
    this.toggleDrawer()
  }

  cambiarOrden() {
    this.setState({ordenAscendente: !this.state.ordenAscendente})
  }

  redirectQuiron() {
    // Determinar rol
    if(sessionStorage.rol !== 'Soñante')
      this.props.history.push(`/home/pacientes/${this.props.match.params.id}/quiron/`)
    else
      this.props.history.push(`/home/quiron/`)
  }

  redirectArboles() {
    // Determinar rol
    if(sessionStorage.rol !== 'Soñante')
      this.props.history.push(`/home/pacientes/${this.props.match.params.id}/arboles/`)
    else
      this.props.history.push(`/home/arboles/`)
  }

  render() {  
    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>)

    //Cargar suenios y ordenarlos según lo elegido por usuario
    let suenios = null;
    if (this.state.ordenar === "fecha" && !this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.fecha === b.fecha) { return (a.descripcion > b.descripcion) ? -1 : 1;
    } else { return (a.fecha > b.fecha) ? -1 : 1; }})
    if (this.state.ordenar === "fecha" && this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.fecha === b.fecha) { return (a.descripcion > b.descripcion) ? 1 : -1;
    } else { return (a.fecha > b.fecha) ? 1 : -1; }})
    else if (this.state.ordenar === "descripción" && !this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.descripcion === b.descripcion) { return (a.fecha > b.fecha) ? -1 : 1;
    } else { return (a.descripcion > b.descripcion) ? -1 : 1; }})
    else if (this.state.ordenar === "descripción" && this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.descripcion === b.descripcion) { return (a.fecha > b.fecha) ? 1 : -1;
    } else { return (a.descripcion > b.descripcion) ? 1 : -1; }})
    else if (this.state.ordenar === "título" && !this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.nombre === b.nombre) { return (a.fecha > b.fecha) ? -1 : 1;
    } else { return (a.nombre > b.nombre) ? -1 : 1; }})
    else if (this.state.ordenar === "título" && this.state.ordenAscendente) suenios = this.state.suenios.sort((a, b) => {if(a.nombre === b.nombre) { return (a.fecha > b.fecha) ? 1 : -1;
    } else { return (a.nombre > b.nombre) ? 1 : -1; }})
    suenios = suenios.map((suenio) => {
      //Sacar los símbolos utilizados para ocultar información del sueño
      let desc = suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = desc;
      if (desc.length > 250) {descChica = desc.substring(0, 250) + "...";}
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(suenio.fecha + 'T00:00:00'));
      return (
        <tr key={suenio.id}>
          <td style={{display:'flex'}}>
            {suenio.imagen && window.screen.width >= 800 ? <Avatar className='mr-2 mt-1' alt={suenio.descChica} src={suenio.imagen.imagen} />:null}
            <div>
              <Link to={sessionStorage.rol === 'Soñante' ? `/home/suenios/${suenio.id}/` : `/home/pacientes/${this.props.match.params.id}/suenios/${suenio.id}/`} style={{ color: '#000' }}>{suenio.nombre}</Link>
              <p className="small">{fecha}</p>
              {suenio.imagen && window.screen.width < 800 ? <Avatar style={{height:70, width:70}} alt={suenio.descChica} src={suenio.imagen.imagen} />:null}
            </div>
          </td>
          <td><p className="text-justify">{descChica} <Link to={sessionStorage.rol === 'Soñante' ? `/home/suenios/${suenio.id}/` : `/home/pacientes/${this.props.match.params.id}/suenios/${suenio.id}/`}>Ver más</Link></p></td>
        </tr>
      )
    });

    //Ver si la lista de sueños está vacía
    let noHaySueños = null;
    if (suenios.length === 0 && this.state.cargado && !this.state.hayFiltro) noHaySueños = (<p>Aún no se ha ingresado ningún sueño.</p>);
    else if (suenios.length === 0 && this.state.cargado && this.state.hayFiltro) noHaySueños = (<p>No hay sueños que cumplan con los filtros establecidos.</p>);
    else if (this.state.cargado) noHaySueños = null;

    //Agregar botón de descarga de sueños sólo cuando se cargaron los sueños y se apreta el botón de descargar (evitar rerender)
    let PDFSuenios = (<ButtonUI color="primary" onClick={() => this.setState({descargarPDF: true})} startIcon={<GetAppIcon/>}>PDF</ButtonUI>);
    if (this.state.descargarPDF) PDFSuenios = 
    (<PDFDownloadLink document={<SueniosAPDF suenios={this.state.suenios} paciente={this.state.paciente} />} fileName={`sueños.pdf`}>
      {({ blob, url, loading, error }) => (loading ? <ButtonUI disabled startIcon={<GetAppIcon/>} variant='outlined'>Generando PDF...</ButtonUI> : <ButtonUI startIcon={<GetAppIcon/>} variant='contained' color='primary'>Click aquí!</ButtonUI>)}
    </PDFDownloadLink>);

    //Si soñante no tiene paciente acoplado, retornar otra pantalla distinta.
    if (this.state.sonianteSinPaciente)
      return (
        <div className="container-sm">
          <h1 className='display-4'>Para continuar, debes seguir uno de los siguientes pasos:</h1>
          <p>• Si vas a usar Mercurio junto con tu terapeuta, debes solicitarle que te ingrese como paciente.</p>
          <p>• Si vas a usar Mercurio en forma independiente, haz <a href='/home/inicio/'>click aquí.</a></p>
        </div>
      );
    
    //Botón de deshacer filtro si hay un filtro aplicado
    const deshacerFiltro = this.state.hayFiltro? <ButtonUI color="primary" onClick={this.actualizarSuenios.bind(this)}>Deshacer filtro</ButtonUI> : null

    //Estilo del switch de orden (ASC, DESC)
    const BlueSwitch = withStyles({
      switchBase: {
        color: blue[500],
      },
    })(Switch);

    if (!this.state.cargado)
      return (
        <div className="container-sm">
          <h1 className="display-4">Obteniendo sueños...</h1>
          <Spinner style={{ width: '3rem', height: '3rem' }}/>
        </div>
      );

    return (
      <div className="container-sm">
        <Form inline>
          <h1 className="display-4">{sessionStorage.rol === 'Soñante' ? "Sueños" : this.state.cargado ? "Sueños de " + this.state.paciente : null}</h1>
          <hr/>
          {suenios.length > 0 &&
            <ButtonGroup color="primary">
              {this.state.permisoGPT3 && <ButtonUI color="primary" onClick={this.redirectQuiron.bind(this)} startIcon={<RobotIcon/>}>Quirón</ButtonUI>}
              {<ButtonUI color="primary" onClick={this.redirectArboles.bind(this)} startIcon={<NatureIcon/>}>Árboles</ButtonUI>}
              {PDFSuenios}
            </ButtonGroup>
          }
        </Form>
        <Form inline className="mb-sm-2">
          <FormGroup>
            <Label for="ordenar" className="mr-sm-2">Ordenar por:</Label>
            <Input type="select" name="ordenar" id="ordenar" className="shadow-none" style={{border: 'none', borderBottom: '1px solid'}} value={this.state.ordenar} onChange={(e) =>{
              this.setState({ ordenar: e.target.value })
            }}>
              <option>título</option>
              <option>descripción</option>
              <option>fecha</option>
            </Input>
            <FormControlLabel 
              className="ml-sm-2"
              control={<BlueSwitch size="small" checked={this.state.ordenAscendente} onChange={this.cambiarOrden.bind(this)} color='default' name="checkedA" />}
              label={<p className="letraChica mt-sm-1">{this.state.ordenAscendente? "ASC" : "DESC"}</p>}
            />
          </FormGroup>
          <hr/>
          <div>
            {deshacerFiltro}
            <ButtonUI color="primary" onClick={this.toggleDrawer.bind(this)}>Filtrar</ButtonUI>
            <SwipeableDrawer
              anchor={'right'}
              open={this.state.drawer}
              onClose={this.toggleDrawer.bind(this)}
              onOpen={this.toggleDrawer.bind(this)}
            >
              <div className="ml-4 mr-4 mt-4 mb-4">
                <Filtros authAxios={this.props.authAxios} toggleDrawer={this.toggleDrawer.bind(this)} sueniosFiltrados={this.sueniosFiltrados.bind(this)}></Filtros>
              </div>
            </SwipeableDrawer>
          </div>
        </Form>
        <div className="scrollbarListas" id='bordeScrollbar'>
          {noHaySueños}
          <Table>
            <tbody>
              {suenios}
            </tbody>
          </Table>
        </div>
        <Form inline className="mt-4">
          <p className="lead">Cantidad de sueños: {suenios.length}</p>
          <hr/>
          <Button outline color="success" size="lg" className="margenAbajo" href={
            sessionStorage.rol === 'Soñante' ? `/home/agregar_suenio/` : `/home/pacientes/${this.props.match.params.id}/agregar_suenio/`
            }>+
          </Button>
        </Form>
      </div>
    );
  }
}

export default withRouter(Suenios);