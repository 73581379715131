import React from 'react';
import '../Recursos.css';
import { Button, Table, Collapse, Spinner } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

class ListaSueniosFiltrados extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      suenios: [],
      toggleLista: false,
      cargado: false,
    };
  }

  componentDidMount(){}

  realizarFiltro(){
    let filtro = {}
    if (this.props.simbolo) filtro.simbolos = [this.props.simbolo]
    if (this.props.sentimiento) filtro.sentimientos = [this.props.sentimiento]
    if (this.props.fecha_suenio_hasta) filtro.fecha_suenio_hasta = this.props.fecha_suenio_hasta
    //Determinar rol (soñante o analista)
    let endpoint = `/suenios/filtrar_suenios/`;
    if(sessionStorage.rol === 'Analista') endpoint = `/pacientes/${this.props.idPaciente}/suenios/filtrar_suenios/`;
    this.props.authAxios.post(endpoint, filtro).then((Response => {
        this.setState({suenios: Response.data, toggleLista: true, cargado: true})
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  }

  toggleLista() {
      if (!this.state.toggleLista && !this.state.cargado) this.realizarFiltro()
      else this.setState({toggleLista: !this.state.toggleLista})
  }

  render() {
    //Cargar suenios
    let suenios = this.state.suenios.sort((a, b) => {if(a.fecha === b.fecha) { return (a.descripcion > b.descripcion) ? -1 : 1;
    } else { return (a.fecha > b.fecha) ? -1 : 1; }}).map((suenio) => {
        //Sacar los símbolos utilizados para ocultar información del sueño
        let desc = suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
        //Achicar descripcion a a 250 caracteres y concatenar '...'
        let descChica = desc;
        if (desc.length > 250) {descChica = desc.substring(0, 250) + "...";}
        //Fecha en formato lindo
        const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(suenio.fecha + 'T00:00:00'));
        return (
            <tr key={suenio.id}>
                <td><Link style={{ color: '#000' }} onClick={() => this.props.cargarSuenioSeleccionado(suenio, desc, fecha)}>{suenio.nombre}</Link><p className="letraChica">{fecha}</p></td>
                <td><p className="text-justify">{descChica}<Button color="link" size="sm" className="active" onClick={() => this.props.cargarSuenioSeleccionado(suenio, desc, fecha)}>Ver más</Button></p></td>
            </tr>
        )
    });
    if (this.props.cantidad && this.state.cargado) suenios = suenios.slice(0, this.props.cantidad)
    
    return (
    <div style={{all: 'unset'}}>
        <Link size="sm" className="active" onClick={this.toggleLista.bind(this)}>{this.state.toggleLista? 'Ocultar sueños' : 'Mostrar sueños'}</Link>
        <Collapse isOpen={this.state.toggleLista}>
          <div className="contorno scrollbarListas" style={{height: '35vh'}} id='bordeScrollbar'>
            <Table>
                <tbody>
                {suenios}
                </tbody>
            </Table>
          </div>
        </Collapse>
    </div>
    );
  }

}

export default withRouter(ListaSueniosFiltrados);