import React from 'react';
import '../Recursos.css';
import { withRouter } from "react-router-dom";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Pie, PieChart, Cell, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, RadialBarChart, RadialBar } from 'recharts';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Form } from 'reactstrap';

const MAX = 3;

class VisualizadorInforme extends React.Component {

  constructor(props){
    super(props);

    let simbolos = this.contarSimbolosMasFrecuentes();

    this.state = {
      sueniosPorMesYAnio: this.contarSueniosPorMesYAnio(),
      cantActivosPasivos: this.contarActivosPasivos(),
      cantSueniosPorPaciente: this.contarSueniosPorPaciente(),
      simbolos: simbolos,
      simbolosMasFrecuentes: simbolos.slice(0, MAX),
      simbolosPorAnio: [],
      simbolosPorPaciente: [],
      estadisticasJerarquias: {},
      todosPacientes: [],
      usarProporcion: true,
    }
  };

  componentDidMount() {
    this.contarSimbolosPorAnio();
    this.contarSimbolosPorPaciente();
    this.obtenerEstadisticasJerarquias();
    this.getPacientes();
  }

  // Contar los sueños por mes y año, y guardarlo en un array de objectos con el formato [{name: "enero 2019", value: 2}, ...]
  contarSueniosPorMesYAnio() {
    let sueniosPorMesYAnio = [];
    for(const suenio of this.props.informe.suenios) {
      let yaEstaba = false;
      for(let s of sueniosPorMesYAnio) {
        let date = new Date(suenio.fecha);
        if (s.name === date.toLocaleString('es-ES', { year: 'numeric'})) {
          s.cantidad++;
          yaEstaba = true;
        }
      }
      if(!yaEstaba) {
        let date = new Date(suenio.fecha);
        sueniosPorMesYAnio = [...sueniosPorMesYAnio, {name: date.toLocaleString('es-ES', { year: 'numeric'}), cantidad: 1}]
      }
    }

    // Ordenar el array de objectos por fecha
    sueniosPorMesYAnio.sort((a, b) => {
      let dateA = new Date(a.name);
      let dateB = new Date(b.name);
      return dateA < dateB ? -1 : 1;
    });
    return sueniosPorMesYAnio;
  }

  // Contar los sueños activos y pasivos, y guardarlo en un array de objectos con el formato [{name: "activos", value: 2}, ...]
  contarActivosPasivos() {
    let cantActivosPasivos = [];
    for(const suenio of this.props.informe.suenios) {
      let yaEstaba = false;
      let tipo = suenio.es_pasivo ? "pasivos" : "activos";
      for(let s of cantActivosPasivos) {
        if (s.name === tipo) {
          s.cantidad++;
          yaEstaba = true;
        }
      }
      if(!yaEstaba) {
        cantActivosPasivos = [...cantActivosPasivos, {name: tipo, cantidad: 1}]
      }
    }
    return cantActivosPasivos;
  }

  // Contar cantidad de sueños por paciente y guardarlo en un array de objectos con el formato [{name: "paciente1", value: 2}, ...]
  contarSueniosPorPaciente() {
    let cantSueniosPorPaciente = [];
    for(const suenio of this.props.informe.suenios) {
      let yaEstaba = false;
      for(let s of cantSueniosPorPaciente) {
        if (s.name === suenio.paciente.nombre) {
          s.cantidad++;
          yaEstaba = true;
        }
      }
      if(!yaEstaba) {
        cantSueniosPorPaciente = [...cantSueniosPorPaciente, {name: suenio.paciente.nombre, cantidad: 1}]
      }
    }
    return cantSueniosPorPaciente;
  }

  contarSimbolosMasFrecuentes() {
    // Tomar los 5 simbolos más frecuentes, el suenio contiene una lista de símbolos que aparecen una vez
    let simbolos = [];
    for(const suenio of this.props.informe.suenios) {
      for(const simbolo of suenio.simbolos) {
        let yaEstaba = false;
        for(let c of simbolos) {
          if (c[0] === simbolo.nombre) {
            c[1]++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) simbolos = [...simbolos, [simbolo.nombre, 1]]
      }
    }
    simbolos = simbolos.sort((a, b) => a[1] < b[1] ? 1 : -1);
    return simbolos;
  }

  // Contar los cinco simbolos más frecuentes, y distribuirlos por año y guardarlo en un array de objectos con el formato [{name: "simbolo1", {name: 2019, value: 2}, ...]
  contarSimbolosPorAnio() {
    let simbolosPorAnio = [];
    let simbolos = this.state.simbolosMasFrecuentes;
    for(const simbolo of simbolos) {
      for(const suenio of this.props.informe.suenios) {
        let yaEstaba = false;
        let date = new Date(suenio.fecha);
        for (let s of simbolosPorAnio) {
          if (s.name === date.toLocaleString('es-ES', { year: 'numeric'})) {
            if (!s[simbolo[0]]) {
              s[simbolo[0]] = 0;
            }
            if (suenio.simbolos.map(a => a.nombre).includes(simbolo[0])) {
              s[simbolo[0]]++;
            }
            yaEstaba = true;
          }
        }
        if(!yaEstaba) {
          let date = new Date(suenio.fecha);
          simbolosPorAnio = [...simbolosPorAnio, {name: date.toLocaleString('es-ES', { year: 'numeric'})}]
          if (!simbolosPorAnio[simbolosPorAnio.length-1][simbolo[0]]) {
            simbolosPorAnio[simbolosPorAnio.length-1][simbolo[0]] = 0;
          }
          if (suenio.simbolos.map(a => a.nombre).includes(simbolo[0])) {
            simbolosPorAnio[simbolosPorAnio.length-1][simbolo[0]]++;
          }
        }
      }
    }

    if (this.state.usarProporcion) {
      // Contar la cantidad de sueños por año y dividir por la cantidad de veces que aparece cada simbolo en ese año
      let cantSueniosPorAnio = [];
      for(const suenio of this.props.informe.suenios) {
        let yaEstaba = false;
        let date = new Date(suenio.fecha);
        for(let s of cantSueniosPorAnio) {
          if (s.name === date.toLocaleString('es-ES', { year: 'numeric'})) {
            s.cantidad++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) {
          cantSueniosPorAnio = [...cantSueniosPorAnio, {name: date.toLocaleString('es-ES', { year: 'numeric'}), cantidad: 1}]
        }
      }
      for (let s of simbolosPorAnio) {
        for (let c of cantSueniosPorAnio) {
          if (s.name === c.name) {
            for (let simbolo of simbolos) {
              if (s[simbolo[0]]) {
                s[simbolo[0]] = ((s[simbolo[0]] / c.cantidad) * 100).toFixed(2);
              }
            }
          }
        }
      }
    }

    simbolosPorAnio.sort((a, b) => {
      let dateA = new Date(a.name);
      let dateB = new Date(b.name);
      return dateA < dateB ? -1 : 1;
    });
    this.setState({simbolosPorAnio: simbolosPorAnio});
  }

  contarSimbolosPorPaciente() {
    let simbolosPorPaciente = [];
    let simbolos = this.state.simbolosMasFrecuentes;
    for(const simbolo of simbolos) {
      for(const suenio of this.props.informe.suenios) {
        let yaEstaba = false;
        for (let s of simbolosPorPaciente) {
          if (s.name === suenio.paciente.nombre) {
            if (!s[simbolo[0]]) {
              s[simbolo[0]] = 0;
            }
            if (suenio.simbolos.map(a => a.nombre).includes(simbolo[0])) {
              s[simbolo[0]]++;
            }
            yaEstaba = true;
          }
        }
        if(!yaEstaba) {
          simbolosPorPaciente = [...simbolosPorPaciente, {name: suenio.paciente.nombre}]
          if (!simbolosPorPaciente[simbolosPorPaciente.length-1][simbolo[0]]) {
            simbolosPorPaciente[simbolosPorPaciente.length-1][simbolo[0]] = 0;
          }
          if (suenio.simbolos.map(a => a.nombre).includes(simbolo[0])) {
            simbolosPorPaciente[simbolosPorPaciente.length-1][simbolo[0]]++;
          }
        }
      }
    }
    this.setState({simbolosPorPaciente: simbolosPorPaciente});
  }

  obtenerEstadisticasJerarquias() {
    //Determinar rol (soñante o analista)
    let endpoint = `/analisis_por_filtro_analista/${this.props.match.params.id}/estadisticas_jerarquias/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/analisis_por_filtro_soniante/${this.props.match.params.id}/estadisticas_jerarquias/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        estadisticasJerarquias: Response.data,
      })
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  getPacientes() {
    if (sessionStorage.rol === 'Analista') {
      let pacientes = [];
      this.props.authAxios.get(`/pacientes/`)
      .then((Response) => {
        this.setState({
          todosPacientes: Response.data,
        })
      })
      .catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      });
    } else {
      this.setState({
        todosPacientes: this.props.informe.suenios.length > 0? [this.props.informe.suenios[0].paciente] : []
      })
    }
  }

  calcularEdadPacientes(todos = false) {
    let pacientes = [];
    if (todos) {
      pacientes = this.state.todosPacientes.map(p => [p.fecha_nacimiento ? new Date(p.fecha_nacimiento) : null, new Date()]);
    } else {
      for (const suenio of this.props.informe.suenios) {
        pacientes.push([suenio.paciente.fecha_nacimiento ? new Date(suenio.paciente.fecha_nacimiento): null, new Date(suenio.fecha)]);
      }
    }
    let sumaEdad = 0;
    let cantPacientes = 0;
    for (const paciente of pacientes) {
      if (paciente[0]) {
        let fechaNac = paciente[0];
        let fechaActual = paciente[1];
        let edad = fechaActual.getFullYear() - fechaNac.getFullYear();
        let mes = fechaActual.getMonth() - fechaNac.getMonth();
        if (mes < 0 || (mes === 0 && fechaActual.getDate() < fechaNac.getDate())) {
          edad--;
        }
        sumaEdad += edad;
        cantPacientes++;
      }
    }
    return cantPacientes === 0 ? 0 : sumaEdad / cantPacientes;
  }

  calcularTiempoTerapiaPacientes(todos = false) {
    let pacientes = [];
    if (todos) {
      pacientes = this.state.todosPacientes.map(p => [p.fecha_ingreso ? new Date(p.fecha_ingreso) : null, new Date()]);
    } else {
      for (const suenio of this.props.informe.suenios) {
        pacientes.push([suenio.paciente.fecha_ingreso ? new Date(suenio.paciente.fecha_ingreso): null, new Date(suenio.fecha)]);
      }
    }
    console.log(pacientes);
    let sumaEdad = 0;
    let cantPacientes = 0;
    for (const paciente of pacientes) {
      if (paciente[0]) {
        let fechaNac = paciente[0];
        let fechaActual = paciente[1];
        let edad = fechaActual.getFullYear() - fechaNac.getFullYear();
        let mes = fechaActual.getMonth() - fechaNac.getMonth();
        if (mes < 0 || (mes === 0 && fechaActual.getDate() < fechaNac.getDate())) {
          edad--;
        }
        sumaEdad += edad;
        cantPacientes++;
      }
    }
    return cantPacientes === 0 ? 0 : sumaEdad / cantPacientes;
  }

    // Contar los sueños activos y pasivos, y guardarlo en un array de objectos con el formato [{name: "activos", value: 2}, ...]
    contarPorGenero() {
      let cantPorGenero = [];
      for(const suenio of this.props.informe.suenios) {
        let yaEstaba = false;
        let tipo = suenio.paciente.sexo;
        for(let s of cantPorGenero) {
          if (s.name === tipo) {
            s.cantidad++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) {
          cantPorGenero = [...cantPorGenero, {name: tipo, cantidad: 1}]
        }
      }
      return cantPorGenero;
    }

  render() {
      let cantSueniosPorPaciente = this.state.cantSueniosPorPaciente.length > 1 ?
      (<div>
      <div className="centrar2">
        <h5 className="mt-2 mb-4 text-justify">Distribución de sueños por paciente</h5>
        <BarChart width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.4} height={window.screen.height/2} data={this.state.cantSueniosPorPaciente}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"/>
          <YAxis />
          <Tooltip />
          <Bar dataKey="cantidad" fill="#2884d8" />
        </BarChart>
      </div></div>) : null

      const COLORES = ["#13B000","#00E1DE","#E1005C","#E18F00","#0026E1", "#84FC76","#7CFFFD","#FD88B8","#FFC45E","#7990FF"]

      let simbolosPorAnio = this.state.simbolosPorAnio.length > 0 ?
      (<BarChart width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.7} height={window.screen.height/2} data={this.state.simbolosPorAnio}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"/>
        <YAxis />
        <Tooltip />
        <Legend />
        {this.state.simbolosMasFrecuentes.map((simbolo, index) => {
          return <Bar key={index} dataKey={simbolo[0]} fill={COLORES[index % 10]} />
        })}
      </BarChart>) : null

      let simbolosPorPaciente = this.state.simbolosPorAnio.length > 0 && this.state.simbolosPorPaciente.length > 1 ?
      (<div>
      <div className="centrar2">
        <h5 className="mt-2 mb-4 text-justify">Símbolos más frecuentes, distribuidos por paciente</h5>
        <BarChart width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.4} height={window.screen.height/2} data={this.state.simbolosPorPaciente}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"/>
          <YAxis />
          <Tooltip />
          <Legend />
          {this.state.simbolosMasFrecuentes.map((simbolo, index) => {
            return <Bar key={index} dataKey={simbolo[0]} fill={COLORES[index % 10]} />
          })}
        </BarChart>
      </div></div>) : null
    
      let estadisticasJerarquias = Object.keys(this.state.estadisticasJerarquias).length > 0 ?
      (<div className="centrar2">
        <h5 className="mt-2 mb-4 text-justify">Estadisticas según clasificación</h5>
        <RadarChart outerRadius={70} width={730} height={window.screen.height/4} data={this.state.estadisticasJerarquias[1]}>
          <PolarGrid />
          <PolarAngleAxis dataKey="raiz" />
          <PolarRadiusAxis angle={30} />
          <Tooltip />
          <Radar name="cantidad" dataKey="cantidad" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
      </div>) : null

      var tooltip
      const CustomTooltip = ({ active, payload }) => {
          if (!active || !tooltip)    return null
          for (const bar of payload)
              if (bar.dataKey === tooltip)
                  return <div style={{backgroundColor: "white", border: '1px solid #ccc'}}>{ bar.value.toFixed(2) }</div>
          return null
      }

      return(
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="centrar2">
                <h5 className="mt-2 mb-4 text-justify">Distribución de sueños por año</h5>
                <BarChart width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.4} height={window.screen.height/2} data={this.state.sueniosPorMesYAnio}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name"/>
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="cantidad" fill="#8884d8" />
                </BarChart>
              </div>
            </div>
            <div className="col-md-6">
              <div className="centrar2">
                <h5 className="mt-2 mb-4 text-justify">Ego onírico pasivo vs activo</h5>
                <PieChart className="mb-2" width={window.screen.width < 800 ? window.screen.width*0.9 : 730} height={window.screen.height/5}>
                  <Tooltip />
                  <Legend />
                  <Pie data={this.state.cantActivosPasivos} dataKey="cantidad" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                    {this.state.cantActivosPasivos.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.name === 'activos'? "#00E553" : "#959595"} />
                    ))}
                  </Pie>
                </PieChart>
                {estadisticasJerarquias}
              </div>
            </div>
          </div>
          <hr/>
          <div className="centrar2">
            <h5 className="mt-2 mb-4 text-justify">Símbolos más frecuentes, distribuidos por año</h5>
            <Autocomplete
              style={{width: window.screen.width*0.5}}
              className='mb-2'
              multiple
              id="tags-standard"
              options={this.state.simbolos}
              getOptionLabel={option => option[0] + " (" + option[1] + ")"}
              defaultValue={this.state.simbolos.slice(0,MAX)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Elegir símbolos a ver"
                />
              )}
              onChange={(_, value) => {this.setState({simbolosMasFrecuentes: value}, () => {this.contarSimbolosPorAnio(); this.contarSimbolosPorPaciente()})}}
            />
            <Form>
              <input type="checkbox" className="ml-2" onChange={() => this.setState({usarProporcion: !this.state.usarProporcion}, () => {this.contarSimbolosPorAnio()})} checked={this.state.usarProporcion}/>
              <label className="ml-2">Usar proporciones</label>
            </Form>
            {simbolosPorAnio}
          </div>
          {sessionStorage.rol === 'Analista'? <hr/> : null}
          <div className="row mb-4">
            <div className="col-md-6">
              {cantSueniosPorPaciente}
            </div>
            <div className="col-md-6">
              {simbolosPorPaciente}
            </div>
          </div>
          <hr/>
          <div className="row mb-4">
            <div className="col-md-6">
              {this.state.todosPacientes.length > 0 ?
              (<div className="centrar2">
                <h5 className="mt-2 mb-4 text-justify">Promedio de edades</h5>
                <RadialBarChart 
                  width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.4} 
                  height={250} 
                  innerRadius="30%" 
                  outerRadius="80%" 
                  data={[
                    {
                      "name": this.state.todosPacientes.length > 1? "Todos" : "Edad actual",
                      "edad": this.calcularEdadPacientes(true),
                      "fill": "gray"
                    },
                    {
                      "name": "En el informe",
                      "edad": this.calcularEdadPacientes(),
                      "fill": "#8884d8"
                    }]} 
                  startAngle={180} 
                  endAngle={0}
                >
                  <RadialBar onMouseOver={ () => tooltip="edad" } minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='edad' />
                  <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="left" />
                  <Tooltip content={<CustomTooltip/>} />
                </RadialBarChart>
              </div>) : null}
            </div>
            <div className="col-md-6">
              {this.state.todosPacientes.length > 0 ?
              (<div className="centrar2">
                <h5 className="mt-2 mb-4 text-justify">Promedio de tiempo desde inicio de terapia</h5>
                <RadialBarChart 
                  width={window.screen.width < 800 ? window.screen.width*0.9 : window.screen.width*0.4} 
                  height={250} 
                  innerRadius="30%" 
                  outerRadius="80%" 
                  data={[
                    {
                      "name": this.state.todosPacientes.length > 1? "Todos" : "Tiempo actual",
                      "edad": this.calcularTiempoTerapiaPacientes(true),
                      "fill": "gray"
                    },
                    {
                      "name": "En el informe",
                      "edad": this.calcularTiempoTerapiaPacientes(),
                      "fill": "#8884d8"
                    }]} 
                  startAngle={180} 
                  endAngle={0}
                >
                  <RadialBar onMouseOver={ () => tooltip="edad" } minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='edad' />
                  <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
                  <Tooltip content={<CustomTooltip/>} />
                </RadialBarChart>
              </div>) : null}
            </div>
          </div>
          <hr/>
          <div className="centrar2">
            <h5 className="mt-2 mb-4 text-justify">Género de los pacientes de cada sueño</h5>
            <PieChart className="mb-2" width={window.screen.width < 800 ? window.screen.width*0.9 : 730} height={window.screen.height/5}>
              <Tooltip />
              <Legend />
              <Pie data={this.contarPorGenero()} dataKey="cantidad" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                {this.state.cantActivosPasivos.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORES[index]} />
                ))}
              </Pie>
            </PieChart>
          </div>
        </div>
      )
  }
}

export default withRouter(VisualizadorInforme);