import React from 'react';
import {Document, Page, Text, Image, Font, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer'

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  line: {
      borderBottom:'1 solid #000',
  },
  mainTitle: {
    marginTop: 20,
    fontSize: 48,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  negrita: {
    fontFamily: 'Oswald',
    fontWeight: 'bold',
  }
});

function suenioAPag(props) {
    //Generar listas de términos
    const simbolos = props.suenio.simbolos.map((simbolo, i) => 
    {if (i !== props.suenio.simbolos.length - 1) return (simbolo.nombre + ", "); return (simbolo.nombre + '.')});
    const sentimientos = props.suenio.sentimientos.map((sentimiento, i) => 
    {if (i !== props.suenio.sentimientos.length - 1) return (sentimiento.nombre + ", "); return (sentimiento.nombre + '.')});
    const contextos = props.suenio.contextos.map((contexto, i) => 
    {if (i !== props.suenio.contextos.length - 1) return (contexto.nombre + ", "); return (contexto.nombre + '.')});

    //Generar frases con las listas de términos
    let lSimbolos = null;
    if (simbolos.length > 0) lSimbolos = (<Text style={styles.text}>• Símbolos: {simbolos}</Text>);
    let lSentimientos = null;
    if (sentimientos.length > 0) lSentimientos = (<Text style={styles.text}>• Sentimientos/emociones: {sentimientos}</Text>);
    let lContextos = null;
    if (contextos.length > 0) lContextos = (<Text style={styles.text}>• Contextos del soñante: {contextos}</Text>);

    return(
    <Page style={styles.body} key={props.suenio.id}>
        <Text style={styles.header} fixed>Mercurio</Text>
        <Text style={styles.title}>{props.suenio.nombre}</Text>
        <Text style={styles.author}>{props.paciente? props.paciente + " - " : null}{props.suenio.fecha}</Text>
        {props.suenio.imagen && <Image style={styles.image} src={{ uri: props.suenio.imagen.imagen, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}/>}
        <Text style={styles.text}>{props.suenio.descripcion}</Text>
        <Text style={styles.line}></Text>
        <Text style={styles.subtitle}>Información del sueño:</Text>
        {lSimbolos}
        {lSentimientos}
        {lContextos}
        <Text style={styles.text}>• El rol del soñante en el sueño es {props.suenio.es_pasivo? "pasivo" : "activo"}.</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>)
}

const SuenioAPDF = (props) => {
    return(
    <Document>
        {suenioAPag(props)}
    </Document>
)};

const SueniosAPDF = (props) => {
    return(
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>Mercurio</Text>
        <Text style={styles.mainTitle}>Libro de sueños</Text>
        <Image style={styles.image} src="/logo-removebg.png"/>
        <Text style={styles.author}>Creado el {new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "long", day: "numeric"}).format(new Date())}</Text>
      </Page>
        {props.suenios.sort((a, b) => a.fecha > b.fecha ? 1 : -1).map((suenio) => {
          //Copiar suenio y desligarlos completamente
          let s = JSON.parse(JSON.stringify(suenio));
          //Fecha linda
          s.fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(s.fecha + 'T00:00:00'))
          //Sacar los símbolos utilizados para ocultar información del sueño
          s.descripcion = s.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
          const datos = {suenio: s, paciente: props.paciente}; 
          return(suenioAPag(datos))})}
    </Document>
    )
};

const InformeAPDF = (props) => {
  var simbolos = props.simbolosTotales.slice(0, props.max).map((c, index) => {
    return <Text style={styles.text}>{`${index+1}. ${c[0]} apareció ${c[1] + (c[1] > 1 ? " veces." : " vez.")}`}</Text>});
  var sentimientos = props.sentimientosTotales.slice(0, props.max).map((s, index) => {
    return <Text style={styles.text}>{`${index+1}. ${s[0]} apareció ${s[1] + (s[1] > 1 ? " veces." : " vez.")}`}</Text>})
  var contextos = props.contextosTotales.slice(0, props.max).map((c, index) => {
    return <Text style={styles.text}>{`${index+1}. ${c[0]} apareció ${c[1] + (c[1] > 1 ? " veces." : " vez.")}`}</Text>})
  var sobreSimbolos = <Text style={styles.text}>No hay ningún símbolo en los sueños retornados.</Text>
  if (simbolos.length > 0) sobreSimbolos = <Text style={styles.text}>{`En total, hay ${props.simbolosTotales.length} símbolos diferentes en los sueños retornados.\n A continuación se listan los ${simbolos.length} más recurrentes:`}</Text>;
  var sobreSentimientos = <Text style={styles.text}>No hay ningún sentimiento/emoción en los sueños retornados.</Text>
  if (sentimientos.length > 0) sobreSentimientos = <Text style={styles.text}>{`En total, hay ${props.sentimientosTotales.length} sentimientos/emociones diferentes en los sueños retornados.\n A continuación se listan los ${sentimientos.length} más recurrentes:`}</Text>;
  var sobreContextos = <Text style={styles.text}>No hay ningún contexto en los sueños retornados.</Text>
  if (contextos.length > 0) sobreContextos = <Text style={styles.text}>{`En total, hay ${props.contextosTotales.length} contextos diferentes en los sueños retornados.\n A continuación se listan los ${contextos.length} más recurrentes:`}</Text>;
  
  return(
  <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>Mercurio</Text>
        <Text style={styles.title}>{props.informe.descripcion}</Text>
        <Text style={styles.author}>Fecha de creación: {new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit"}).format(new Date(props.informe.fecha_creacion))} - Cantidad de sueños retornados: {props.informe.suenios.length}</Text>
        <Text style={styles.subtitle}>Sobre símbolos...</Text>
        {sobreSimbolos}
        {simbolos}
        <Text style={styles.subtitle}>Sobre sentimientos/emociones...</Text>
        {sobreSentimientos}
        {sentimientos}
        <Text style={styles.subtitle}>Sobre contextos...</Text>
        {sobreContextos}
        {contextos}
      </Page>
      {props.informe.suenios.sort((a, b) => a.fecha > b.fecha ? 1 : -1).map((suenio) => {
        //Copiar suenio y desligarlos completamente
        let s = JSON.parse(JSON.stringify(suenio));
        //Fecha linda
        s.fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(s.fecha + 'T00:00:00'))
        //Sacar los símbolos utilizados para ocultar información del sueño
        s.descripcion = s.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
        const datos = {suenio: s, paciente: s.paciente.nombre}; 
        return(suenioAPag(datos))})}
  </Document>
  )
};

const ChatAPDF = (props) => {

  function parsearIdsSuenios(texto) {
    // Todos los /numero/ se reemplazan por el numero sin las barras
    let regex = /\/\d+\//g;
    let match = texto.match(regex);
    if (match === null) return texto;
    let textoParseado = texto;
    match.forEach((numero) => {
      let numeroParseado = numero.replace(/\//g, '');
      textoParseado = textoParseado.replace(numero, numeroParseado);	
    });
    return textoParseado;
  }

  return(
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>Mercurio</Text>
      <Text style={styles.title}>Chat con Quirón</Text>
      <Text style={styles.author}>{props.fecha}</Text>
      {props.chat.map((mensaje) => {
        if (mensaje.es_usuario) {
          return <Text key={mensaje.id} style={styles.text}><Text style={styles.negrita}>Yo:</Text> {mensaje.contenido}</Text>
        } else {
          return <Text key={mensaje.id} style={styles.text}><Text style={styles.negrita}>Quirón:</Text> {parsearIdsSuenios(mensaje.contenido)}</Text>
        }
      })}
    </Page>
  </Document>
  )
};

export {SuenioAPDF, SueniosAPDF, InformeAPDF, ChatAPDF};