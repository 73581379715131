import React from 'react';
import '../Recursos.css';
import {Button, Card, CardContent, TextField} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const styles = ((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

class Cuestionario extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      steps: this.props.preguntas.map((pregunta) => pregunta.pregunta),
      activeStep: 0,
      preguntas: this.props.preguntas,
    };
  }

  async handleNext() {
    await this.actualizarRespuesta();
    if (this.state.activeStep === this.state.steps.length - 1) {
      this.props.terminarCuestionario();
      return;
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  async handleBack() {
    await this.actualizarRespuesta();
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  };

  async actualizarRespuesta() {
    if (this.state.preguntas[this.state.activeStep].respuesta === null) return;
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    await this.props.authAxios.put(endpoint, {
        respuesta: this.state.preguntas[this.state.activeStep].respuesta === "" ? null : this.state.preguntas[this.state.activeStep].respuesta
      }
    ).catch((error => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      })
    );
  }

  cambiarMeGusta(value) {
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    this.props.authAxios.put(endpoint, {
      me_gusta: this.state.preguntas[this.state.activeStep].me_gusta === value ? null : value
    }).catch((error => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      })
    );
    let {preguntas} = this.state;
    preguntas[this.state.activeStep].me_gusta = this.state.preguntas[this.state.activeStep].me_gusta === value ? null : value;
    this.setState({preguntas: preguntas});
  }

  descartarPregunta() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/preguntas/${this.state.preguntas[this.state.activeStep].id}/`;
    this.props.authAxios.delete(endpoint).then((Response => {
      let { preguntas } = this.state;
      preguntas = preguntas.filter(p => p.id !== this.state.preguntas[this.state.activeStep].id);
      let { steps } = this.state;
      steps = steps.filter(s => s !== this.state.steps[this.state.activeStep]);
      this.setState({preguntas: preguntas, steps: steps, activeStep: this.state.activeStep < this.state.steps.length - 1 ? this.state.activeStep : this.state.activeStep - 1});
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  } 

  async handleKeyPress(event) {
    if (event.key === 'Escape') {
      await this.actualizarRespuesta();
      this.props.terminarCuestionario();
    }
  }

  componentDidMount(){
    this.nameInput.focus(); 
 }

  render() {
    const { classes } = this.props;

    return (
      <Card
        className="mb-2 bg-light shadow btnSinBorde"
        onKeyDown={(e) => this.handleKeyPress(e)}
        tabIndex="0"
        ref={(input) => { this.nameInput = input; }}
      >
        <CardContent>
          <div className={classes.root}>
            <Stepper activeStep={this.state.activeStep} className='bg-light'>
              {this.state.steps.map((label, index) => {
                const stepProps = {};
                if (this.state.preguntas[index].respuesta === null) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel style={{cursor: 'pointer'}} onClick={() => {this.actualizarRespuesta(); this.setState({activeStep: index})}}/>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              <p className="lead mb-4">{this.state.preguntas[this.state.activeStep].pregunta}</p>
              <TextField
                label="Respuesta"
                multiline
                variant="outlined"
                fullWidth
                id={this.state.preguntas[this.state.activeStep].pregunta}
                className="mb-2 bg-white"
                value={this.state.preguntas[this.state.activeStep].respuesta? this.state.preguntas[this.state.activeStep].respuesta : "" }
                placeholder="Escribir respuesta..."
                onChange={(e) => {
                  let preguntas = this.state.preguntas;
                  preguntas[this.state.activeStep].respuesta = e.target.value;
                  this.setState({ preguntas });
                }}
                />
              <div className='derecha'><Button size="small" onClick={this.descartarPregunta.bind(this)} className='mb-4' variant="outlined" color="secondary">
                Descartar pregunta
              </Button></div>
              <div className="d-flex justify-content-between mt-2">
                <div className='mr-2'>
                  <small className='mr-2'><i>¿Qué te pareció esta pregunta?</i></small>
                  <IconButton aria-label="ThumbUp" style={{ color: this.state.preguntas[this.state.activeStep].me_gusta ? 'green' : '' }} size='small' onClick={() => {this.cambiarMeGusta(true)}} className='btnSinBorde'>
                    <ThumbUpIcon fontSize="inherit"/>
                  </IconButton>
                  <IconButton className='mr-2 btnSinBorde' aria-label="ThumbDown" color={this.state.preguntas[this.state.activeStep].me_gusta === null ? 'default' : !this.state.preguntas[this.state.activeStep].me_gusta ? 'secondary' : 'default'} size='small' onClick={() => {this.cambiarMeGusta(false)}}>
                    <ThumbDownIcon fontSize="inherit"/>
                  </IconButton>
                </div>
                <div className={window.screen.width >= 800 ? '' : 'centrar2 ml-4'}>
                  <Button size="small" disabled={this.state.activeStep === 0} onClick={this.handleBack.bind(this)} className={classes.button}>
                    Anterior
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext.bind(this)}
                    className={classes.button}
                  >
                    {this.state.activeStep === this.state.steps.length - 1 ? 'Terminar' : 'Siguiente'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(withStyles(styles)(Cuestionario));