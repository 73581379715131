import React, { Component } from 'react';
import './Recursos.css';
import {withRouter, Link} from "react-router-dom";
import {Button as ButtonUI, List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Divider,} from '@material-ui/core';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, ButtonGroup, Toast, ToastBody, ToastHeader} from 'reactstrap';

class SugerirSimbolos extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      historia: {'historia': this.props.historia},
      simbolos: [],
      simbolosSeleccionados: [],
      estadisticas: {'calificacion': null, 'diferencia': null},
      modal: true,
    //   modalCalificacion: false,
    }
  }

  componentDidMount() {
    this.props.authAxios.post('/sol/sugerir_simbolos/', this.state.historia).then((Response => {
        this.setState({simbolos: Response.data})
    }))
  }

  agregarSimbolo(simbolo) {
    let { simbolosSeleccionados } = this.state
    if (simbolosSeleccionados.includes(simbolo))
        this.setState({simbolosSeleccionados: simbolosSeleccionados.filter(s => s !== simbolo)})
    else
        this.setState({simbolosSeleccionados: [...simbolosSeleccionados, simbolo]})
  }

//   calificarSugerencias(num) {
//     let {estadisticas} = this.state
//     estadisticas.calificacion = num
//     this.setState({estadisticas})
//   }

//   toggleCalificacion() {
//       this.setState({modal: false, modalCalificacion: true})
//   }

  render() {
    //Cargar simbolos
    let simbolos = this.state.simbolos.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((s) => {
        return (
            <div key={s.id}>
            <ListItem>
                <ListItemText id={s.nombre} primary={s.nombre} />
                <ListItemSecondaryAction>
                    <Checkbox
                        edge="end"
                        onChange={this.agregarSimbolo.bind(this, s.nombre)}
                        inputProps={{ 'aria-labelledby': s.nombre }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider component="li" />
            </div>
        )})
    if (simbolos.length == 0) simbolos = "No se encontró ningún símbolo para sugerir. ¡Prueba escribiendo más detalles del contenido del sueño!"
    return (
    <div>
        <Modal isOpen={this.state.modal} toggle={this.props.toggle.bind(this.props.father, {'calificacion': this.state.simbolos.length, 'diferencia': this.state.simbolos.length - this.state.simbolosSeleccionados.length})}>
        <ModalHeader toggle={this.props.toggle.bind(this.props.father, {'calificacion': this.state.simbolos.length, 'diferencia': this.state.simbolos.length - this.state.simbolosSeleccionados.length})}>Seleccione los símbolos que aplican</ModalHeader>
        <ModalBody>
          <List className="scrollbarListas" id='bordeScrollbar'>
              {simbolos}
          </List>
        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={this.props.confirmarSugerencias.bind(this.props.father, this.state.simbolosSeleccionados, {'calificacion': this.state.simbolos.length, 'diferencia': this.state.simbolos.length - this.state.simbolosSeleccionados.length})}>Confirmar</Button>
            <Button color="danger" onClick={this.props.toggle.bind(this.props.father, {'calificacion': this.state.simbolos.length, 'diferencia': this.state.simbolos.length - this.state.simbolosSeleccionados.length})}>Cancelar</Button>
        </ModalFooter>
        </Modal>
        {/* <Modal isOpen={this.state.modalCalificacion} className='ml-4 mb-4'>
            <ToastHeader toggle={this.props.toggle.bind(this.props.father)}>¿Qué tan acertadas fueron las sugerencias?</ToastHeader>
            <ToastBody>
                <ButtonGroup size='sm'>
                    <Button className = {this.state.estadisticas.calificacion === null ? 'active' : ''} outline onClick={this.calificarSugerencias.bind(this, null)}>N/C</Button>
                    <Button className = {this.state.estadisticas.calificacion === 1 ? 'active' : ''} outline onClick={this.calificarSugerencias.bind(this, 1)}>nada</Button>
                    <Button className = {this.state.estadisticas.calificacion === 2 ? 'active' : ''} outline onClick={this.calificarSugerencias.bind(this, 2)}>poco</Button>
                    <Button className = {this.state.estadisticas.calificacion === 3 ? 'active' : ''} outline onClick={this.calificarSugerencias.bind(this, 3)}>bastante</Button>
                    <Button className = {this.state.estadisticas.calificacion === 4 ? 'active' : ''} outline onClick={this.calificarSugerencias.bind(this, 4)}>muy</Button>
                </ButtonGroup>
            </ToastBody>
        </Modal> */}
    </div>
    );
  }
}

export default withRouter(SugerirSimbolos);