import React from 'react';
import '../Recursos.css';
import {Link, Redirect, withRouter} from "react-router-dom";
import BubbleChart from '@weknow/react-bubble-chart-d3';

class Burbujas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clicked: null,
    }
  }

  bubbleClick(label) {
    if (this.state.clicked != null) {
      this.setState({clicked: null})
    } else {
      this.setState({clicked: [{label: label, value: this.props.data.find(c => c[0] === label)[1]}]});
    }
  }

  legendClick = (label) =>{
    console.log("Customer legend click func")
  }

  render() {

    var data = this.state.clicked != null ? this.state.clicked : this.props.data.map((c, index) => {
      return {label: c[0], value: c[1]}
    })

    return (
      <div className='centrar2'>
      <BubbleChart
          graph= {{
            zoom: 1,
          }}
          width={window.screen.height/2}
          height={window.screen.height/2}
          padding={0} // optional value, number that set the padding between bubbles
          showLegend={false} // optional value, pass false to disable the legend.
          legendPercentage={20} // number that represent the % of with that legend going to use.
          legendFont={{
                family: 'Arial',
                size: 12,
                color: '#000',
                weight: 'bold',
              }}
          valueFont={{
                family: 'Arial',
                size: 12,
                color: '#fff',
                weight: 'bold',
              }}
          labelFont={{
                family: 'Arial',
                size: 10,
                color: '#fff',
                weight: 'bold',
              }}
          //Custom bubble/legend click functions such as searching using the label, redirecting to other page
          bubbleClickFun={this.bubbleClick.bind(this)}
          legendClickFun={this.legendClick}
          data={data}
        />
      </div>
      );
    }
  }
  
  export default withRouter(Burbujas);