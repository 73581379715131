import React, { Component } from 'react';
import './Recursos.css';
import { ButtonGroup, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, Redirect} from "react-router-dom";
import Resizer from 'react-image-file-resizer';
import Suenios from './Suenios';
import axios from 'axios';
import logo from './mercurio.png'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authAxios: this.props.authAxios,
      usuario: {
        username: null,
        password: null
      },
      noHuboIntentos: true,
      valido: false,
      rolesModal: false,
      roles: [],
    }
  }

  usernameInvalido() {
    return (this.state.usuario.username === '')
  }

  passwordInvalida() {
    return (this.state.usuario.password === '')
  }

  validarUsuario() {
    axios.post(this.props.apiUrl + '/obtener_token/', this.state.usuario).then((Response => {
      this.setState({noHuboIntentos: false, valido: true});
      sessionStorage.setItem('token', Response.data.token);
      this.obtenerRoles();
      this.toggleRolesModal();
    }))
    .catch((error => {
      this.setState({noHuboIntentos: false, valido: false});
    }))
  }

  toggleRolesModal() {
    this.setState({rolesModal: true})
  }

  obtenerRoles() {
    const config = {headers: {Authorization: `Token ${sessionStorage.token}`}}
    axios.get(this.props.apiUrl + '/usuario/', config).then((Response => {
      //Se guardan los roles del usuario menos el rol "PermisoGPT3"
      let roles = Response.data.rol.filter((rol) => {return rol !== 'PermisoGPT3'})
      this.setState({roles: roles})
      //Se elige un rol por defecto, en caso de que el usuario no eliga y cambie la url
      sessionStorage.setItem('rol', this.state.roles[0])
      //Si hay un solo rol, redirigir de una
      if (roles.length === 1) {
        this.props.history.push('/home/');
        window.location.reload();
      }
    }))
    .catch((error => {
      //Sin resolver (no debería darse nunca un error en este get)
    }))
  }

  asignarRol(rol) {
    sessionStorage.setItem('rol', rol);
    this.props.history.push(this.props.location.pathname);
    window.location.reload();
  }

  render() {
    /*Verificar si inició sesión, para redirigirlo al home
    let redirect = null;
    if (this.state.valido) {
      redirect = (<Redirect to={"/home"}/>)
    };*/

    //Crear botones para los roles
    let roles = this.state.roles.map((rol) => {
      return (<Button key={rol} onClick={() => this.asignarRol(rol)}>{rol === "Analista" ? "Terapeuta" : rol}</Button>)
    });
    
    return (
      <div>
        <img className='centrar mt-4' style={{width: window.screen.width < 800 ? "70vw" : "28vw"}} src={logo} alt="logoDB"></img>
        <Form className='login' onSubmit={(e) => {e.preventDefault(); this.validarUsuario()}}>
          <h1 className="display-4">Iniciar sesión</h1>
          <FormGroup>
            <Label for="username">Nombre de usuario</Label>
            <Input invalid={this.usernameInvalido()} id="username" onChange={(e) =>{
                let { usuario } = this.state;
                usuario.username = e.target.value;
                this.setState({ usuario });
              }}/>
          <FormFeedback>Este campo es obligatorio</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="password">Contraseña</Label>
            <Input invalid={this.passwordInvalida()} id="password" type='password' onChange={(e) =>{
                let { usuario } = this.state;
                usuario.password = e.target.value;
                this.setState({ usuario });
              }}/>
            <FormFeedback>Este campo es obligatorio</FormFeedback>
            <FormText>No compartas tu contraseña con otras personas.</FormText>
          </FormGroup>
          <Button outline color="success" size="lg" type='submit' className='iniciarSesion'>Iniciar sesion</Button>
          <p style={{color: "red"}}>{this.state.valido || this.state.noHuboIntentos ? null : "Los datos ingresados no son válidos"}</p>
        </Form>
        <Modal isOpen={this.state.rolesModal} >
          <ModalHeader>Seleccione un rol</ModalHeader>
          <ModalBody className='centrar'>
            <ButtonGroup>
              {roles}
            </ButtonGroup>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Login);