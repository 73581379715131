import React from 'react';
import '../Recursos.css';
import { Nav, NavItem, NavLink, Spinner, Form, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InformeSuenio from './InformeSuenio';
import VerSuenio from './VerSuenio';
import Comentarios from './Comentarios.js';
import IAInterpretaciones from './IAInterpretaciones.js';
import Imagenes from './Imagenes';
import Preguntas from './Preguntas';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ShareIcon from '@material-ui/icons/Share';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SuenioAPDF } from '../PDF/CreadorPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AudioPlayer from 'react-h5-audio-player';
import { MAX_CHARS_FOR_TTS } from '../utils/Constants.js';

class Suenio extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      suenio: null,
      informeActive: true,
      suenioActive: false,
      comentariosActive: false,
      IAInterpretacionesActive: false,
      preguntasActive: false,
      imagenes: [],
      imagenesActive: false,
      permisos: true,
      cargado: false,
      borrarSuenioModal: false,
      descargar: false,
      permisoGPT3: false,
      anterior: null,
      siguiente: null,
      cargandoAudio: false,
      mostrarReproductor: false,
      audio: null,
    };
  }

  componentDidMount(){
    this.tienePermisoGPT3();
    this.suenioAnteriorSiguiente();
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/`;
    //Hacer get del sueño
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        suenio: Response.data,
      });
      //Hacer get de las imagenes del sueño
      this.actualizarImagenes();
    })
    .catch((error) => {
      this.setState({permisos: false});
    });
  }

  toggleInforme() {
    this.setState({informeActive: true, suenioActive: false, comentariosActive: false, IAInterpretacionesActive: false, imagenesActive: false, preguntasActive: false})
  }

  toggleSuenio() {
    this.setState({informeActive: false, suenioActive: true, comentariosActive: false, IAInterpretacionesActive: false, imagenesActive: false, preguntasActive: false})
  }

  toggleComentarios() {
    this.setState({informeActive: false, suenioActive: false, comentariosActive: true, IAInterpretacionesActive: false, imagenesActive: false, preguntasActive: false})
  }

  toggleIAInterpretaciones() {
    this.setState({informeActive: false, suenioActive: false, comentariosActive: false, IAInterpretacionesActive: true, imagenesActive: false, preguntasActive: false})
  }

  toggleImagenes() {
    this.setState({informeActive: false, suenioActive: false, comentariosActive: false, IAInterpretacionesActive: false, imagenesActive: true, preguntasActive: false})
  }

  togglePreguntas() {
    this.setState({informeActive: false, suenioActive: false, comentariosActive: false, IAInterpretacionesActive: false, imagenesActive: false, preguntasActive: true})
  }

  toggleBorrarSuenioModal() {
    this.setState({
      borrarSuenioModal: !this.state.borrarSuenioModal
    });
  }

  borrarSuenio() {
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/`;
    let listaSuenios = `/home/pacientes/${this.props.match.params.idPaciente}/suenios/`
    if(sessionStorage.rol === 'Soñante') {
      endpoint = `/suenios/${this.props.match.params.idSuenio}/`;
      listaSuenios = `/home/suenios/`
    }
    //Hacer delete
    this.props.authAxios.delete(endpoint);
    //Redirigir a la lista de suenios
    this.props.history.push(listaSuenios)
  }

  //Retorna true si el usuario tiene permiso para usar GPT3, false en caso contrario
  tienePermisoGPT3() {
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      //Si el ususario tiene el rol PermisoGPT3, retornar true
      this.setState({permisoGPT3: Response.data.rol.includes('PermisoGPT3')})
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  suenioAnteriorSiguiente() {
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/anterior_siguiente/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/anterior_siguiente/`;
    //Si hay filtro en SessionStorage, agregarlo al body
    let filtro = sessionStorage.getItem('filtro')
    if(filtro !== null) filtro = JSON.parse(filtro)
    //Hacer get de los sueños anterior y siguiente al sueño actual
    this.props.authAxios.post(endpoint, filtro)
    .then((Response) => {
      this.setState({
        anterior: Response.data.anterior? Response.data.anterior.id : null,
        siguiente: Response.data.siguiente? Response.data.siguiente.id : null
      });
    })
    .catch((error) => {
      //Marcar como si no hubiera ningún sueño anterior o siguiente
      this.setState({
        anterior: null,
        siguiente: null
      });
    });
  }

  dirigirASuenio(idSuenio) {
    //Determinar rol (soñante o analista)
    let dir =`/home/pacientes/${this.props.match.params.idPaciente}/suenios/${idSuenio}/`;
    if(sessionStorage.rol === 'Soñante') dir = `/home/suenios/${idSuenio}/`;
    this.props.history.push(dir);
    window.location.reload();

  }

  volverAtras() {
    //Determinar rol (soñante o analista)
    let dir =`/home/pacientes/${this.props.match.params.idPaciente}/suenios/`;
    if(sessionStorage.rol === 'Soñante') dir = `/home/suenios/`;
    this.props.history.push(dir);
  }

  actualizarImagenes() {
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/imagenes/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/imagenes/`;
    this.props.authAxios.get(endpoint) //Hacer get de las imagenes del sueño
      .then((Response) => {
        this.setState({
          imagenes: Response.data,
          cargado: true,
        });
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }

  obtenerAudio() {
    this.setState({cargandoAudio: true});
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/tts/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/tts/`;
    this.props.authAxios.get(endpoint) //Hacer get del audio del sueño
      .then((Response) => {
        this.setState({
          audio: Response.data,
          mostrarReproductor: true,
          cargandoAudio: false,
        });
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }

  suenioLargoParaTTS() {
    let text = "Título del sueño: " + this.state.suenio.nombre + "\n\n" + this.state.suenio.descripcion
    return text.length > MAX_CHARS_FOR_TTS
  }

  render() {
    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>) 

    //Mostrar spinner mientras está cargando
    if (!this.state.cargado)
      return(<Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>)

    let componente = null;
    if (this.state.informeActive) {
      componente = (<div className="mt-3 mb-4"><InformeSuenio suenio={this.state.suenio} toggleSuenio={this.toggleSuenio.bind(this)} togglePreguntas={this.togglePreguntas.bind(this)} authAxios={this.props.authAxios}></InformeSuenio></div>)
    } else if (this.state.suenioActive) {
      componente = (<div className="mt-3 mb-4"><VerSuenio suenio={this.state.suenio} authAxios={this.props.authAxios} imagenes={this.state.imagenes}></VerSuenio></div>)
    } else if (this.state.comentariosActive) {
      componente = (<div className="mt-3 mb-4"><Comentarios suenio={this.state.suenio} authAxios={this.props.authAxios}></Comentarios></div>)      
    } else if (this.state.IAInterpretacionesActive) {
      componente = (<div className="mt-3 mb-4"><IAInterpretaciones suenio={this.state.suenio} authAxios={this.props.authAxios}></IAInterpretaciones></div>)      
    } else if (this.state.imagenesActive) {
      componente = (<div className="mt-3 mb-4"><Imagenes authAxios={this.props.authAxios} permisoGPT3={this.state.permisoGPT3} actualizarImagenes={this.actualizarImagenes.bind(this)} imagenes={this.state.imagenes}></Imagenes></div>)
    } else if (this.state.preguntasActive) {
      componente = (<div className="mt-3 mb-4"><Preguntas suenio={this.state.suenio} authAxios={this.props.authAxios}></Preguntas></div>)
    }

    //Agregar botón de descarga
    let PDFSuenio = <Button outline color="success" size="sm" className="mr-2" onClick={() => this.setState({descargarPDF: true})}><strong>⇣</strong><p className='letraChica'>Exportar</p></Button>;
    let suenioParaPDF = {...this.state.suenio}
    suenioParaPDF.fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(this.state.suenio.fecha + 'T00:00:00'))
    if (this.state.descargarPDF) PDFSuenio = (<PDFDownloadLink className="mr-2" document={<SuenioAPDF suenio={suenioParaPDF} paciente={sessionStorage.rol !== 'Soñante' && this.state.suenio.paciente.nombre} />} fileName={`sueño del ${this.state.suenio.fecha}.pdf`}>
      {({ blob, url, loading, error }) => (loading ? 'Generando PDF...' : 'Click aquí!')}
    </PDFDownloadLink>);

    //Si el usuario tiene permiso para GPT3, agregar pestaña de interpretador inteligente
    let IAInterpretacionesNavLink = this.state.permisoGPT3 ?
    (<NavItem>
      <NavLink active={this.state.IAInterpretacionesActive} onClick={this.toggleIAInterpretaciones.bind(this)}>Interpretador inteligente</NavLink>
    </NavItem>) : null
    return (
      <div>
        <Form inline>
          <IconButton onClick={this.volverAtras.bind(this)} aria-label="volver" size="medium" className='mt-2 mt-md-0'>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <div className="ml-2 mr-2">
          <h4 className="display-4 mb-0 mt-0 mt-md-3">{this.state.suenio.nombre? this.state.suenio.nombre : 'Sueño'}</h4>
          <h1 className="lead">
          <IconButton disabled={this.state.anterior === null} onClick={this.dirigirASuenio.bind(this, this.state.anterior)} aria-label="ant" size="small" style={{outline: 'none'}}>
            <KeyboardArrowUpIcon fontSize="inherit" />
          </IconButton>
          <IconButton disabled={this.state.siguiente === null} onClick={this.dirigirASuenio.bind(this, this.state.siguiente)} aria-label="sig" size="small" style={{outline: 'none'}} className="mr-2">
            <KeyboardArrowDownIcon fontSize="inherit" />
          </IconButton>
          {new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(this.state.suenio.fecha + 'T00:00:00'))}
          </h1>
          </div>
          <hr/>
          <div className="mb-2">
            {PDFSuenio}
            <Button outline color="primary" size="sm" className="mr-2" href={
              sessionStorage.rol === 'Soñante' ? `/home/suenios/${this.state.suenio.id}/editar/` : `/home/pacientes/${this.props.match.params.idPaciente}/suenios/${this.state.suenio.id}/editar/`
              }><strong>✎</strong><p className='letraChica'>Editar</p>
            </Button>
            <Button outline color="danger" size="sm" className="mr-2" onClick={this.toggleBorrarSuenioModal.bind(this)}><strong>🗑</strong><p className='letraChica'>Borrar</p></Button>
            <Button outline color="secondary" size="sm" className="mr-2" onClick={() => {navigator.share({title:this.state.suenio.nombre?this.state.suenio.nombre:"Suenio", text: this.state.suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, ''), link: "www.mercurio.org.uy"})}}><ShareIcon fontSize='small'/><p className='letraMuyChica'>Compartir</p></Button>
            {this.state.permisoGPT3 &&
              <Button outline color="info" size="sm" className="mr-2" onClick={this.state.mostrarReproductor? () => this.setState({mostrarReproductor: false}) : this.obtenerAudio.bind(this)} disabled={this.state.cargandoAudio || this.suenioLargoParaTTS()}><strong>🔊</strong><p className='letraChica'>Escuchar</p></Button>
            }
          </div>
        </Form>
        {this.state.cargandoAudio && this.state.permisoGPT3 &&
          (<div className="centrar2">
            <Spinner className='mb-2'/>
            <i className="text-center">Generando audio...</i>
          </div>)
        } 
        {this.state.mostrarReproductor && this.state.permisoGPT3 && 
          <AudioPlayer src={this.state.audio.audio} className='mb-2'/>
        }
        <Nav tabs fill className='user-select-none' role="button">
          <NavItem>
            <NavLink active={this.state.informeActive} onClick={this.toggleInforme.bind(this)}>Resumen</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.suenioActive} onClick={this.toggleSuenio.bind(this)}>Detalles</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.imagenesActive} onClick={this.toggleImagenes.bind(this)}>Imágenes</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.comentariosActive} onClick={this.toggleComentarios.bind(this)}>Comentarios</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.preguntasActive} onClick={this.togglePreguntas.bind(this)}>Preguntas</NavLink>
          </NavItem>
          {IAInterpretacionesNavLink}
        </Nav>
        {componente}
        <Modal isOpen={this.state.borrarSuenioModal} toggle={this.toggleBorrarSuenioModal.bind(this)}>
          <ModalBody>
            El sueño se borrará permanentemente. ¿Estás seguro que deseas continuar?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.borrarSuenio.bind(this)}>Confirmar</Button>{' '}
            <Button color="secondary" onClick={this.toggleBorrarSuenioModal.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Suenio);