import React from 'react';
import './Recursos.css';
import { Button, Form, FormGroup, Label, Input, Row, UncontrolledCollapse, ListGroup, ListGroupItem, Badge, ButtonGroup } from 'reactstrap';
import {Button as ButtonUI, Switch, FormControlLabel} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { Redirect, withRouter } from 'react-router-dom';

class Filtros extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      nuevoInformeData: {
        simbolos: [],
        sentimientos: [],
        contextos: [],
        jerarquia: '1',
        con_or: false
      },
      simbolos: [],
      simbolosBuscados: [],
      sentimientos: [],
      sentimientosBuscados: [],
      contextos: [],
      contextosBuscados: [],
      consultaComplejaActive: false,
      plantillas: [],
    };  
  }

  componentDidMount(){this.obtenerTerminos();}


  guardarInforme() {
    //Guardar en session storage para no perder el filtro
    sessionStorage.setItem('filtro', JSON.stringify(this.state.nuevoInformeData))

    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.match.params.id}/suenios/filtrar_suenios/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/filtrar_suenios/`;
    this.props.authAxios.post(endpoint, this.state.nuevoInformeData).then((Response => {
      this.props.sueniosFiltrados(Response.data)
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  }

  //Funciones para el manejo de tags
  obtenerTerminos() {
    //Hacer get simbolos
    this.props.authAxios.get(`/sol/simbolos/`)
    .then((Response1) => {
      //Hacer get sentimientos
      this.props.authAxios.get(`/sentimientos/`)
      .then((Response2) => {
        //Hacer get contextos
        this.props.authAxios.get(`/contextos/`)
        .then((Response3) => {
          //Guardar el estado, y al finalizar fijarse si hay caso de uso de editar suenio y cargarlo
          this.setState({
            simbolos: Response1.data, simbolosBuscados: Response1.data,
            sentimientos: Response2.data, sentimientosBuscados: Response2.data,
            contextos: Response3.data, contextosBuscados: Response3.data
          }, () => {if (this.props.match.params.idSuenio) this.cargarSuenio();})
    })})})

    this.props.authAxios.get(`/plantillas/`)
    .then((Response) => {
      let plantillas = Response.data;
      this.props.authAxios.get(`/plantillas_publicas/`)
      .then((Response) => {
        // Concatenar las plantillas publicas, pero sin repetir
        plantillas = plantillas.concat(Response.data.filter(plantilla => !plantillas.some(plantillaPublica => plantillaPublica.id === plantilla.id)));
        this.setState({plantillas});

    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }
    )})
  }

  agregarSimbolo(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.simbolos = [...this.state.nuevoInformeData.simbolos, {nombre: nombre}]
    this.setState({
      nuevoInformeData,
      simbolos: this.state.simbolos.filter(simbolo => simbolo.nombre !== nombre),
      simbolosBuscados: this.state.simbolosBuscados.filter(simbolo => simbolo.nombre !== nombre)
    });
  }

  quitarSimbolo(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.simbolos = this.state.nuevoInformeData.simbolos.filter(simbolo => simbolo.nombre !== nombre);
    this.setState({
      nuevoInformeData,
      simbolos: [...this.state.simbolos, {id: nombre, nombre: nombre}],
      simbolosBuscados:[...this.state.simbolosBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaSimbolos(busquedaSimbolo) {
    const { simbolos } = this.state;
    let simbolosBuscados = simbolos.filter(simbolo => simbolo.nombre.toLowerCase().includes(busquedaSimbolo.toLowerCase()));
    this.setState({simbolosBuscados});
  }

  agregarSentimiento(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.sentimientos = [...this.state.nuevoInformeData.sentimientos, {nombre: nombre}];
    this.setState({
      nuevoInformeData,
      sentimientos: this.state.sentimientos.filter(sentimiento => sentimiento.nombre !== nombre),
      sentimientosBuscados: this.state.sentimientosBuscados.filter(sentimiento => sentimiento.nombre !== nombre)
    });
  }

  quitarSentimiento(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.sentimientos = this.state.nuevoInformeData.sentimientos.filter(sentimiento => sentimiento.nombre !== nombre);
    this.setState({
      nuevoInformeData,
      sentimientos: [...this.state.sentimientos, {id: nombre, nombre: nombre}],
      sentimientosBuscados:[...this.state.sentimientosBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaSentimientos(busquedaSentimiento) {
    const { sentimientos } = this.state;
    let sentimientosBuscados = sentimientos.filter(sentimiento => sentimiento.nombre.toLowerCase().includes(busquedaSentimiento.toLowerCase()));
    this.setState({sentimientosBuscados});
  }

  agregarContexto(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.contextos = [...this.state.nuevoInformeData.contextos, {nombre: nombre}];
    this.setState({
      nuevoInformeData,
      contextos: this.state.contextos.filter(contexto => contexto.nombre !== nombre),
      contextosBuscados: this.state.contextosBuscados.filter(contexto => contexto.nombre !== nombre)
    });
  }

  quitarContexto(nombre) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.contextos = this.state.nuevoInformeData.contextos.filter(contexto => contexto.nombre !== nombre);
    this.setState({
      nuevoInformeData,
      contextos: [...this.state.contextos, {id: nombre, nombre: nombre}],
      contextosBuscados:[...this.state.contextosBuscados, {id: nombre, nombre: nombre}]
    });
  }
  
  busquedaContextos(busquedaContexto) {
    const { contextos } = this.state;
    let contextosBuscados = contextos.filter(contexto => contexto.nombre.toLowerCase().includes(busquedaContexto.toLowerCase()));
    this.setState({contextosBuscados});
  }

  toggleConsultaCompleja() {
    if (this.state.consultaComplejaActive) {
      let { nuevoInformeData } = this.state;
      delete nuevoInformeData['consulta_compleja'];
    }
    this.setState({consultaComplejaActive: !this.state.consultaComplejaActive});
  }

  seleccionarPlantilla(plantilla) {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.consulta_compleja = plantilla;
    this.setState({nuevoInformeData});
  }

  cambiarConOr() {
    let { nuevoInformeData } = this.state;
    nuevoInformeData.con_or = !this.state.nuevoInformeData.con_or;
    this.setState({nuevoInformeData});
  }

  render() {

    //Cargar simbolos, sentimientos y contextos
    const simbolos = this.state.simbolosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((c) => {
      return (<ListGroupItem key={c.id} id={c.nombre} action onClick={(e) => this.agregarSimbolo(e.target.id)}>{c.nombre}</ListGroupItem>)
    })
    const sentimientos = this.state.sentimientosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((s) => {
      return (<ListGroupItem key={s.id} id={s.nombre} action onClick={(e) => this.agregarSentimiento(e.target.id)}>{s.nombre}</ListGroupItem>)
    })
    const contextos = this.state.contextosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((c) => {
      return (<ListGroupItem key={c.id} id={c.nombre} action onClick={(e) => this.agregarContexto(e.target.id)}>{c.nombre}</ListGroupItem>)
    })

    //Cargar los tags
    const simbolosSel = this.state.nuevoInformeData.simbolos.map((c) => {
      return (<h5 key={c.nombre}><Badge color="primary" id={c.nombre} className="mr-sm-2" onClick={(e) => this.quitarSimbolo(e.target.id)}>{ c.nombre + ' x' }</Badge></h5>)
    })
    const sentimientosSel = this.state.nuevoInformeData.sentimientos.map((s) => {
      return (<h5 key={s.nombre}><Badge color="warning" id={s.nombre} className="mr-sm-2" onClick={(e) => this.quitarSentimiento(e.target.id)}>{ s.nombre + ' x' }</Badge></h5>)
    })
    const contextosSel = this.state.nuevoInformeData.contextos.map((c) => {
      return (<h5 key={c.nombre}><Badge color="info" id={c.nombre} className="mr-sm-2" onClick={(e) => this.quitarContexto(e.target.id)}>{ c.nombre + ' x' }</Badge></h5>)
    })

    const fecha = (<Form inline>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Label for="fechaSueDesde" className="mr-sm-2">Fecha del sueño: desde </Label>
        <Input type="date" name="fechaSueDesde" onChange={(e) =>{
                  let { nuevoInformeData } = this.state;
                  nuevoInformeData.fecha_suenio_desde = e.target.value;
                  if (e.target.value === "") delete nuevoInformeData['fecha_suenio_desde'];
                  this.setState({ nuevoInformeData });
                  }}/>
      </FormGroup>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Label for="fechaSueHasta" className="mr-sm-2"> hasta </Label>
        <Input type="date" name="fechaSueHasta" onChange={(e) =>{
                  let { nuevoInformeData } = this.state;
                  nuevoInformeData.fecha_suenio_hasta = e.target.value;
                  if (e.target.value === "") delete nuevoInformeData['fecha_suenio_hasta'];
                  this.setState({ nuevoInformeData });
                  }}/>
      </FormGroup>
    </Form>)

    const rol = (<Form inline>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Label for="fecha" className="mr-sm-2">Rol del soñante: </Label>
        <ButtonGroup>
        <Button outline size='sm' className={this.state.nuevoInformeData.pasivos === undefined ? 'active' : ''} onClick={() => {
                  let { nuevoInformeData } = this.state;
                  delete nuevoInformeData['pasivos'];
                  this.setState({ nuevoInformeData })
                  }}>Cualquiera</Button>
          <Button outline size='sm' className={this.state.nuevoInformeData.pasivos === true ? 'active' : ''} onClick={() => {
                  let { nuevoInformeData } = this.state;
                  nuevoInformeData.pasivos = true;
                  this.setState({ nuevoInformeData })
                  }}>Pasivos</Button>
          <Button outline size='sm' className={this.state.nuevoInformeData.pasivos === false ? 'active' : ''} onClick={() => {
                  let { nuevoInformeData } = this.state;
                  nuevoInformeData.pasivos = false;
                  this.setState({ nuevoInformeData })
                  }}>Activos</Button>
        </ButtonGroup>
      </FormGroup>
    </Form>)

    const simbolosFiltro = (<div>
    <Label className="mr-sm-2"><Badge color="primary" pill> </Badge> Elegir símbolos para filtrar: </Label>
      <Input name="simbolos" placeholder="Buscar" onChange={(e) => {
                let busquedaSimbolo = e.target.value;
                this.busquedaSimbolos(busquedaSimbolo);
              }}/>
      <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
        {simbolos}
      </ListGroup>
      <div style={{display: 'flex'}}>{simbolosSel}</div>
    </div>)

    const sentimientosFiltro = (<div>
      <Label className="mr-sm-2"><Badge color="warning" pill> </Badge> Elegir sentimientos o emociones para filtrar: </Label>
        <Input name="sentimientos" placeholder="Buscar" onChange={(e) => {
                  let busquedaSentimiento = e.target.value;
                  this.busquedaSentimientos(busquedaSentimiento);
                }}/>
        <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
          {sentimientos}
        </ListGroup>
        <div style={{display: 'flex'}}>{sentimientosSel}</div>
      </div>)

    const contextosFiltro = (<div>
      <Label className="mr-sm-2"><Badge color="info" pill> </Badge> Elegir contextos del soñante para filtrar: </Label>
      <Input name="contextos" placeholder="Buscar" onChange={(e) => {
                let busquedaContexto = e.target.value;
                this.busquedaContextos(busquedaContexto);
              }}/>
      <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
        {contextos}
      </ListGroup>
      <div style={{display: 'flex'}}>{contextosSel}</div>
    </div>)

    const cargarPlantillas = this.state.plantillas.map((p) => {
      return (<option key={p.plantilla_id} value={p.plantilla}>{p.descripcion}</option>)
    })

    let seleccionSimbolosSentimientos = this.state.consultaComplejaActive ? 
    (<div>
      <Label className="mr-sm-2">Escribir consulta compleja:</Label>
      <Input className="contorno mb-2" type="textarea" name="consultaCompleja" value={this.state.nuevoInformeData.consulta_compleja} onChange={(e) =>{
            let { nuevoInformeData } = this.state;
            nuevoInformeData.consulta_compleja = e.target.value;
            this.setState({ nuevoInformeData });
          }}/>
      <Input className='margenAbajo' type="select" defaultValue={''} onChange={(e) => {this.seleccionarPlantilla(e.target.value)}}>
        <option value=''>O seleccionar una plantilla</option>
        {cargarPlantillas}
      </Input>
    </div>)
    :
    (<div>
      <Button color="link" id="simbolos" style={{ marginBottom: '1rem' }}>
        Símbolos
      </Button>
      <UncontrolledCollapse toggler="#simbolos">{simbolosFiltro}</UncontrolledCollapse>
      <br/>
      <Button color="link" id="sentimientos" style={{ marginBottom: '1rem' }}>
        Sentimientos y emociones
      </Button>
      <UncontrolledCollapse toggler="#sentimientos">{sentimientosFiltro}</UncontrolledCollapse>
    </div>)

    //Estilo del switch de (AND, OR)
    const BlueSwitch = withStyles({
      switchBase: {
        color: blue[500],
      },
    })(Switch);
    
    return (
      

      <div>
        <p>Filtrar por:</p>
        <Button color="link" id="fecha" style={{ marginBottom: '1rem' }}>
          Fecha
        </Button>
        <UncontrolledCollapse toggler="#fecha">{fecha}</UncontrolledCollapse>
        <br/>
        <Button color="link" id="rol" style={{ marginBottom: '1rem' }}>
          Rol
        </Button>
        <UncontrolledCollapse toggler="#rol">{rol}</UncontrolledCollapse>
        <br/>
        <div className='derecha'> 
        {!this.state.consultaComplejaActive ?
          <FormControlLabel 
            className="ml-sm-2"
            control={<BlueSwitch size="small" checked={this.state.nuevoInformeData.con_or} onChange={this.cambiarConOr.bind(this)} color='default' name="checkedA" />}
            label={<p className="letraChica mt-sm-1">{this.state.nuevoInformeData.con_or? "ALGUNO" : "TODOS"}</p>}
          /> : null}
          <hr/>
          <ButtonUI color="primary" onClick={this.toggleConsultaCompleja.bind(this)}>{this.state.consultaComplejaActive ? 'Consulta normal' : 'Consulta avanzada'}</ButtonUI>
        </div>
        {seleccionSimbolosSentimientos}
        <Button color="link" id="contextos" style={{ marginBottom: '1rem' }}>
          Contextos
        </Button>
        <UncontrolledCollapse toggler="#contextos">{contextosFiltro}</UncontrolledCollapse>
        <div className='centrar2'>
          <Row className='margenAbajo'>
            <Button color="success" className="mr-2" onClick={this.guardarInforme.bind(this)}>Filtrar</Button>
            <Button outline color="danger" className="mr-sm-2" onClick={() => this.props.toggleDrawer()}>Cancelar</Button>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Filtros);