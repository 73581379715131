import React from 'react';
import '../Recursos.css';
import { Badge, Row, Col } from 'reactstrap';
import { withRouter } from "react-router-dom";

class VerMito extends React.Component {

  render() {  

    //Cargar Simbolos y Arquetipos de mito seleccionado
    let simbolos = this.props.mito.simbolos.map((simbolo) => {
      return (<Col sm={{size: 'auto'}} key={simbolo.id}><h5><Badge color="primary" pill>{ simbolo.nombre }</Badge></h5></Col>)
    });
    let arquetipos = this.props.mito.arquetipos.map((arquetipo) => {
      return (<Col sm={{size: 'auto'}} key={arquetipo.id}><h5><Badge color="warning" pill>{arquetipo.nombre}</Badge></h5></Col>)
    });


    return (
      <div>
        <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{this.props.mito.descripcion}</p>
        <h6>Símbolos</h6>
        {simbolos.length ? <Row className='margenAbajo'>{simbolos}</Row> : <p>El mito no tiene símbolos asociados</p>}
        {/* <h6>Arquetipos</h6>
        {arquetipos.length ? <Row className='margenAbajo'>{arquetipos}</Row> : <p>El mito no tiene arquetipos asociados</p>} */}
      </div>
    );
  }
}

export default withRouter(VerMito);