import React, { Component } from 'react';
import './Recursos.css';
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

class CambiarPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cambiarPasswordData: {
        password_vieja:"",
        password_nueva: ""
      },
      passwordViejaIncorrecta: false,
      passwordNuevaRepetida: ""
    }
  }

  cambiarPassword() {
    if (this.esPasswordNuevaIgualRepeticion()) {
      this.props.authAxios.put('/usuario/cambiar_password/', this.state.cambiarPasswordData)
      .then((Response) => {
        this.setState({passwordViejaIncorrecta: false})
        this.props.toggleCambiarPasswordModal();
      })
      .catch((error) => {
        this.setState({passwordViejaIncorrecta: true})
      });
    }
  }

  esPasswordNuevaIgualRepeticion() {
    return this.state.passwordNuevaRepetida === this.state.cambiarPasswordData.password_nueva;
  }

  render() {
    return (
      <Form>
          <FormGroup>
            <Label for="passAnterior">Constraseña anterior</Label>
            <Input invalid={this.state.passwordViejaIncorrecta} name="passAnterior" type="password" onChange={(e) =>{
                let { cambiarPasswordData } = this.state;
                cambiarPasswordData.password_vieja = e.target.value;
                this.setState({ cambiarPasswordData });
              }}/>
          <FormFeedback>La contraseña es incorrecta</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="passNueva">Nueva contraseña</Label>
            <Input invalid={this.state.cambiarPasswordData.password_nueva === ''} name="passNueva" type='password' onChange={(e) =>{
                let { cambiarPasswordData } = this.state;
                cambiarPasswordData.password_nueva = e.target.value;
                this.setState({ cambiarPasswordData });
              }}/>
            <FormFeedback>La nueva contraseña no puede ser vacía</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="passNuevaRep">Repetir nueva contraseña</Label>
            <Input invalid={!this.esPasswordNuevaIgualRepeticion()} name="passNuevaRep" type='password' onChange={(e) =>{
                let { passwordNuevaRepetida } = this.state;
                passwordNuevaRepetida = e.target.value;
                this.setState({ passwordNuevaRepetida });
              }}/>
            <FormFeedback>No hay coincidencia con la contraseña ingresada en el campo anterior</FormFeedback>
          </FormGroup>
          <Form inline>
            <Button outline color="primary" size="lg" onClick={this.cambiarPassword.bind(this)}>Cambiar</Button>
            <hr/>
            <Button outline color="secondary" size="lg" onClick={() => this.props.toggleCambiarPasswordModal()}>Cancelar</Button>
          </Form>
        </Form>
    );
  }
}

export default CambiarPassword;