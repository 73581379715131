import React from 'react';
import './Recursos.css';
import {Switch, FormControlLabel} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import {withRouter, Link} from "react-router-dom";
import {Spinner, Card, Form, CardBody, CardText, Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import ImageGallery from 'react-image-gallery';
import ModalImage from "react-modal-image";
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';


class VerGaleria extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imagenes: [],
      cargado: false,
      viewAsSlide: false,
      editando: null,
      descripcion: '',
      borrarImagenModal: false,
      borrar: null
    }
  }

  componentDidMount() {
    this.actualizarImagenes();
  }

  actualizarImagenes() {
    let endpoint =`/febo/galeria/imagenes/`;
    this.props.authAxios.get(endpoint) //Hacer get de las imagenes del sueño
      .then((Response) => {
        this.setState({
          imagenes: Response.data,
          cargado: true
        });
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }

  toggleViewAsSlide() {
    this.setState({
      viewAsSlide: !this.state.viewAsSlide
    });
  }

  toggleEditando(img) {
    if (img.id === this.state.editando) {
      let endpoint =`/febo/galeria/imagenes/${img.id}/`;
      this.props.authAxios.put(endpoint, {imagen: 'null', descripcion: this.state.descripcion})
      .then((Response) => {
        this.actualizarImagenes();
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      })
    }
    this.setState({
        editando: this.state.editando === img.id? null : img.id,
        descripcion: img.descripcion !== 'null' ? img.descripcion : ''
    });
  }

  toggleBorrarImagenModal(img) {
    if (!this.state.borrarImagenModal) {
        this.setState({
            borrar: img.id,
            borrarImagenModal: !this.state.borrarImagenModal
        });
    } else {
        this.setState({
            borrar: null,
            borrarImagenModal: !this.state.borrarImagenModal
        });
    }
  }

  borrarImagen() {
    let endpoint =`/febo/galeria/imagenes/${this.state.borrar}/`;
    this.props.authAxios.delete(endpoint)
      .then((Response) => {
        this.actualizarImagenes();
        this.toggleBorrarImagenModal();
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }
  
  render() {
    if(!this.state.cargado) {
      return <Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>
    }

    if(this.state.imagenes.length === 0) {
        return (
            <div>
                <h1 className="container-sm display-4">Galería</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="alert alert-warning" role="alert">
                                <strong>¡No hay imágenes!</strong>
                                <p className='mt-2'>Luego de agregar imágenes en los sueños, podrás verlas aquí.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const images = this.state.imagenes.map((imagen) => {
        let desc = imagen.descripcion;
        if (window.screen.width <= 800)
            desc = imagen.descripcion.length <= 80? imagen.descripcion : imagen.descripcion.substring(0, 80) + "..." ;
        return {
            original: imagen.imagen,
            thumbnail: imagen.imagen,
            description: imagen.descripcion !== 'null' ? desc : '',
            originalClass: 'fondoNegro',
        }
    });

    //Estilo del switch
    const BlueSwitch = withStyles({
        switchBase: {
            color: blue[500],
        },
        })(Switch);

    return (
    <div className='mb-4'>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <h1 className="container-sm display-4">Galería</h1>
        <FormControlLabel 
            className="mt-3"
            control={<BlueSwitch checked={this.state.viewAsSlide} onChange={this.toggleViewAsSlide.bind(this)} color='default' name="checkedA" />}
            label={<p className="letraChica mt-sm-1">{this.state.viewAsSlide? "SLIDE" : "LISTA"}</p>}
        />
      </div>
      {this.state.viewAsSlide ?
      <ImageGallery items={images} thumbnailPosition={window.screen.width > 800 ? 'left':'bottom'} showIndex='true' />
      :
      <div>
      <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-around', alignItems:'center'}}>
        {this.state.imagenes.sort((a, b) => a.fecha > b.fecha ? -1 : 1).map((img) => (
          <Card key={img.id} style={{maxWidth:window.screen.width > 800 ? '25vw' : 'auto', margin:'1rem', height:'fit-content'}}>
              <ModalImage small={img.imagen} large={img.imagen} showRotate={true} hideZoom={true} alt={<p className='letraChica'>{img.descripcion !== 'null'? img.descripcion : ''}</p>} />
            <CardBody>
              <CardText>
                <p className='letraChica'><strong>Descripción: </strong>{img.descripcion !== 'null' && img.id !== this.state.editando? img.descripcion : null}</p>
                {img.id === this.state.editando? <textarea className='form-control letraChica mb-1' rows='auto' value={this.state.descripcion} onChange={(e) => this.setState({descripcion: e.target.value})}></textarea> : null}
                <p className='letraChica'><strong>Fecha: </strong>{new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(img.fecha))}</p>
                <Form inline>
                  {sessionStorage.rol === 'Soñante' && img.suenios.length > 0 ?<Link className='letraChica' to={`/home/suenios/${img.suenios[0]}/`}>Ir al sueño</Link>:null}
                  <hr/>
                  <Button outline color="primary" size="sm" className="letraChica" onClick={this.toggleEditando.bind(this, img)}>{this.state.editando === img.id? 'Confirmar' : '✎'}</Button>
                  <Button outline color="danger" size="sm" className="letraChica ml-1" onClick={this.toggleBorrarImagenModal.bind(this, img)}>🗑</Button>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        ))}
      </div>
      <p className="lead">Cantidad de imágenes: {this.state.imagenes.length}</p>
      </div>
      }
        <Modal isOpen={this.state.borrarImagenModal} toggle={this.toggleBorrarImagenModal.bind(this)}>
            <ModalBody>
                La imagen se borrará permanentemente. ¿Estás seguro que deseas continuar?
            </ModalBody>
            <ModalFooter>
            <Button color="primary" onClick={this.borrarImagen.bind(this)}>Confirmar</Button>{' '}
            <Button color="secondary" onClick={this.toggleBorrarImagenModal.bind(this)}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    </div>
    );
  }
}

export default withRouter(VerGaleria);