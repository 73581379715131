import React from 'react';
import '../Recursos.css';
import { Table, Button, Spinner, Alert, Form } from 'reactstrap';
import { Link, withRouter} from "react-router-dom";
import {IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import MuiAlert from '@material-ui/lab/Alert';


class ListaClusters extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      clustersJerarquicos: [],
      clusterSeleccionado: null,
      paciente: null,
      error: null,
      errorMsg: false,
      cargado: false,
      informacion: true,
    }

    //Hacer get de paciente para obtener nombre, si rol es analista
    if(sessionStorage.rol !== 'Soñante') {
      this.props.authAxios.get(`/pacientes/${this.props.match.params.id}/`)
      .then((Response) => {
        this.setState({
          paciente: Response.data.nombre
        });
      })
      .catch((error) => {
        this.capturarError(error.response);
      });
    }
  }

  actualizarClustersJerarquicos() {
    //Hacer get
    const path = sessionStorage.rol === 'Soñante' ? '/cupido/clustering_jerarquico/' : `/cupido/paciente/${this.props.match.params.id}/clustering_jerarquico/`;
    this.props.authAxios.get(path)
    .then((Response) => {
      this.setState({
        clustersJerarquicos: Response.data,
        cargado: true,
      });
    })
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  componentDidMount(){this.actualizarClustersJerarquicos()}

  crearClusterJerarquico() {
    const path = sessionStorage.rol === 'Soñante' ? '/cupido/clustering_jerarquico/' : `/cupido/paciente/${this.props.match.params.id}/clustering_jerarquico/`;
    this.setState({cargado: false})
    this.props.authAxios.post(path).then((Response => {
      if (sessionStorage.rol === 'Soñante')
        this.props.history.push(`/home/arboles/${Response.data.id}/`);
      else 
        this.props.history.push(`/home/pacientes/${this.props.match.params.id}/arboles/${Response.data.id}/`);
    }))
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  capturarError(response) {
    // Si el error es 403, significa que el usuario no tiene permisos para realizar la acción
    if (response.status === 403) this.setState({error: "No tiene permisos para realizar esta acción."});
    else if (response.status === 404) this.setState({error: "No se ha encontrado el recurso."});
    else this.props.history.push('/ErrorInterno');
    this.setState({errorMsg: true, cargado: true});
  }

  toggleErrorMsg() {
    this.setState({errorMsg: !this.state.errorMsg})
  }

  volverAtras() {
    //Determinar rol (soñante o analista)
    let dir = `/home/pacientes/${this.props.match.params.idPaciente}/`;
    if(sessionStorage.rol === 'Soñante') dir = `/home/`;
    this.props.history.push(dir);
  }

  render() {
    // Cargar clusters jerarquicos
    let clusters = this.state.clustersJerarquicos.sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1).map((cluster) => {
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"}).format(new Date(cluster.fecha_creacion));
      const path = sessionStorage.rol === 'Soñante' ? `/home/arboles/${cluster.id}/` : `/home/pacientes/${this.props.match.params.id}/arboles/${cluster.id}/`;
      return (
        <tr key={cluster.id}>
          <td><Link to={path} style={{ color: '#000' }}>Árbol del {fecha}</Link></td>
        </tr>
      )
    });

    return (
      <div>
        <Form inline>
          <IconButton onClick={this.volverAtras.bind(this)} aria-label="volver" size="medium" className="mt-2">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <div className="ml-2 mr-2">
            <h4 className={`display-4 mt-0 mt-md-3 ${sessionStorage.rol === 'Soñante' ? '' : 'mb-0'}`}>Árboles</h4>
            {this.state.paciente && <h2 className="lead">Sobre los sueños de {this.state.paciente}</h2>}
          </div>
        </Form>
        <MuiAlert icon={<InfoIcon />} severity="info" className="my-2" onClose={() => this.setState({informacion: false})} hidden={!this.state.informacion}>
          Los árboles son una forma de visualizar los sueños organizados por similitud. A medida que se expande el árbol, se pueden ver los sueños que más se parecen entre sí. Los árboles se pueden enriquecer con títulos y análisis para cada agrupación utilizando inteligencia artificial. Para enriquecer un árbol con esta información, enviar un mail a <a href="mailto:comunicacion.mercurio@gmail.com">comunicacion.mercurio@gmail.com</a>.
        </MuiAlert>
        {this.state.errorMsg && <Alert color="danger" isOpen={this.state.errorMsg} toggle={this.toggleErrorMsg.bind(this)}>{this.state.error}</Alert>}
        {(!this.state.cargado) ? <Spinner style={{ width: '3rem', height: '3rem' }}/>
        : !this.state.error &&
        <>
        {(clusters.length === 0 && this.state.cargado) && (<p>Aún no has creado ningún árbol.</p>)}
        <div className="scrollbarListas" id='bordeScrollbar' style={{height: '40vh'}} >
          <Table>
            <tbody>
              {clusters}
            </tbody>
          </Table>
        </div>
        <div className="leftbutton">
          <Button outline color="success" size="lg" className="margenAbajo" onClick={this.crearClusterJerarquico.bind(this)}>+</Button>
        </div> 
        </>}
      </div>
    );
  }
}

export default withRouter(ListaClusters);