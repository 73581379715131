import React from 'react';
import './Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Spinner, FormGroup, Label, Input, Badge, Row, Col } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";

class Informes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        informes: [],
        borrarInformeModal: false,
        informeSeleccionadoId: null,
        cargado: false,
      }
    }
    
  obtenerInformes() {
    //Determinar rol (soñante o analista)
    let endpoint = `/analisis_por_filtro_analista/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/analisis_por_filtro_soniante/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        informes: Response.data,
        cargado: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  borrarInforme() {
    if (this.state.informeSeleccionadoId) {
        //Determinar rol (soñante o analista)
        let endpoint = `/analisis_por_filtro_analista/${this.state.informeSeleccionadoId}/`;
        if(sessionStorage.rol === 'Soñante') endpoint = `/analisis_por_filtro_soniante/${this.state.informeSeleccionadoId}/`;
        this.props.authAxios.delete(endpoint);
        let {informes} = this.state;
        informes = this.state.informes.filter(informe => informe.id !== this.state.informeSeleccionadoId);
        this.setState({informes});
        this.toggleBorrarInformeModal();
    }
  }

  toggleBorrarInformeModal() {
      this.setState({borrarInformeModal: !this.state.borrarInformeModal})
  }

  componentDidMount(){this.obtenerInformes()}

  render() {
    //Cargar informes
    let informes = this.state.informes.sort((a, b) => a.fecha_creacion < b.fecha_creacion ? 1 : -1).map((informe) => {
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = informe.descripcion;
      if (descChica != null && descChica.length > 250) {descChica = descChica.substring(0, 250) + "...";}
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit"}).format(new Date(informe.fecha_creacion));
      return (
        <tr key={informe.id}>
          <td><p className="text-justify">{descChica}<Button color="link"  size="sm" className="active" href={`/home/informe/${informe.id}`}>Ver</Button></p></td>
          <td><Link to={`/home/informe/${informe.id}`} style={{ color: '#000' }}>{fecha}</Link><p className="small">Cantidad de sueños retornados: {informe.suenios.length}</p></td>
          <td><Button outline color="danger" size='lg' onClick={() => {
              this.setState({informeSeleccionadoId: informe.id});
              this.toggleBorrarInformeModal(this);
              }}>🗑</Button></td>
        </tr>
      )
    });

    //Ver si la lista de informes está vacía
    let noHayInformes = <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    if (informes.length === 0 && this.state.cargado) noHayInformes = (<p>Aún no se ha realizado ningún informe.</p>);
    else if (this.state.cargado) noHayInformes = null;

    return (
      <div className="container-sm">
        <h1 className="display-4">Informes</h1>
        <div className="scrollbarListas" id='bordeScrollbar'>
          {noHayInformes}
          <Table>
            <tbody>
              {informes}
            </tbody>
          </Table>
        </div>
        <div className="leftbutton">
          <Button outline color="success" size="lg" className="margenAbajo" onClick={() => this.props.toggleNuevoInforme()}>+</Button>
        </div>
        <Modal isOpen={this.state.borrarInformeModal} toggle={this.toggleBorrarInformeModal.bind(this)}>
          <ModalBody>
              El informe se borrará permanentemente. ¿Estás seguro que deseas continuar?
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={this.borrarInforme.bind(this)}>Confirmar</Button>{' '}
              <Button color="secondary" onClick={this.toggleBorrarInformeModal.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Informes);