import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, Spinner, } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";
import VerMito from "./VerMito.js"

class Mitos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mitologia: null,
      mitos: [],
      verMasModal: false,
      borrarMitoModal: false,
      mitoSeleccionado: {
          id: "",
          nombre: "",
          descripcion: "",
          fecha_creacion: "",
          simbolos: [],
          arquetipos: []
      },
      cargado: false,
      permisos: true,
    }
    
    //Fijarse que usuario sea admin (staff)
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      if (!Response.data.es_admin) this.setState({permisos: false});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
    
    //Hacer get de mitologia para obtener nombre
    this.props.authAxios.get(`/eneas/mitologias/${this.props.match.params.id}/`)
    .then((Response) => {
      this.setState({
        mitologia: Response.data.nombre
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }
  
  obtenerMitos() {
    //Hacer get
    this.props.authAxios.get(`/eneas/mitologias/${this.props.match.params.id}/mitos/`)
    .then((Response) => {
      this.setState({
        mitos: Response.data,
        cargado: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  componentDidMount(){this.obtenerMitos()}

  //Recibe el mito seleccionado y la fecha ya convertida correctamente.
  cargarMitoSeleccionado(mito, fecha) {
    let { mitoSeleccionado } = this.state;
    mitoSeleccionado.id = mito.id;
    mitoSeleccionado.nombre = mito.nombre;
    mitoSeleccionado.descripcion = mito.descripcion;
    mitoSeleccionado.fecha_creacion = fecha;
    mitoSeleccionado.simbolos = mito.simbolos;
    mitoSeleccionado.sentimientos = mito.arquetipos;
    this.setState({
      mitoSeleccionado: mitoSeleccionado,
      verMasModal: true,
    });
  }

  toggleVerMasModal() {
    this.setState({
      verMasModal: !this.state.verMasModal,
    });
  }

  toggleBorrarMitoModal() {
    this.setState({
      borrarMitoModal: !this.state.borrarMitoModal
    });
  }

  borrarMito() {
    //Hacer delete
    this.props.authAxios.delete(`/eneas/mitologias/${this.props.match.params.id}/mitos/${this.state.mitoSeleccionado.id}/`)
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
    //Actualizar lista de mitos
    let {mitos} = this.state;
    mitos = this.state.mitos.filter(mito => mito.id !== this.state.mitoSeleccionado.id);
    this.setState({mitos});
    this.toggleBorrarMitoModal();
    this.toggleVerMasModal();
  }

  render() {  
    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>)

    //Cargar mitos
    let mitos = this.state.mitos.sort((a, b) => { return (a.nombre > b.nombre) ? 1 : -1;}).map((mito) => {
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = mito.descripcion;
      if (descChica.length > 250) {descChica = descChica.substring(0, 250) + "...";}
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(mito.fecha_creacion));
      return (
        <tr key={mito.id}>
          <td><Link onClick={this.cargarMitoSeleccionado.bind(this, mito, fecha)} style={{ color: '#000' }}>{mito.nombre}</Link></td>
          <td><p className="text-justify">{descChica}<Button color="link" size="sm" className="active" onClick={this.cargarMitoSeleccionado.bind(this, mito, fecha)}>Ver más</Button></p></td>
        </tr>
      )
    });

    //Ver si la lista de mitos está vacía
    let noHayMitos = <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    if (mitos.length === 0 && this.state.cargado) noHayMitos = (<p>Aún no se ha ingresado ningún mito.</p>);
    else if (this.state.cargado) noHayMitos = null;

    return (
      <div className="container-sm">
        <Form inline>
          <h1 className="display-4">{this.state.cargado ? this.state.mitologia : null}</h1>
          <hr/>
          <Link to={`/home/eneas/`} style={{ color: 'black' }}>Volver a Eneas</Link>
        </Form>
        <h2 className="lead">Mitos</h2>
        <div className="scrollbarListas" id='bordeScrollbar'>
          {noHayMitos}
          <Table>
            <tbody>
              {mitos}
            </tbody>
          </Table>
        </div>
        <Form inline className="mt-4">
          <p className="lead">Cantidad de mitos: {mitos.length}</p>
          <hr/>
          <Button outline color="success" size="lg" className="margenAbajo" href={
            `/home/eneas/${this.props.match.params.id}/agregar_mito/`
            }>+
          </Button>
        </Form>
        <Modal isOpen={this.state.verMasModal} toggle={this.toggleVerMasModal.bind(this)}>
          <ModalHeader toggle={this.toggleVerMasModal.bind(this)}>{this.state.mitoSeleccionado.nombre}<p className='letraChica'>{this.state.mitoSeleccionado.fecha}</p></ModalHeader>
          <ModalBody>
            <VerMito mito={this.state.mitoSeleccionado} authAxios={this.props.authAxios}/>
            <Modal isOpen={this.state.borrarMitoModal} toggle={this.toggleBorrarMitoModal.bind(this)}>
              <ModalBody>
                El mito se borrará permanentemente. ¿Estás seguro que deseas continuar?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.borrarMito.bind(this)}>Confirmar</Button>{' '}
                <Button color="secondary" onClick={this.toggleBorrarMitoModal.bind(this)}>Cancelar</Button>
              </ModalFooter>
            </Modal>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" href={
              `/home/eneas/${this.props.match.params.id}/mitos/${this.state.mitoSeleccionado.id}`
              }><strong>✎</strong><p className='letraChica'>Editar</p>
            </Button>
            <Button color="danger" onClick={this.toggleBorrarMitoModal.bind(this)}><strong>🗑</strong><p className='letraChica'>Borrar</p></Button>
            <Button color="secondary" onClick={this.toggleVerMasModal.bind(this)}><strong>X</strong><p className='letraChica'>Cerrar</p></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Mitos);