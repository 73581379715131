import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, Spinner, FormGroup, Label, Input, Alert } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";

class Mitologias extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mitologias: [],
      borrarMitologiaModal: false,
      mitologiaSeleccionada: {
          id: "",
          nombre: "",
          descripcion: "",
          fecha_creacion: ""
      },
      cargado: false,
      permisos: true,
      verMasModal: false,
      nuevaMitologiaData: {
        nombre: '',
        descripcion: '',
      },
      crearMitologiaModal: false,
      //Con esta variable booleana se establece si se hace post o put
      editandoMitologia: false,
      error: null,
      hayError: false,
    }

    //Fijarse que usuario sea admin (staff)
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      if (!Response.data.es_admin) this.setState({permisos: false});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  obtenerMitologias() {
    //Hacer get
    this.props.authAxios.get(`/eneas/mitologias/`)
    .then((Response) => {
      this.setState({
        mitologias: Response.data,
        cargado: true
      });
    })
    .catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    });  
  }

  componentDidMount(){this.obtenerMitologias()}

  toggleCrearMitologiaModal() {
    this.setState({
      crearMitologiaModal: !this.state.crearMitologiaModal
    }, () => { if (!this.state.crearMitologiaModal) this.cerrarCrearMitologia();})   
  }

  crearMitologia() {
    this.props.authAxios.post('/eneas/mitologias/', this.state.nuevaMitologiaData).then((Response => {
      this.setState({crearMitologiaModal: false});
      this.obtenerMitologias();
    }))
    .catch((error => {
        this.capturarError(error.response)
    }))
  }

  capturarError(response) {
    if (response.data.nombre) this.setState({error: "El nombre no puede quedar en blanco."});
    else this.setState({error: "Ups! Algo salió mal. Por favor, revisar los datos ingresados."});
    this.setState({hayError: true})
  }

  //Recibe la mitologia seleccionada y la fecha ya convertida correctamente.
  cargarMitologiaSeleccionada(mitologia, fecha) {
    let { mitologiaSeleccionada } = this.state;
    mitologiaSeleccionada.id = mitologia.id;
    mitologiaSeleccionada.nombre = mitologia.nombre;
    mitologiaSeleccionada.descripcion = mitologia.descripcion;
    mitologiaSeleccionada.fecha = fecha;
    this.setState({
      mitologiaSeleccionada: mitologiaSeleccionada,
      verMasModal: true,
    });
  }

  toggleVerMasModal() {
    this.setState({
      verMasModal: !this.state.verMasModal,
    });
  }

  //Para editar una mitología, se utiliza el modal de crear mitología y el estado nuevaMitologiaData
  toggleEditarMitologia() {
    this.setState({
      nuevaMitologiaData: this.state.mitologiaSeleccionada,
      verMasModal: false,
      crearMitologiaModal: true,
      editandoMitologia: true
    })
  }

  editarMitologia() {
    this.props.authAxios.put(`/eneas/mitologias/${this.state.mitologiaSeleccionada.id}/`, this.state.nuevaMitologiaData).then((Response => {
      this.cerrarCrearMitologia();
      this.obtenerMitologias();
    }))
    .catch((error => {
      this.capturarError(error.response)
    }))
  }

  toggleBorrarMitologiaModal() {
    this.setState({
      borrarMitologiaModal: !this.state.borrarMitologiaModal
    });
  }

  borrarMitologia() {
    //Hacer delete
    this.props.authAxios.delete(`/eneas/mitologias/${this.state.mitologiaSeleccionada.id}/`)
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });;
    //Actualizar lista de mitologias
    let {mitologias} = this.state;
    mitologias = this.state.mitologias.filter(m => m.id !== this.state.mitologiaSeleccionada.id);
    this.setState({mitologias});
    this.toggleBorrarMitologiaModal();
    this.toggleVerMasModal();
  }

  cerrarCrearMitologia() {
    this.setState({
      crearMitologiaModal: false,
      editandoMitologia: false,
      nuevaMitologiaData: {
        nombre: '',
        descripcion: ''
      },
      hayError: false,
    });
  }

  toggleErrorMsg() {
    this.setState({hayError: !this.state.hayError})
  }

  render() {  
    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>)

    //Cargar mitologias
    let mitologias = this.state.mitologias.sort((a, b) => { return (a.nombre > b.nombre) ? 1 : -1;})
    .map((mitologia) => {
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(mitologia.fecha_creacion));
      //Achicar descripcion a a 150 caracteres y concatenar '...'
      let descChica = mitologia.descripcion;
      let verMas = null;
      if (descChica.length > 150) {
        descChica = descChica.substring(0, 150) + "...";
        verMas = <Button color="link" size="sm" className="active" onClick={this.cargarMitologiaSeleccionada.bind(this, mitologia, fecha)}>Ver más</Button>
      }
      return (
        <tr key={mitologia.id}>
          <td><Link to={`/home/eneas/${mitologia.id}/mitos/`} style={{ color: '#000' }}>{mitologia.nombre}</Link></td>
          <td><p className="text-justify">{descChica ? descChica : "La mitología no tiene descripción."}{verMas}</p></td>
          <td><Button outline color="primary" href={`/home/eneas/${mitologia.id}/mitos/`}>Mitos</Button></td>
          <td><Button outline color="info" onClick={this.cargarMitologiaSeleccionada.bind(this, mitologia, fecha)}>Info</Button></td>
        </tr>
      )
    });

    //Ver si la lista de mitologias está vacía
    let noHayMitologias = <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    if (mitologias.length === 0 && this.state.cargado) noHayMitologias = (<p>Aún no se ha ingresado ninguna mitología.</p>);
    else if (this.state.cargado) noHayMitologias = null;

    return (
      <div className="container-sm">
        <h1 className="display-4">Mitologías</h1>
        <div className="scrollbarListas" id='bordeScrollbar'>
          {noHayMitologias}
          <Table>
            <tbody>
              {mitologias}
            </tbody>
          </Table>
        </div>
        <Form inline className="mt-4">
          <p className="lead">Cantidad de mitologías: {mitologias.length}</p>
          <hr/>
          <Button outline color="success" size="lg" className="margenAbajo" onClick={this.toggleCrearMitologiaModal.bind(this)}>+</Button>
        </Form>
        <Modal isOpen={this.state.crearMitologiaModal} toggle={this.toggleCrearMitologiaModal.bind(this)}>
          <ModalHeader toggle={this.toggleCrearMitologiaModal.bind(this)}>{this.state.editandoMitologia ? "Editar mitología" : "Agregar mitología"}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="nombre">Nombre</Label>
                <Input id="nombre" placeholder="Nombre para la mitología" value={this.state.nuevaMitologiaData.nombre} onChange={(e) =>{
                  let { nuevaMitologiaData } = this.state;
                  nuevaMitologiaData.nombre = e.target.value;
                  this.setState({ nuevaMitologiaData });
                }}/>
              </FormGroup>
              <FormGroup>
                <Label for="descripcion">Descripción</Label>
                <Input id="descripcion" type="textarea" placeholder="Breve descripción" value={this.state.nuevaMitologiaData.descripcion} onChange={(e) =>{
                  let { nuevaMitologiaData } = this.state;
                  nuevaMitologiaData.descripcion = e.target.value;
                  this.setState({ nuevaMitologiaData });
                }}/>
              </FormGroup>
            </Form>
            <Alert color="danger" isOpen={this.state.hayError} toggle={this.toggleErrorMsg.bind(this)}>
              {this.state.error}
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" 
            onClick={this.state.editandoMitologia ? this.editarMitologia.bind(this) : this.crearMitologia.bind(this)}>
              {this.state.editandoMitologia ? "Confirmar" : "Agregar"}
            </Button>{' '}
            <Button color="secondary" onClick={this.cerrarCrearMitologia.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.verMasModal} toggle={this.toggleVerMasModal.bind(this)}>
          <ModalHeader toggle={this.toggleVerMasModal.bind(this)}>{this.state.mitologiaSeleccionada.nombre}<p className='letraChica'>{this.state.mitologiaSeleccionada.fecha}</p></ModalHeader>
          <ModalBody>
            <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{this.state.mitologiaSeleccionada.descripcion ? this.state.mitologiaSeleccionada.descripcion : "La mitología no tiene descripción."}</p>
            <Modal isOpen={this.state.borrarMitologiaModal} toggle={this.toggleBorrarMitologiaModal.bind(this)}>
              <ModalBody>
                La mitología, junto con todos sus mitos, se borrará permanentemente. ¿Estás seguro que deseas continuar?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.borrarMitologia.bind(this)}>Confirmar</Button>{' '}
                <Button color="secondary" onClick={this.toggleBorrarMitologiaModal.bind(this)}>Cancelar</Button>
              </ModalFooter>
            </Modal>
          </ModalBody>
          <ModalFooter>
            <Button outline color="danger" className="mr-auto" href={`/home/eneas/${this.state.mitologiaSeleccionada.id}/mitos/`}>Mitos</Button>
            <Button color="primary" onClick={this.toggleEditarMitologia.bind(this)}><strong>✎</strong><p className='letraChica'>Editar</p></Button>
            <Button color="danger" onClick={this.toggleBorrarMitologiaModal.bind(this)}><strong>🗑</strong><p className='letraChica'>Borrar</p></Button>
            <Button color="secondary" onClick={this.toggleVerMasModal.bind(this)}><strong>X</strong><p className='letraChica'>Cerrar</p></Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Mitologias);