import React from 'react';
import { ReactMic } from 'react-mic';
import { Input, } from 'reactstrap';
import { Button, Tabs, Tab, Divider, Card, CardContent } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { fetch_stream } from './utils/Streaming';

class AudioHandler extends React.Component {

constructor(props) {
  super(props);
  this.state = {
    record: false,
    urlBlob: null,
    file: null,
    blob: null,
    valueTab: 0,
    cargando: false,
    mensaje: '',
  }
}

changeRecording = () => {
  this.setState({ record: !this.state.record });
}

onStop(recordedBlob) {
  const url = URL.createObjectURL(recordedBlob.blob);
  this.setState({file: null, urlBlob: url, blob: recordedBlob.blob});
}

realizarTranscripcion() {
  this.setState({cargando: true});
  const formData = new FormData();
  let file = this.state.file;
  if (this.state.blob && !this.state.file) {
    file = new File([this.state.blob], "audio.webm", {type: "audio/webm"});
  }
  formData.append("audio", file);
  
  let transcripcion = null;
  fetch_stream("/ceres/", formData, (mensaje) => {
    if (!mensaje.startsWith('{')) {
      this.setState({mensaje: mensaje});
    } else {
      transcripcion = mensaje;
    }
  }, () => {
    transcripcion = JSON.parse(transcripcion);
    if (transcripcion.error) {
      this.props.enviarTranscripcion(null, null, null, transcripcion.error);
      return;
    }
    let texto = "<p>" + transcripcion.texto + "</p>"; 
    //Pongo los <p>, </p> en vez de los \n
    texto = texto.replace(/\n/g, '</p><p>');
    this.props.enviarTranscripcion(transcripcion.id, transcripcion.titulo, texto, null);
  }, (error) => {
    //No debería darse nunca, dirigirse a InternalServerError
    this.props.history.push('/ErrorInterno')
  }, false);
}

render() {
  return (
    <div className="centrar2">
      <Card className="my-2" style={{backgroundColor: '#f5f5f5'}}>
        <CardContent>
          <p className="text-justify mb-0" style={{fontSize: '14px'}}>El audio de tu sueño será procesado y se escribirán el título y el contenido del sueño con inteligencia artificial. Luego puedes modificarlo a tu gusto.</p>
        </CardContent>
      </Card>
      <Tabs
        value={this.state.valueTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, newValue) => {
          this.setState({valueTab: newValue, urlBlob: null, file: null});
        }}
        centered
        className='mb-3'
      >
        <Tab label="Grabar" className='btnSinBorde' />
        <Tab label="Subir audio" className='btnSinBorde' />
      </Tabs>
      {this.state.valueTab === 0 ?
        <>
          <Button onClick={this.changeRecording} variant="outlined" startIcon={!this.state.record ? <MicIcon /> : <StopIcon />}>
            {!this.state.record ? "Comenzar a grabar" : "Terminar grabación"}
          </Button>
          <div style = {{overflow: 'hidden', width: '100%', display: this.state.record ? 'block' : 'none'}}>
            <ReactMic
              record={this.state.record}
              onStop={(audio) => this.onStop(audio)}
              strokeColor="#000000"
              backgroundColor="white"
              mimeType="audio/mp3" />
          </div>
        </>
      :
      <div style={{width: 'fit-content'}}>
        <Input
          type="file"
          onChange={(e) => this.setState({urlBlob: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], blob: null})}
        />
      </div>
      }
      {this.state.urlBlob && !this.state.record && 
      (<div style={{width: '100%'}} className="centrar2 mt-3">
        <Divider style={{width: '100%'}}/>
        <AudioPlayer
          src={this.state.urlBlob}
          className='my-3'
        />
        {this.state.cargando? 
        <div className="centrar2">
          <Spinner className='mb-2'/>
          <i className="text-center">{this.state.mensaje}</i>
        </div> 
        :
        <Button onClick={() => this.realizarTranscripcion()} color="primary" variant="contained">
          Confirmar
        </Button>}
      </div>)}
    </div>
    );
  }
}

export default AudioHandler;