import React from 'react';
import '../Recursos.css';
import { Spinner, Form } from 'reactstrap';
import {Button as ButtonUI, Card, CardContent, Typography, Paper, IconButton} from '@material-ui/core';
import { Button } from 'reactstrap';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';
import {withRouter} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { fetch_stream } from '../utils/Streaming';
import ShareIcon from '@material-ui/icons/Share';
import { ChatAPDF } from '../PDF/CreadorPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

class Chat extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      conversacion: [],
      cargado: false,
      generando: false,
      respuesta: '',
      error: null,
      messagesEnd: null,
      fecha_creacion: null,
    };
  }

  scrollToBottom = () => {
    if (this.state.messagesEnd === null || this.state.descargarPDF) return;
    this.state.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentDidMount(){
    this.obtenerConversacion();
    this.scrollToBottom();
  }

  obtenerConversacion() {
    let endpoint = `/quiron/chat/${this.props.match.params.id}/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      let { mensajes, fecha_creacion } = Response.data;
      this.setState({
        conversacion: mensajes.sort((a, b) => a.num_mensaje - b.num_mensaje),
        fecha_creacion: fecha_creacion,
        cargado: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  enviarMensaje() {
    if (this.state.respuesta === '' && this.state.conversacion.length !== 0) {
      this.setState({error: 'No puede enviar un mensaje vacío.'});
      return;
    }
    const respuesta = this.state.respuesta;
    this.setState({generando: true, respuesta: '', error: null});
    const conversacion = this.state.conversacion;
    const largo = conversacion.length;
    if (largo === 0) {
      conversacion.push({num_mensaje: 1, es_usuario: false, contenido: ''});
    } else {
      conversacion.push({num_mensaje: largo+1, es_usuario: true, contenido: respuesta});
      conversacion.push({num_mensaje: largo+2, es_usuario: false, contenido: ''});
    }
    this.setState({conversacion: conversacion});

    let primero = true;
    fetch_stream(`/quiron/chat/${this.props.match.params.id}/`, {pregunta: respuesta}, (mensaje) => {
      if (mensaje.startsWith('<PREDATA>')) {
        mensaje = mensaje.replace('<PREDATA>', '');
        conversacion[largo === 0 ? 0 : largo+1].contenido = "<i>" + mensaje + "</i>";
      } else if (primero) {
        conversacion[largo === 0 ? 0 : largo+1].contenido = mensaje;
        primero = false;
      } else {
        conversacion[largo === 0 ? 0 : largo+1].contenido += mensaje;
      }
      this.setState({conversacion: conversacion});
    }, () => {
      this.setState({generando: false, error: null});
      this.obtenerConversacion();
    }, (error) => {
      console.log(error);
      this.setState({generando: false, error: 'Hubo un error al enviar el mensaje.'});
    });
  }

  cambiarMessageEnd = (element) => {
    this.setState({messagesEnd: element});
  }

  parsearSueniosEnTexto(texto) {
    // Todos los /numero/ se reemplazan por el numero en formato link que dirije a la pagina de ese sueño
    let regex = /\/\d+\//g;
    let match = texto.match(regex);
    if (match === null) return texto;
    let textoParseado = texto;
    match.forEach((numero) => {
      let numeroParseado = numero.replace(/\//g, '');
      textoParseado = textoParseado.replace(numero, sessionStorage.rol === 'Soñante' ? `<a href="/home/suenios/${numeroParseado}">${numeroParseado}</a>` : `<a href="/home/pacientes/${this.props.match.params.idPaciente}/suenios/${numeroParseado}">${numeroParseado}</a>`);	
    });
    return textoParseado;
  }

  volverAtras() {
    //Determinar rol (soñante o analista)
    let dir =`/home/pacientes/${this.props.match.params.idPaciente}/quiron/`;
    if(sessionStorage.rol === 'Soñante') dir = `/home/quiron/`;
    this.props.history.push(dir);
  }

  chatTextoSimple() {

    let texto = '';
    this.state.conversacion.forEach((mensaje) => {
      if (mensaje.es_usuario) {
        texto += `Yo: ${mensaje.contenido}\n\n`;
      } else {
        texto += `Quirón: ${mensaje.contenido}\n\n`;
      }
    });
    return texto.trim();
  }

  render() {

    if (!this.state.cargado) { 
      return <Spinner style={{ width: '3rem', height: '3rem' }} className="mt-3" />;
    }

    //Agregar botón de descarga
    let PDFChat = <Button outline color="success" size="sm" className="mr-2" onClick={() => this.setState({descargarPDF: true})} disabled={this.state.generando}><strong>⇣</strong><p className='letraChica'>Exportar</p></Button>;
    let fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"}).format(new Date(this.state.fecha_creacion))
    if (this.state.descargarPDF) PDFChat = (<PDFDownloadLink className="mr-2" document={<ChatAPDF chat={this.state.conversacion} fecha={fecha}/>} fileName={`quiron${this.state.fecha_creacion}.pdf`}>
      {({ blob, url, loading, error }) => (loading ? 'Generando PDF...' : 'Click aquí!')}
    </PDFDownloadLink>);

    return (
      <>
      <Form inline>
        <IconButton onClick={this.volverAtras.bind(this)} aria-label="volver" size="medium" className="mt-2">
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <div className="ml-2 mr-2">
          <h4 className="display-4 mb-0 mt-0 mt-md-3">Chat con Quirón</h4>
          <h1 className="lead">
            {new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"}).format(new Date(this.state.fecha_creacion))}
          </h1>
        </div>
        <hr/>
        <div className="mb-2">
          {PDFChat}
          <Button outline color="secondary" size="sm" className="mr-2" onClick={() => {navigator.share({text: this.chatTextoSimple(), link: "www.mercurio.org.uy"})}} disabled={this.state.generando}><ShareIcon fontSize='small'/><p className='letraMuyChica'>Compartir</p></Button>
        </div>
      </Form>
      <Paper className="centrar p-3 mb-4 mt-2" variant="outlined" style={{'backgroundColor': '#f5f5f5'}}>
        {this.state.conversacion.map((mensaje) => (
          <div key={mensaje.num_mensaje} className={mensaje.es_usuario ? "" : "derecha"}>
            <Card style={{'width': '95%'}} className="mb-3">
              <CardContent>
                <Typography variant="h6" component="div">
                  {mensaje.es_usuario ? "Yo" : "Quirón"}
                </Typography>
                <Typography className="text-justify" style={{'whiteSpace': 'pre-line'}} variant="body2">
                  {ReactHtmlParser(this.parsearSueniosEnTexto(mensaje.contenido))}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
        <div className="centrar2">
          <div className="w-100">
            <textarea
              className="form-control"
              placeholder="Escriba su mensaje..."
              rows="3"
              value={this.state.respuesta}
              disabled={this.state.generando}
              onChange={(event) => this.setState({respuesta: event.target.value})}
            />
          </div>
          <div className="derecha mt-3 w-100">
            {!this.state.generando? (<ButtonUI
              variant="contained"
              color="primary"
              startIcon={<QuestionAnswerOutlinedIcon />}
              onClick={() => this.enviarMensaje()}
            >
              Enviar
            </ButtonUI>) : 
            (<Spinner style={{ width: '2rem', height: '2rem' }}/>)}
          </div>
          {this.state.error !== null ? (<Alert severity="error" className='mt-2'>{this.state.error}</Alert>) : null}
          <div style={{ float:"left", clear: "both" }}
            ref={this.cambiarMessageEnd}>
          </div>
        </div>
      </ Paper>
    </>
    );
  }
}

export default withRouter(Chat);