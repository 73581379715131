import React from 'react';
import './Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Spinner, Alert } from 'reactstrap';
import { BrowserRouter as Router, Link, withRouter} from "react-router-dom";

class PacienteSoniante extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      nuevoPacienteData: {
        nombre: '',
        sexo: 'no especifica',
        fecha_nacimiento: null,
        fecha_ingreso: null,
        ocupacion: ''
      },
      error: null,
      errorMsg: false,
    };

    this.sonianteTienePaciente();
  }

  sonianteTienePaciente() {
    //Hacer get
    this.props.authAxios.get(`/suenios/`)
      .then((Response) => {
        this.props.history.push('/home/suenios/')
    })
  }

  crearPaciente() {
    this.props.authAxios.post('/paciente_soniante/', this.state.nuevoPacienteData).then((Response => {
      this.props.history.push('/home/suenios/')
    }))
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  capturarError(response) {
    if (response.data.nombre) this.state.error = "El nombre del paciente no puede quedar en blanco.";
    else this.state.error = "Ups! Algo salió mal. Por favor, revisar los datos ingresados.";
    this.setState({errorMsg: true})
  }

  toggleErrorMsg() {
    this.setState({errorMsg: !this.state.errorMsg})
  }

  render() {
    return (
      <div className="container-sm">
        <h1 className="display-4">Por favor, ingresa los siguientes datos:</h1>
        <Form>
          <FormGroup>
            <Label for="nombre">Nombre</Label>
            <Input id="nombre" placeholder="Introduzca nombre y apellido" value={this.state.nuevoPacienteData.nombre} onChange={(e) =>{
              let { nuevoPacienteData } = this.state;
              nuevoPacienteData.nombre = e.target.value;
              this.setState({ nuevoPacienteData });
            }}/>
            <p className='letraChica'>Si lo prefieres, puedes poner sólo las iniciales.</p>
          </FormGroup>
          <FormGroup>
            <Label for="sexo">Género</Label>
            <Input type="select" name="select" id="sexo" value={this.state.nuevoPacienteData.sexo} onChange={(e) =>{
              let { nuevoPacienteData } = this.state;
              nuevoPacienteData.sexo = e.target.value;
              this.setState({ nuevoPacienteData });
            }}>
              <option>femenino</option>
              <option>masculino</option>
              <option>no binario</option>
              <option>transgénero</option>
              <option>otro</option>
              <option>no especifica</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="fechaNacimiento">Fecha de nacimiento (opcional)</Label>
            <Input value={this.state.nuevoPacienteData.fecha_nacimiento ? this.state.nuevoPacienteData.fecha_nacimiento : ""}
              type="date"
              name="date"
              id="fechaNacimiento"
              onChange={(e) =>{
                let { nuevoPacienteData } = this.state;
                nuevoPacienteData.fecha_nacimiento = e.target.value;
                if (nuevoPacienteData.fecha_nacimiento === "") nuevoPacienteData.fecha_nacimiento = null;
                this.setState({ nuevoPacienteData });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="fechaIngreso">Fecha de inicio de terapia (opcional)</Label>
            <Input value={this.state.nuevoPacienteData.fecha_ingreso ? this.state.nuevoPacienteData.fecha_ingreso : ""}
              type="date"
              name="date"
              id="fechaIngreso"
              onChange={(e) =>{
                let { nuevoPacienteData } = this.state;
                nuevoPacienteData.fecha_ingreso = e.target.value;
                if (nuevoPacienteData.fecha_ingreso === "") nuevoPacienteData.fecha_ingreso = null;
                this.setState({ nuevoPacienteData });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="ocupacion">Ocupación (opcional)</Label>
            <Input id="ocupacion" placeholder="Introduzca ocupación del paciente" value={this.state.nuevoPacienteData.ocupacion}
            onChange={(e) =>{
              let { nuevoPacienteData } = this.state;
              nuevoPacienteData.ocupacion = e.target.value;
              this.setState({ nuevoPacienteData });
            }}/>
          </FormGroup>
        </Form>
        <Alert color="danger" isOpen={this.state.errorMsg} toggle={this.toggleErrorMsg.bind(this)}>
          {this.state.error}
        </Alert>
        <div className='derecha'>
          <Button color="primary mr-sm-2" onClick={this.crearPaciente.bind(this)}>Confirmar</Button>
          <Button color="secondary" onClick={() => this.props.history.push('/home/suenios/')}>Cancelar</Button>
        </div>
      </div>
    );
  }
}

export default withRouter(PacienteSoniante);