import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Badge, Row, Col } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";

class SueniosInforme extends React.Component {

  constructor(props) {
    super(props);
    console.log(this.props.informe)
    this.state = {
      informe: this.props.informe,
        verMasModal: false,
        suenioSeleccionado: {
            id: "",
            paciente: "",
            descripcion: "",
            nombre: "",
            fecha: "",
            es_pasivo: false,
            simbolos: [],
            sentimientos: [],
            contextos: []
        },
      }
  }

  //Recibe el suenio seleccionado, la descripción ya procesada y la fecha ya convertida correctamente.
  cargarSuenioSeleccionado(suenio, desc, fecha) {
    let { suenioSeleccionado } = this.state;
    suenioSeleccionado.id = suenio.id;
    suenioSeleccionado.paciente = suenio.paciente.nombre;
    suenioSeleccionado.descripcion = desc;
    suenioSeleccionado.nombre = suenio.nombre;
    suenioSeleccionado.fecha = fecha;
    suenioSeleccionado.es_pasivo = suenio.es_pasivo;
    suenioSeleccionado.simbolos = suenio.simbolos;
    suenioSeleccionado.sentimientos = suenio.sentimientos;
    suenioSeleccionado.contextos = suenio.contextos;
    this.setState({
      suenioSeleccionado: suenioSeleccionado,
      verMasModal: true
    });
  }

  toggleVerMasModal() {
    this.setState({
      verMasModal: !this.state.verMasModal
    });
  }

  render() {
    //Cargar suenios
    let suenios = this.state.informe.suenios.sort((a, b) => {if(a.fecha == b.fecha) { return (a.descripcion > b.descripcion) ? 1 : -1;
    } else { return (a.fecha > b.fecha) ? -1 : 1; }}).map((suenio) => {
      //Sacar los símbolos utilizados para ocultar información del sueño
      let desc = suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = desc;
      if (desc.length > 250) {descChica = desc.substring(0, 250) + "...";}
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(suenio.fecha + 'T00:00:00'));
      return (
        <tr key={suenio.id}>
          <td><Link onClick={this.cargarSuenioSeleccionado.bind(this, suenio, desc, fecha)} style={{ color: '#000' }}>{suenio.nombre}</Link>
            <p className="small">{fecha} <br/> {suenio.paciente.nombre}</p>
          </td>
          <td><p className="text-justify">{descChica}<Button color="link"  size="sm" className="active" onClick={this.cargarSuenioSeleccionado.bind(this, suenio, desc, fecha)}>Ver más</Button></p></td>
        </tr>
      )
    });

    //Cargar Simbolos, Sentimientos y Contextos de sueño seleccionado
    let simbolos = this.state.suenioSeleccionado.simbolos.map((simbolo) => {
      return (<Col sm={{size: 'auto'}} key={simbolo.id}><h5><Badge color="primary" pill>{ simbolo.nombre }</Badge></h5></Col>)
    });
    let sentimientos = this.state.suenioSeleccionado.sentimientos.map((sentimiento) => {
      return (<Col sm={{size: 'auto'}} key={sentimiento.id}><h5><Badge color="warning" pill>{sentimiento.nombre}</Badge></h5></Col>)
    });
    let contextos = this.state.suenioSeleccionado.contextos.map((contexto) => {
      return (<Col sm={{size: 'auto'}} key={contexto.id}><h5><Badge color="info" pill>{ contexto.nombre }</Badge></h5></Col>)
    });

    //Ver si la lista de sueños está vacía
    let noHaySueños = null;
    if (suenios.length === 0) noHaySueños = (<p>El informe no ha retornado ningún sueño.</p>);

    return (
      <div className="container-sm mt-4">
        <div className="scrollbarListas" id='bordeScrollbar'>
          {noHaySueños}
          <Table>
            <tbody>
              {suenios}
            </tbody>
          </Table>
        </div>
        <Modal isOpen={this.state.verMasModal} toggle={this.toggleVerMasModal.bind(this)}>
          <ModalHeader toggle={this.toggleVerMasModal.bind(this)}>{this.state.suenioSeleccionado.nombre}</ModalHeader>
          <ModalBody>
            <h6>Contenido</h6>
            <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{this.state.suenioSeleccionado.descripcion}</p>
            <h6>Fecha</h6>
            <p>{this.state.suenioSeleccionado.fecha}</p>
            <h6>Paciente</h6>
            <p>{this.state.suenioSeleccionado.paciente}</p>
            <h6>Rol del soñante</h6>
            <p>{this.state.suenioSeleccionado.es_pasivo ? 'Pasivo' : 'Activo'}</p>
            <h6>Símbolos</h6>
            {simbolos.length ? <Row className='margenAbajo'>{simbolos}</Row> : <p>El sueño no tiene símbolos asociados</p>}
            <h6>Sentimientos y emociones</h6>
            {sentimientos.length ? <Row className='margenAbajo'>{sentimientos}</Row> : <p>El sueño no tiene sentimientos o emociones asociadas</p>}
            <h6>Contextos del soñante</h6>
            {contextos.length ? <Row className='margenAbajo'>{contextos}</Row> : <p>El sueño no tiene contextos asociados</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleVerMasModal.bind(this)}>Cerrar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SueniosInforme);