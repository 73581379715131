import React from 'react';
import './Recursos.css';
import {withRouter, Link} from "react-router-dom";
import {Badge, ButtonGroup, Button, Tooltip, Snackbar, Dialog, DialogContent, DialogContentText} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Popover, PopoverBody, PopoverHeader, Form } from 'reactstrap';
import moment from "moment"


class Notificaciones extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      abrirNotif: false,
      prueba: false,
      notificaciones: [],
      verMasModal: false,
      cambiarRol: false,
      bienvenidoNotificaciones: false,
    }
  }

  componentDidMount() {
    //Agarrar las notificaciones
    this.props.authAxios.get('/notificaciones/')
    .then((Response) => {
      this.setState({notificaciones: Response.data});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  toggleNotif() {
    this.setState({abrirNotif: !this.state.abrirNotif})
  }

  cambiarVisto(id) {
    //Hacer put vacío para invertir valor de atributo visto de notificacion
    this.props.authAxios.put(`/notificaciones/${id}/`)
    .then((Response) => {
      let notifs  = this.state.notificaciones;
      notifs = notifs.filter(notif => notif.id !== id)
      this.setState({notificaciones: [...notifs, Response.data]});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  marcarTodasVistas(id) {
    //Hacer put para marcar todas las notificaciones como vistas
    this.props.authAxios.put(`/notificaciones/visto_todas/`)
    .then((Response) => {
      let { notificaciones } = this.state;
      for(var notif of notificaciones) {
        notif.visto = true
      }
      this.setState({notificaciones});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  toggleVerMasModal() {
    this.setState({
      verMasModal: !this.state.verMasModal,
      abrirNotif: false
    });
  }

  toggleCambiarRol() {
    this.setState({
      cambiarRol: !this.state.cambiarRol,
    });
  }

  irASuenio(notif) {
    this.setState({abrirNotif: false, verMasModal: false});
    const suenio = notif.suenio? notif.suenio : notif.comentario? notif.comentario.id_suenio : null;
    if (!notif.visto)
      this.cambiarVisto(notif.id);
    // Si la notificación NO es una novedad
    if(suenio) {
      //Ver si la notificación es de un paciente o terapeuta
      this.props.authAxios.get('/pacientes/')
      .then((Response) => {
          const soniante = Response.data.filter(p => p.soniante === notif.usuario_actor);
          if (soniante.length > 0 && sessionStorage.getItem('rol') === "Analista") {
            this.props.history.push(`/home/pacientes/${soniante[0].id}/suenios/${suenio}/`);
            this.props.history.go(0);
          } else if (soniante.length === 0 && sessionStorage.getItem('rol') === "Soñante") {
            this.props.history.push(`/home/suenios/${suenio}`);
            this.props.history.go(0);
          } else {
            this.setState({cambiarRol: true})
          }
        })
      .catch((error) => {
        //No tiene el rol de terapeuta
        this.props.history.push(`/home/suenios/${suenio}`);
      });
    //La notificación es una novedad
    } else {
      //Abrir Dialog de bienvenido notificaciones, si esa es la novedad
      if (notif.novedad === "Bienvenido a las notificaciones. Saber más...")
        this.toggleBienvenidoNotificaciones()
    }
  }

  toggleBienvenidoNotificaciones() {
    this.setState({
      bienvenidoNotificaciones: !this.state.bienvenidoNotificaciones,
    });
  }

  render() { 
    //Ordenar todas las notificaciones
    let notificacionesOrdenadas = this.state.notificaciones.sort((a, b) => a.fecha_creacion > b.fecha_creacion ? -1 : 1)
    //Quedarme sólo con las 6 notificaciones más nuevas
    let ultimasNotificaciones = notificacionesOrdenadas.slice(0, 5);
    
    //Calcular tamaño máximo de frase de ultimas notificaciones
    let tamTotal = 0; 
    for(var notif of ultimasNotificaciones) {
      let frase = notif.suenio? 
        notif.usuario_actor + " ha ingresado un nuevo sueño"  : notif.comentario?
          notif.usuario_actor + " ha agregado un/a " + notif.comentario.tipo.tipo + " a un sueño" : notif.novedad?
            notif.novedad : null;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = getComputedStyle(document.body).font;
      let tamFrase =  context.measureText(frase).width;
      if (tamFrase > tamTotal) tamTotal = tamFrase + 50;
    };

    //Cargar ultimas notificaciones
    let notificaciones = ultimasNotificaciones.map((notif) => {
      let frase = notif.suenio? 
        notif.usuario_actor + " ha ingresado un nuevo sueño"  : notif.comentario?
          notif.usuario_actor + " ha agregado un/a " + notif.comentario.tipo.tipo + " a un sueño" : notif.novedad?
            notif.novedad : null;
      let haceXDias = moment().diff(notif.fecha_creacion, "days");
      return (
        <div key={notif.id} className="derecha">
          <ButtonGroup variant="text" aria-label="text black button group">
            <Button style={{textTransform: 'none', width: tamTotal, maxWidth: "70vw", justifyContent: 'flex-start'}} onClick={this.irASuenio.bind(this, notif)}>
              <div>
                <p style={{whiteSpace: 'normal', marginBottom: 0}}>{notif.visto ? frase : <strong>{frase}</strong>}</p> 
                <p className="izquierda letraChica" style={{marginTop: 0}}>{haceXDias === 0 ? "Hoy" : haceXDias === 1 ? "Ayer" : "Hace " + haceXDias + " días"}</p>
              </div>
            </Button>
            <Button onClick={this.cambiarVisto.bind(this, notif.id)}>
              <Tooltip title={notif.visto? "Marcar como no visto" : "Marcar como visto" } aria-label="marcar">
                <h5 className='derecha'>{notif.visto? '⦾': '⦿'}</h5>
              </Tooltip>
            </Button>
          </ButtonGroup>
        </div>
      )
    });

    //Cargar TODAS las notificaciones si el modal de ver más está abierto
    let todasNotificaciones = false;
    if (this.state.verMasModal) {
      todasNotificaciones = notificacionesOrdenadas.map((notif, index) => {
        let frase = notif.suenio? 
          notif.usuario_actor + " ha ingresado un nuevo sueño"  : notif.comentario?
            notif.usuario_actor + " ha agregado un/a " + notif.comentario.tipo.tipo + " a un sueño" : notif.novedad?
              notif.novedad : null;
        let haceXDias = moment().diff(notif.fecha_creacion, "days");
        return (
          <tr key={notif.id}>
            <td>
              <Link  style={{ color: '#000' }}>{notif.visto ? frase : <strong>{frase}</strong>}</Link>
              <p className="letraChica" style={{marginTop: 0}}>{haceXDias === 0 ? "Hoy" : haceXDias === 1 ? "Ayer" : "Hace " + haceXDias + " días"}</p>
            </td>
            <td>
              <Button onClick={this.cambiarVisto.bind(this, notif.id)}>
                <Tooltip title={notif.visto? "Marcar como no visto" : "Marcar como visto" } aria-label="marcar">
                  <h5 class='derecha'>{notif.visto? '⦾': '⦿'}</h5>
                </Tooltip>
              </Button>
            </td>
          </tr>
        )
      });
    }

    return (
    <div>
    <Badge badgeContent={this.state.notificaciones.filter(notif => !notif.visto).length} max={99} color="secondary">
      <NotificationsIcon className='botonUsuario' id="Popover" onClick={this.toggleNotif.bind(this)}/>
    </Badge>
    <Popover popperClassName='notificaciones' trigger="legacy" placement='bottom-end' isOpen={this.state.abrirNotif} target={"Popover"} toggle={this.toggleNotif.bind(this)}>
      <PopoverHeader><Form inline>Notificaciones<hr/><Button color="primary" size='small' style={{textTransform: 'none'}} onClick={this.marcarTodasVistas.bind(this)}>Marcar todas como vistas</Button></Form></PopoverHeader>
      <PopoverBody className='scrollbarNotificaciones' id='bordeScrollbar'>
          {notificaciones.length === 0? <p>No hay notificaciones.</p> : notificaciones}
          {this.state.notificaciones.length > 5? <Button style={{textTransform: 'none', width: tamTotal, maxWidth: "70vw"}} onClick={this.toggleVerMasModal.bind(this)}>Ver más...</Button> : null}
      </PopoverBody>
    </Popover>
    <Modal isOpen={this.state.verMasModal} toggle={this.toggleVerMasModal.bind(this)}>
      <ModalHeader toggle={this.toggleVerMasModal.bind(this)}>Todas las notificaciones</ModalHeader>
      <ModalBody>
        {todasNotificaciones? null : "No hay notificaciones."}
        <div className="scrollbarListas" id='bordeScrollbar'>
          <Table striped>
          <thead>

          </thead>
            <tbody>
              {todasNotificaciones}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggleVerMasModal.bind(this)}>Cerrar</Button>
      </ModalFooter>
    </Modal>
    <Snackbar open={this.state.cambiarRol} autoHideDuration={6000} onClose={this.toggleCambiarRol.bind(this)} message='Tienes que cambiar de rol para poder acceder a esta notificación.'>
    </Snackbar>
    <Dialog open={this.state.bienvenidoNotificaciones} onClose={this.toggleBienvenidoNotificaciones.bind(this)}>
        <DialogContent>
          <h3>Bienvenido a las notificaciones</h3>
          <DialogContentText id="alert-dialog-description" style={{textAlign: 'justify'}}>
            <p>Las notificaciones son una nueva funcionalidad en Mercurio.</p>
            <p>Los usuarios <strong>terapeutas</strong> serán notificados de los nuevos sueños o comentarios (asociaciones, amplificaciones, etc...) que hayan subido sus pacientes.</p>
            <p>Los usuarios <strong>soñantes</strong> serán notificados si su terapeuta agrega un comentario (asociaciones, amplificaciones, etc...) en uno de sus sueños, o agrega un sueño al paciente.</p>
            <p>Al hacer click en la notificación, serás redirigido al sueño en cuestión.</p>
            <p>Si aún no has visto el sueño pero no quieres que siga activa la notificación, puedes hacer click en ⦿.</p>
            <p><strong>Las notificaciones que no estén activas serán borradas luego de 10 días. Si quieres que alguna se mantenga, puedes reactivarla  apretando en ⦾.</strong></p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
    );
  }
}

export default withRouter(Notificaciones);