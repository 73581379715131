import React from 'react';
import './Recursos.css';
import { Button, Form, FormGroup, Label, Input, Row, Col, ListGroup, ListGroupItem, Badge, ButtonGroup, UncontrolledPopover, PopoverBody, Spinner, Modal, ModalHeader } from 'reactstrap';
import {Button as ButtonUI, IconButton, Snackbar} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {Editor, EditorState, RichUtils} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {stateToHTML}  from 'draft-js-export-html'
import {stateFromHTML}  from 'draft-js-import-html'
import './SelectorTags.css'
import { Redirect, withRouter } from 'react-router-dom';
import SugerirSimbolos from './SugerirSimbolos';
import AudioHandler from './AudioHandler';
import MicIcon from '@material-ui/icons/Mic';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MuiAlert from '@material-ui/lab/Alert';
import AnnouncementIcon from '@material-ui/icons/Announcement';

class IngresarSuenio extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      editorState: EditorState.createEmpty(),
      simbolos: [],
      simbolosBuscados: [],
      sentimientos: [],
      sentimientosBuscados: [],
      contextos: [],
      contextosBuscados: [],
      suenioData: {
        descripcion: '',
        nombre: '',
        fecha: '',
        es_pasivo: false,
        simbolos: [],
        sentimientos: [],
        contextos: [],
        notificar: true,
        transcripcion: null,
      },
      redirectListarSuenios: false,
      nuevoSimbolo: '',
      nuevoSentimiento: '',
      nuevoContexto: '',
      error: null,
      errorMsg: false,
      sugerirSimbolosModal: false,
      cargado: false,
      opcionesAvanzadas: false,
      audio: null,
      audioModal: false,
    };

    //Cargar terminos
    this.obtenerTerminos();

    //Para el editor de texto...
    this.onChange = editorState => this.setState({editorState});
  }

  //Se marca el texto que será oculto en negrita, y luego se traducirá a la sintaxis de oculto
  _onBoldClick() {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
  }

  //Funciones para el manejo de tags
  obtenerTerminos() {
    //Hacer get simbolos
    this.props.authAxios.get(`/sol/simbolos/`)
    .then((Response1) => {
      //Hacer get sentimientos
      this.props.authAxios.get(`/sentimientos/`)
      .then((Response2) => {
        //Hacer get contextos
        this.props.authAxios.get(`/contextos/`)
        .then((Response3) => {
          //Guardar el estado, y al finalizar fijarse si hay caso de uso de editar suenio y cargarlo
          this.setState({
            simbolos: Response1.data, simbolosBuscados: Response1.data,
            sentimientos: Response2.data, sentimientosBuscados: Response2.data,
            contextos: Response3.data, contextosBuscados: Response3.data
          }, () => {if (this.props.match.params.idSuenio) this.cargarSuenio();
                    else this.setState({cargado: true})
                    })
    })})})
  }

  agregarSimbolo(nombre) {
    let { suenioData } = this.state;
    suenioData.simbolos = [...this.state.suenioData.simbolos, {nombre: nombre}]
    this.setState({
      suenioData,
      simbolos: this.state.simbolos.filter(simbolo => simbolo.nombre !== nombre),
      simbolosBuscados: this.state.simbolosBuscados.filter(simbolo => simbolo.nombre !== nombre)
    });
  }

  quitarSimbolo(nombre) {
    let { suenioData } = this.state;
    suenioData.simbolos = this.state.suenioData.simbolos.filter(simbolo => simbolo.nombre !== nombre);
    this.setState({
      suenioData,
      simbolos: [...this.state.simbolos, {id: nombre, nombre: nombre}],
      simbolosBuscados:[...this.state.simbolosBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaSimbolos(busquedaSimbolo) {
    const { simbolos } = this.state;
    let simbolosBuscados = simbolos.filter(simbolo => simbolo.nombre.toLowerCase().includes(busquedaSimbolo.toLowerCase()));
    this.setState({simbolosBuscados});
  }

  agregarSentimiento(nombre) {
    let { suenioData } = this.state;
    suenioData.sentimientos = [...this.state.suenioData.sentimientos, {nombre: nombre}];
    this.setState({
      suenioData,
      sentimientos: this.state.sentimientos.filter(sentimiento => sentimiento.nombre !== nombre),
      sentimientosBuscados: this.state.sentimientosBuscados.filter(sentimiento => sentimiento.nombre !== nombre)
    });
  }

  quitarSentimiento(nombre) {
    let { suenioData } = this.state;
    suenioData.sentimientos = this.state.suenioData.sentimientos.filter(sentimiento => sentimiento.nombre !== nombre);
    this.setState({
      suenioData,
      sentimientos: [...this.state.sentimientos, {id: nombre, nombre: nombre}],
      sentimientosBuscados:[...this.state.sentimientosBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaSentimientos(busquedaSentimiento) {
    const { sentimientos } = this.state;
    let sentimientosBuscados = sentimientos.filter(sentimiento => sentimiento.nombre.toLowerCase().includes(busquedaSentimiento.toLowerCase()));
    this.setState({sentimientosBuscados});
  }

  agregarContexto(nombre) {
    let { suenioData } = this.state;
    suenioData.contextos = [...this.state.suenioData.contextos, {nombre: nombre}];
    this.setState({
      suenioData,
      contextos: this.state.contextos.filter(contexto => contexto.nombre !== nombre),
      contextosBuscados: this.state.contextosBuscados.filter(contexto => contexto.nombre !== nombre)
    });
  }

  quitarContexto(nombre) {
    let { suenioData } = this.state;
    suenioData.contextos = this.state.suenioData.contextos.filter(contexto => contexto.nombre !== nombre);
    this.setState({
      suenioData,
      contextos: [...this.state.contextos, {id: nombre, nombre: nombre}],
      contextosBuscados:[...this.state.contextosBuscados, {id: nombre, nombre: nombre}]
    });
  }
  
  busquedaContextos(busquedaContexto) {
    const { contextos } = this.state;
    let contextosBuscados = contextos.filter(contexto => contexto.nombre.toLowerCase().includes(busquedaContexto.toLowerCase()));
    this.setState({contextosBuscados});
  }

  esPasivo(bool) {
    let { suenioData } = this.state;
    suenioData.es_pasivo = bool;
    this.setState({suenioData});
  }

  redirectListarSuenios() {
    this.setState({redirectListarSuenios: true})
  }

  procesarDescripcion() {
    //Convierto el texto actual del editor a HTML
    let texto = stateToHTML(this.state.editorState.getCurrentContent());
    //Saco los <p>, </p>, <br>, <em>, </em> y &nbsp; cuando corresponda, y los cambio por /n si implican un enter. Cambio &amp; por &.
    texto = texto.replace(/<\/p><p>/g, '\n').replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br>/g, '').replace(/&nbsp;/g, '').replace(/&amp;/g, '&').replace(/<em>/g, '').replace(/<\/em>/g, '');
    //Busco las secciones en negrita (entre <strong> y </strong>) y las transformo en <^ y ^>.
    texto = texto.replace(/<strong>/g, '<^').replace(/<p>/g, '').replace(/<\/strong>/g, '^>');

    let { suenioData } = this.state;
    suenioData.descripcion = texto;
    this.setState({suenioData});
  }

  guardarSuenio() {
    this.procesarDescripcion()

    if (this.props.match.params.idSuenio) {
      //Determinar rol (soñante o analista)
      let endpoint = `/pacientes/${this.props.match.params.id}/suenios/${this.props.match.params.idSuenio}/`;
      if (sessionStorage.rol === "Soñante") endpoint = `/suenios/${this.props.match.params.idSuenio}/`;
      //Hacer put
      this.props.authAxios.put(endpoint, this.state.suenioData).then((Response => {
        this.props.history.push({ pathname: '/home' + endpoint, state: { refresh: true }})
      }))
      .catch((error => {
        this.capturarError(error.response);
      }))
    } else {
      //Determinar rol (soñante o analista)
      let endpoint = `/pacientes/${this.props.match.params.id}/suenios/`;
      if (sessionStorage.rol === "Soñante") endpoint = `/suenios/`;
      //Hacer post
      this.props.authAxios.post(endpoint, this.state.suenioData).then((Response => {
        this.props.history.push('/home' + endpoint + `${Response.data.id}/`)
      }))
      .catch((error => {
        this.capturarError(error.response);
      }))
    }
  }

  capturarError(response) {
    let msg = null;
    if (response.data.descripcion) msg = "El contenido no puede quedar en blanco.";
    else if (response.data.fecha) msg = "La fecha del sueño no puede quedar en blanco.";
    else msg = "Ups! Algo salió mal. Por favor, revisar los datos ingresados.";
    this.setState({error: msg, errorMsg: true})
  }

  cargarSuenio() {
    //Determinar el rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.match.params.id}/suenios/${this.props.match.params.idSuenio}/`
    if (sessionStorage.rol === 'Soñante')
      endpoint = `/suenios/${this.props.match.params.idSuenio}/`

    //Hacer get suenio
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      //Cargar datos del sueño
      let { suenioData } = this.state;
      let texto = "<p>" + Response.data.descripcion + "</p>"; 
      //Pongo los <p>, </p> en vez de los \n
      texto = texto.replace(/\n/g, '</p><p>');
      //Busco las secciones ocultas (entre <^ y ^>) y las transformo en <strong> y </strong>.
      texto = texto.replace(/<\^/g, '<strong>').replace(/\^>/g, '</strong>');
      suenioData.descripcion = texto;
      suenioData.nombre = Response.data.nombre;
      suenioData.fecha = Response.data.fecha;
      suenioData.es_pasivo = Response.data.es_pasivo;
      suenioData.simbolos = Response.data.simbolos;
      suenioData.sentimientos = Response.data.sentimientos;
      suenioData.contextos = Response.data.contextos;
      suenioData.transcripcion = Response.data.transcripcion;
      this.setState({suenioData, editorState: EditorState.createWithContent(stateFromHTML(texto)), cargado: true})
      //Quitar simbolos, sentimientos y contextos del sueño de las opciones para elegir
      for (const c of Response.data.simbolos) {
        this.setState({
          simbolos: this.state.simbolos.filter(simbolo => simbolo.nombre !== c.nombre),
          simbolosBuscados: this.state.simbolos.filter(simbolo => simbolo.nombre !== c.nombre),
        });
      }
      for (const s of Response.data.sentimientos) {
        this.setState({
          sentimientos: this.state.sentimientos.filter(sentimiento => sentimiento.nombre !== s.nombre),
          sentimientosBuscados: this.state.sentimientos.filter(sentimiento => sentimiento.nombre !== s.nombre), 
        });
      }
      for (const c of Response.data.contextos) {
        this.setState({
          contextos: this.state.contextos.filter(contexto => contexto.nombre !== c.nombre),
          contextosBuscados: this.state.contextos.filter(contexto => contexto.nombre !== c.nombre), 
        });
      }
    })
  }

  toggleErrorMsg() {
    this.setState({errorMsg: !this.state.errorMsg})
  }

  toggleSugerirSimbolos(estadisticas) {
    if (this.state.sugerirSimbolosModal)
      this.props.authAxios.post('/sol/estadisticas_sugerencias_simbolos/', estadisticas)
    else
      this.procesarDescripcion()
    this.setState({sugerirSimbolosModal: !this.state.sugerirSimbolosModal})
  }

  confirmarSugerencias(simbolosSeleccionados, estadisticas) {
    let { suenioData, simbolos, simbolosBuscados } = this.state
    for (const s of simbolosSeleccionados) {
      if (suenioData.simbolos.filter(simbolo => simbolo.nombre === s).length === 0) {
        suenioData.simbolos = [...suenioData.simbolos, {id: s, nombre: s}]
        simbolos = simbolos.filter(simbolo => simbolo.nombre !== s)
        simbolosBuscados = simbolosBuscados.filter(simbolo => simbolo.nombre !== s)
      }
    }
    this.setState({
      simbolos,
      simbolosBuscados,
      suenioData
    });
    this.toggleSugerirSimbolos(estadisticas)
  }

  obtenerTranscripcion(idTranscripcion, titulo, texto, error=null) {
    if (error) {
      this.setState({error: error, errorMsg: true, modalAudio: false})
      return
    }
    this.setState(
      {
        suenioData: {
          ...this.state.suenioData,
          nombre: titulo,
          descripcion: texto,
          transcripcion: idTranscripcion
        },
        editorState: EditorState.createWithContent(stateFromHTML(texto)),
        modalAudio: false,
      }
    )
  }

  render() {
    // Si no se cargó todo, mostrar spinner
    if (!this.state.cargado) return (<Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>)

    //Cargar simbolos, sentimientos y contextos
    const simbolos = this.state.simbolosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((c) => {
      return (<ListGroupItem key={c.id} id={c.nombre} action onClick={(e) => this.agregarSimbolo(e.target.id)}>{c.nombre}</ListGroupItem>)
    })
    const sentimientos = this.state.sentimientosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((s) => {
      return (<ListGroupItem key={s.id} id={s.nombre} action onClick={(e) => this.agregarSentimiento(e.target.id)}>{s.nombre}</ListGroupItem>)
    })
    const contextos = this.state.contextosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((c) => {
      return (<ListGroupItem key={c.id} id={c.nombre} action onClick={(e) => this.agregarContexto(e.target.id)}>{c.nombre}</ListGroupItem>)
    })

    //Cargar los tags
    const simbolosSel = this.state.suenioData.simbolos.map((c) => {
      return (<h5 key={c.nombre}><Badge color="primary" id={c.nombre} className="mr-2" onClick={(e) => this.quitarSimbolo(e.target.id)}>{ c.nombre + ' x' }</Badge></h5>)
    })
    const sentimientosSel = this.state.suenioData.sentimientos.map((s) => {
      return (<h5 key={s.nombre}><Badge color="warning" id={s.nombre} className="mr-2" onClick={(e) => this.quitarSentimiento(e.target.id)}>{ s.nombre + ' x' }</Badge></h5>)
    })
    const contextosSel = this.state.suenioData.contextos.map((c) => {
      return (<h5 key={c.nombre}><Badge color="info" id={c.nombre} className="mr-2" onClick={(e) => this.quitarContexto(e.target.id)}>{ c.nombre + ' x' }</Badge></h5>)
    })

    //Determinar si el botón de ocultar está apretado o no, y elegir el estilo acorde
    let botonOcultar = (<IconButton className='btnSinBorde' style={{float: 'right'}} onClick={this._onBoldClick.bind(this)}><VisibilityIcon/></IconButton>);
    if (this.state.editorState.getCurrentInlineStyle().has('BOLD'))
      botonOcultar = (<IconButton className='btnSinBorde' style={{float: 'right'}} color="secondary" onClick={this._onBoldClick.bind(this)}><VisibilityIcon/></IconButton>);

    //Redirigir a listar sueños cuando termina el caso de uso
    let redirectListarSuenios = null;
    if (this.state.redirectListarSuenios && sessionStorage.rol !== "Soñante") 
      redirectListarSuenios = (<Redirect to={`/home/pacientes/${this.props.match.params.id}/suenios/`}/>);
    else if (this.state.redirectListarSuenios)
      redirectListarSuenios = (<Redirect to={`/home/suenios/`}/>);

    let sugerirSimbolos = null;
    if (this.state.sugerirSimbolosModal)
      sugerirSimbolos = <SugerirSimbolos authAxios={this.props.authAxios} historia={this.state.suenioData.descripcion} toggle={this.toggleSugerirSimbolos} father={this} confirmarSugerencias={this.confirmarSugerencias}/>
    
    let modalAudio = (
      <Modal 
        isOpen={this.state.modalAudio}
        toggle={() => this.setState({modalAudio: !this.state.modalAudio})}
      >
        <ModalHeader toggle={() => this.setState({modalAudio: !this.state.modalAudio})}>
          Registrar sueño por audio
        </ModalHeader>
        <div className='mb-3 mx-3'>
          <AudioHandler enviarTranscripcion={this.obtenerTranscripcion.bind(this)} authAxios={this.props.authAxios}/>
        </div>
      </Modal>
    )

    return (
      <div>
        {redirectListarSuenios}
        {modalAudio}
        <h1 className="display-4">{this.props.match.params.idSuenio ? "Editar sueño" : "Ingresa un sueño nuevo"}</h1>
        <br/>
        <div className='flexRowFull'>
          <Label for="titulo" className="mr-sm-2" style={{fontSize: 30}}>Título:</Label>
            <Input
              name="titulo"
              id="titulo"
              className='inputLinea mx-2'
              size="lg"
              value={this.state.suenioData.nombre}
              onChange={(e) => {
                let { suenioData } = this.state;
                suenioData.nombre = e.target.value;
                this.setState({ suenioData });
            }}/>
        </div>
        <br/>
        {!this.state.suenioData.transcripcion &&
        <MuiAlert icon={<AnnouncementIcon />} severity="error" className='mb-2'>
          <strong>¡NUEVO!</strong> Ahora puedes grabar el sueño y este será escrito con inteligencia artificial. ¡Haz click en el micrófono para probarlo!
        </MuiAlert>}
        <Label className="mr-sm-2" style={{fontSize: 30}}>Contenido:</Label>
        {botonOcultar}
        <IconButton outline className='btnSinBorde' style={{float: 'right'}} onClick={() => this.setState({modalAudio: true})} disabled={this.state.suenioData.transcripcion}><MicIcon/></IconButton>
        <br/>
        <div className='contorno scrollbar' id='bordeScrollbar'>
          <Editor editorState={this.state.editorState} onChange={this.onChange} blockStyleFn={() => 'padding'} placeholder="  Escriba su sueño aquí..." />
        </div>
        <br/>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="fecha" className="mr-sm-2" style={{fontSize: 30}}>Fecha: </Label>
            <Input type="date" name="fecha" id="fecha" value={this.state.suenioData.fecha} onChange={(e) =>{
                      let { suenioData } = this.state;
                      suenioData.fecha = e.target.value;
                      this.setState({ suenioData });
                      }}/>
          </FormGroup>
          <hr/>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="fecha" className="mr-2" style={{fontSize: 30}}>Rol del soñante: </Label>
            <ButtonGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Button outline size='sm' className={this.state.suenioData.es_pasivo === true ? 'active' : ''} onClick={this.esPasivo.bind(this, true)}>Pasivo</Button>
              <Button outline size='sm' className={this.state.suenioData.es_pasivo === false ? 'active' : ''} onClick={this.esPasivo.bind(this, false)}>Activo</Button>
            </ButtonGroup>
            <Button id="rolDelSoniante" color='' type="button"><strong>?</strong></Button>
            <UncontrolledPopover trigger="focus" placement="bottom" target="rolDelSoniante">
              <PopoverBody>El rol del soñante debe marcarse en función de la participación que tiene este en el sueño. Si es protagonista, seleccionar 'Activo'. Si lo observa desde afuera, seleccionar 'Pasivo'.</PopoverBody>
            </UncontrolledPopover>
          </FormGroup>
        </Form>
        <hr/>
        <div>
          <h1 className="display-4"><Badge color="primary" pill> </Badge> Símbolos</h1>
          <Row>
            <Col xs="6" >
              <Input name="simbolos" size="lg" placeholder="Buscar en la lista" onChange={(e) => {
                        let busquedaSimbolo = e.target.value;
                        this.busquedaSimbolos(busquedaSimbolo);
                      }}/>
              <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
                {simbolos}
              </ListGroup>
            </Col>
            <Col xs="6">
              <div className='justify-content-center d-flex'>
                <Button className='mb-4' size='sm' variant="contained" color="primary" onClick={this.toggleSugerirSimbolos.bind(this)}>Sugerencia de símbolos</Button>
                {sugerirSimbolos}
              </div>
              <p>Si el símbolo que buscas no se encuentra en la lista, puedes escribirlo aquí:</p>
              <Form inline className='justify-content-center d-flex'>
                <Input name="simbolos" placeholder="Escribir aquí..." className='' onChange={(e) => {
                            let nuevoSimbolo = e.target.value;
                            this.setState({nuevoSimbolo});
                          }}/>
                <Button outline color="success" className="ml-sm-2" onClick={() => {
                            let { suenioData, nuevoSimbolo } = this.state;
                            if (nuevoSimbolo !== '' && !suenioData.simbolos.some(({nombre}) => nombre.toLowerCase() === nuevoSimbolo.toLowerCase())) {
                              suenioData.simbolos = [...this.state.suenioData.simbolos, {nombre: nuevoSimbolo.toLowerCase()}];
                              this.setState({suenioData});
                            }
                }}>Agregar</Button>
              </Form>
            </Col>
          </Row>
          <Form inline >{simbolosSel}</Form>
        </div>
        <hr/>
        <div>
          <h1 className="display-4"><Badge color="warning" pill> </Badge> Sentimientos y emociones</h1>
          <Row>
            <Col xs="6" >
              <Input name="sentimientos" size="lg" placeholder="Buscar en la lista" onChange={(e) => {
                      let busquedaSentimiento = e.target.value;
                      this.busquedaSentimientos(busquedaSentimiento);
                    }}/>
              <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
                {sentimientos}
              </ListGroup>
            </Col>
            <Col xs="6">
              <p>Si el sentimiento o emoción que buscas no se encuentra en la lista, puedes escribirlo aquí:</p>
              <Form inline className='justify-content-center d-flex'>
                <Input name="sentimientos" placeholder="Escribir aquí..." className='' onChange={(e) => {
                            let nuevoSentimiento = e.target.value;
                            this.setState({nuevoSentimiento});
                          }}/>
                <Button outline color="success" className="ml-sm-2" onClick={() => {
                            let { suenioData, nuevoSentimiento } = this.state;
                            if (nuevoSentimiento !== '' && !suenioData.sentimientos.some(({nombre}) => nombre.toLowerCase() === nuevoSentimiento.toLowerCase())) {
                              suenioData.sentimientos = [...this.state.suenioData.sentimientos, {nombre: nuevoSentimiento.toLowerCase()}];
                              this.setState({suenioData});
                            }
                }}>Agregar</Button>
              </Form>
            </Col>
          </Row>
          <Form inline>{sentimientosSel}</Form>
        </div>
        <hr/>
        <div>
          <h1 className="display-4"><Badge color="info" pill> </Badge> Contextos del soñante</h1>
          <Row>
            <Col xs="6">
              <Input name="contextos" size="lg" placeholder="Buscar en la lista" onChange={(e) => {
                      let busquedaContexto = e.target.value;
                      this.busquedaContextos(busquedaContexto);
                    }}/>
              <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
                {contextos}
              </ListGroup>
            </Col>
            <Col xs="6">
              <p>Si el contexto que buscas no se encuentra en la lista, puedes escribirlo aquí:</p>
              <Form inline className='justify-content-center d-flex'>
                <Input name="contextos" placeholder="Escribir aquí..." className='' onChange={(e) => {
                            let nuevoContexto = e.target.value;
                            this.setState({nuevoContexto});
                          }}/>
                <Button outline color="success" className="ml-sm-2" onClick={() => {
                            let { suenioData, nuevoContexto } = this.state;
                            if (nuevoContexto !== '' && !suenioData.contextos.some(({nombre}) => nombre.toLowerCase() === nuevoContexto.toLowerCase())) {
                              suenioData.contextos = [...this.state.suenioData.contextos, {nombre: nuevoContexto.toLowerCase()}];
                              this.setState({suenioData});
                            }
                }}>Agregar</Button>
              </Form>
            </Col>
          </Row>
          <Form inline>{contextosSel}</Form>
        </div>
        <hr/>
        {!this.props.match.params.idSuenio &&
        <div className='derecha'>
          <ButtonUI
            type='link'
            color='primary'
            style={{outline: 'none'}}
            endIcon={this.state.opcionesAvanzadas? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => {
              this.setState({opcionesAvanzadas: !this.state.opcionesAvanzadas});
            }}
          >
            Opciones avanzadas
          </ButtonUI>
        </div>}
        {this.state.opcionesAvanzadas &&
        <>
          <FormGroup check>
            <Input id="notificar" type="checkbox" checked={this.state.suenioData.notificar}
            onChange={(e) =>{
              let { suenioData } = this.state;
              suenioData.notificar = e.target.checked;
              this.setState({ suenioData });
            }}/>
            <Label for="notificar">Notificar al {sessionStorage.rol === 'Soñante' ? 'terapeuta' : 'paciente'} al crear el sueño</Label>
          </FormGroup>
          <hr/>
        </>}
        <div className='centrar2'>
          <Snackbar open={this.state.errorMsg} autoHideDuration={12000} onClose={this.toggleErrorMsg.bind(this)}>
            <MuiAlert severity="error" onClose={this.toggleErrorMsg.bind(this)} elevation={6} variant="filled" >
              {this.state.error}
            </MuiAlert>
          </Snackbar>
          <h1 className="display-4 margenAbajo">¿Deseas guardar el sueño?</h1>
          <Row className='mb-4'>
            <Button color="success" size='lg' className="mr-2" onClick={this.guardarSuenio.bind(this)}>Guardar</Button>
            <Button outline color="danger" size='lg' className="mr-sm-2" onClick={this.redirectListarSuenios.bind(this)}>Cancelar</Button>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(IngresarSuenio);