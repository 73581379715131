import React from 'react';
import '../Recursos.css';
import { Spinner, } from 'reactstrap';
import {Button as ButtonUI, Card, CardContent, } from '@material-ui/core';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import Alert from '@material-ui/lab/Alert';
import {withRouter} from "react-router-dom";
import Cuestionario from './Cuestionario';
import { MAX_TOKENS } from '../utils/Constants';

class Preguntas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preguntas: [],
      sin_responder: 0,
      total: 0,
      cargado: false,
      generando: false,
      respuesta: '',
      me_gusta: null,
      error: null,
      estado: 0, //0: no hay preguntas generadas o no hay respuestas, 1: cuestionario en curso, 2: mostrar resultados
    };
  }

  componentDidMount(){this.obtenerPreguntas()}

  obtenerPreguntas() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/preguntas/?generar=false`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/preguntas/?generar=false`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      let { preguntas, sin_responder, total } = Response.data;
      let estado = 0;
      if (sin_responder < total) estado = 2;
      this.setState({
        preguntas: preguntas.sort((a, b) => a.id - b.id),
        sin_responder: sin_responder,
        total: total,
        estado: estado,
        cargado: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  generarPreguntas() {
    if (this.state.total > 0) {
      this.setState({estado: 1});
      return;
    }
    this.setState({generando: true});
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/preguntas/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/preguntas/`;
    this.props.authAxios.get(endpoint).then((Response => {
      let { preguntas, sin_responder, total } = Response.data;
      this.setState({
        preguntas: preguntas.sort((a, b) => a.id - b.id),
        preguntaSeleccionada: preguntas[0].id,
        sin_responder: sin_responder,
        total: total,
        estado: 1,
        cargado: true,
        generando: false
      });
    }))
    .catch((error => {
      if (error.response.status === 400) {
        let msj = "Ups! Algo salió mal."
        if ('error_largo' in error.response.data) {
          msj = `El sueño es muy largo (supera los ${MAX_TOKENS} caracteres) y no puede ser procesado. Puedes dividirlo en partes más cortas o resumir algunas partes.`;
        } else if ('error_parsing' in error.response.data) {
          msj = error.response.data.error_parsing;
        }
        this.setState({error: msj, generando: false});
      } else {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      }
    }))
  }

  render() {

    let estadoSinRespuestas = this.state.generando ?
      (<div className="centrar2">
        <Spinner />
        <p>Por favor mantener la ventana abierta, el procesamiento puede llevar unos segundos...</p>
      </div>) : this.state.error ?
      (<Alert className='mb-3' severity="error">{this.state.error}</Alert>) :
      (<div className="centrar2 mb-3">
        <ButtonUI variant="contained" color="primary" style={{ backgroundColor: '#01F7E2', color: 'black' }} size='large' startIcon={<QuestionAnswerOutlinedIcon />} onClick={() => this.generarPreguntas()}>
          Responder preguntas
        </ButtonUI>
      </div>);

    let estadoCuestionario = (
      <Cuestionario autofocus preguntas={this.state.preguntas} authAxios={this.props.authAxios} suenio={this.props.suenio} terminarCuestionario={() => this.obtenerPreguntas()} />
    );

    let estadoResultados = (
      <div>
        {this.state.sin_responder > 0 &&
          <div className='d-flex justify-content-between'>
            <i className='mx-2'>¡Aún queda{this.state.sin_responder > 1 && 'n'} {this.state.sin_responder} pregunta{this.state.sin_responder > 1 && 's'} por responder!</i>
            <ButtonUI variant="contained" color="primary" style={{ backgroundColor: '#01F7E2', color: 'black', fontSize: window.screen.width >= 800 ? null : 12 }} size='large' startIcon={<QuestionAnswerOutlinedIcon />} onClick={() => this.setState({estado: 1})}>
              {this.state.sin_responder === 0 ? 'Modificar respuestas' : 'Seguir respondiendo'}
            </ButtonUI>
          </div>
        }
        {this.state.preguntas.filter(p => p.respuesta !== null).map((pregunta, index) => {
          return (
            <Card className="bg-light my-3 shadow" key={index}>
              <CardContent className="d-flex flex-column">
                <p className="text-justify lead mb-2" style={{fontSize: window.screen.width >= 800 ? '20px' : '16px'}}>{pregunta.pregunta}</p>
                <p className="text-justify mb-0" style={{fontSize: '14px'}}>{pregunta.respuesta}</p>
              </CardContent>
            </Card>
          )
        })}
        {this.state.sin_responder === 0 &&
          <div className="derecha">
            <ButtonUI variant="contained" color="primary" style={{ backgroundColor: '#01F7E2', color: 'black' }} size='large' startIcon={<QuestionAnswerOutlinedIcon />} onClick={() => this.setState({estado: 1})}>
              Modificar respuestas
            </ButtonUI>
          </div>
        }
      </div>
    );

    if (!this.state.cargado) { 
      return <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    }

    return (
      <div>
        <Card className="my-4 d-flex align-items-stretch">
          <CardContent>
          <p className="text-justify mb-0" style={{fontSize: '14px'}}>Las preguntas son generadas mediante inteligencia artificial. Se utiliza el modelo GPT-3 provisto por <a href="https://openai.com/" target="_blank" rel="noopener noreferrer">OpenAI</a>. Los sueños son procesados automáticamente sin intervención de ninguna persona.</p>
          </CardContent>
        </Card>
        {this.state.estado === 0 && estadoSinRespuestas}
        {this.state.estado === 1 && estadoCuestionario}
        {this.state.estado === 2 && estadoResultados}
      </div>
    );
  }
}

export default withRouter(Preguntas);