import React from 'react';
import './Recursos.css';
import { Nav, NavItem, NavLink, Spinner, Form, Button } from 'reactstrap';
import { withRouter } from "react-router-dom";
import SueniosInforme from './informe/SueniosInforme';
import ResumenInforme from './informe/ResumenInforme';
import VisualizadorInforme from './informe/VisualizadorInforme';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RobotIcon } from "@material-ui-extra/icons/RobotIcon";

class VerInforme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      informe: {
        id: "",
        descripcion: "",
        fecha_creacion: "2020-01-01",
        suenios: []
      },
      resumenInformeActive: true,
      sueniosInformeActive: false,
      visualizadorInformeActive: false,
      permisos: true,
      cargado: false,
      permisoGPT3: false,
    }

    //Determinar si el usuario tiene permiso para usar GPT3
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      this.setState({permisoGPT3: Response.data.rol.includes('PermisoGPT3')})
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  obtenerInforme() {
    //Determinar rol (soñante o analista)
    let endpoint = `/analisis_por_filtro_analista/${this.props.match.params.id}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/analisis_por_filtro_soniante/${this.props.match.params.id}/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        informe: Response.data,
        cargado: true
      });
    })
    .catch((error) => {
      this.setState({permisos: false})
    });
  }

  componentDidMount(){this.obtenerInforme()}

  toggleResumenInformes() {
    this.setState({resumenInformeActive: true, sueniosInformeActive: false, visualizadorInformeActive: false})
  }

  toggleSueniosInformes() {
    this.setState({resumenInformeActive: false, sueniosInformeActive: true, visualizadorInformeActive: false})
  }

  toggleVisualizadorInformes() {
    this.setState({resumenInformeActive: false, sueniosInformeActive: false, visualizadorInformeActive: true})
  }

  volverAtras() {
    //Determinar rol (soñante o analista)
    let dir =`/home/informes/`;
    this.props.history.push(dir);
  }

  crearChat() {
    const path = sessionStorage.rol === 'Soñante' ? '/quiron/chats/' : `/quiron/pacientes/${this.props.match.params.id}/`;
    this.setState({cargado: false})
    this.props.authAxios.post(path, {analisis: this.state.informe.id}).then((Response => {
      if (sessionStorage.rol === 'Soñante')
        this.props.history.push(`/home/quiron/${Response.data.id}/`);
      else 
        this.props.history.push(`/home/pacientes/${this.props.match.params.id}/quiron/${Response.data.id}/`);
    }))
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  render() {
    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>)
    
    //Mostrar spinner mientras está cargando
    if (!this.state.cargado)
    return(<Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>)

    //Cargar componente en función de la pestaña activa
    let componente = null;
    if (this.state.resumenInformeActive) {
      componente = (<ResumenInforme informe={this.state.informe}/>)
    } else if (this.state.sueniosInformeActive) {
      componente = (<SueniosInforme authAxios={this.props.authAxios} informe={this.state.informe}/>)
    } else if (this.state.visualizadorInformeActive) {
      componente = (<VisualizadorInforme authAxios={this.props.authAxios} informe={this.state.informe}/>)
    }

    if (this.state.informe.id === "") return (<div></div>)
    return (
      <div>
        <Form inline>
          <IconButton onClick={this.volverAtras.bind(this)} aria-label="volver" size="medium" className='mt-2 mt-md-0'>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <div className="ml-2 mr-2">
            <h4 className="display-4 mb-0 mt-0 mt-md-3">{this.state.informe.descripcion? this.state.informe.descripcion : 'Informe'}</h4>
            <h1 className="lead">
            {new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(this.state.informe.fecha_creacion))}
            </h1>
          </div>
          {this.state.permisoGPT3 && (
          <>
            <hr/>
            <div className="mb-2">
              <Button outline color="primary" size="sm" className="mr-2" onClick={this.crearChat.bind(this)} >
                <RobotIcon/><p className='letraChica'>Quirón</p>
              </Button>
            </div>
          </>
          )}
        </Form>
        <Nav tabs fill>
          <NavItem>
            <NavLink active={this.state.resumenInformeActive} onClick={this.toggleResumenInformes.bind(this)}>Resumen</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.visualizadorInformeActive} onClick={this.toggleVisualizadorInformes.bind(this)}>Gráficas</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.sueniosInformeActive} onClick={this.toggleSueniosInformes.bind(this)} >Sueños</NavLink>
          </NavItem>
        </Nav>
        {componente}
      </div>
    );
  }
}

export default withRouter(VerInforme);