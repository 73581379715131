import React from 'react';
import '../Recursos.css';
import { Toast, ToastHeader, ToastBody, Spinner, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {withRouter} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import DeleteIcon from '@material-ui/icons/Delete';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import FlareIcon from '@material-ui/icons/Flare';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import {Button as ButtonUI, Card, CardContent, Accordion, AccordionSummary} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MAX_TOKENS } from '../utils/Constants';
import { fetch_stream } from '../utils/Streaming';

class IAInterpretaciones extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      interpretaciones: [],
      cargado: false,
      interpretacionSeleccionada: null,
      borrarInterpretacionModal: false,
      reportarProblemaModal: false,
      reclamo: '',
      datosPrevios: {largo: ''},
      generandoInterpretacion: false,
      generarInterpretacionModal: false,
      inputSuenio: this.props.suenio.descripcion,
      hayArquetipos: false,
      esInterpretacion: true,
      refInter: null,
    }
  }

  componentDidMount(){
    this.obtenerDatosPrevios()
    this.obtenerInterpretaciones()
  }

  obtenerDatosPrevios() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/datos_gpt3/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/datos_gpt3/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        datosPrevios: Response.data,
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  obtenerInterpretaciones() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/gpt3/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      // Recorrer las interpretaciones, y si una es de tipo "Arquetipos", hacer un get a la url de la interpretación
      let interpretaciones = Response.data;
      interpretaciones.forEach(interpretacion => {
        if(interpretacion.tipo === 'Arquetipos') {
          this.setState({hayArquetipos: true});
          this.props.authAxios.get(endpoint + interpretacion.pk + '/justificacion/')
          .then((Response) => {
            if (Response.data.length > 0)
              interpretacion.justificacion = Response.data[0].texto.trim();
            interpretacion.tieneJustificacion = Response.data.length > 0;
            this.setState({cargado: true});
          })
          .catch((error) => {
            //No debería darse nunca, dirigirse a InternalServerError
            this.props.history.push('/ErrorInterno')
          });
        }
      });
      this.setState({
        interpretaciones: interpretaciones,
        generandoInterpretacion: false,
        cargado: true,
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  borrarInterpretacion() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.idSuenio}/gpt3/${this.state.interpretacionSeleccionada}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/${this.state.interpretacionSeleccionada}/`;
    this.props.authAxios.delete(endpoint).then((Response => {
      let { interpretaciones } = this.state;
      interpretaciones = this.state.interpretaciones.filter(c => c.pk !== this.state.interpretacionSeleccionada);
      this.setState({interpretaciones, borrarInterpretacionModal: false});
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  }

  toggleBorrarInterpretacionModal() {
    this.setState({
      borrarInterpretacionModal: !this.state.borrarInterpretacionModal
    });
  }

  cambiarMeGusta(id, val) {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/gpt3/${id}/evaluar/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/${id}/evaluar/`;
    //Hacer post
    this.props.authAxios.put(endpoint, {'me_gusta':val})
    .then((Response) => {
      this.obtenerInterpretaciones()
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  toggleReportarProblemaModal() {
    this.setState({
      reportarProblemaModal: !this.state.reportarProblemaModal
    });
  }

  reportarProblema() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.idSuenio}/gpt3/${this.state.interpretacionSeleccionada}/reclamos/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/${this.state.interpretacionSeleccionada}/reclamos/`;
    this.props.authAxios.post(endpoint, {'reclamo':this.state.reclamo}).then((Response => {
      this.setState({reportarProblemaModal: false});
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  }

  generar() {
    this.scrollToInter();
    if (this.state.esInterpretacion) {
      this.generarInterpretacion();
    } else {
      this.generarArquetipos();
    }
  }

  generarArquetipos() {
    this.setState({generandoInterpretacion: true, generarInterpretacionModal: false});
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/gpt3/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/`;
    //Hacer post
    let dict = {'input_suenio':this.state.inputSuenio, 'tipo':'Arquetipos'};
    this.props.authAxios.post(endpoint, dict)
    .then((Response) => {
      this.obtenerInterpretaciones()
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  generarInterpretacion() {
    this.setState({generandoInterpretacion: true, generarInterpretacionModal: false});
    let interpretaciones = this.state.interpretaciones;
    interpretaciones.unshift({fecha_creacion: new Date(), tipo: 'Streaming', texto: '', pk: -1});
    this.setState({interpretaciones});
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/gpt3/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/`;
    fetch_stream(endpoint, {'input_suenio':this.state.inputSuenio, 'tipo': 'Interpretacion'}, (mensaje) => {
      interpretaciones[0].texto += mensaje;
      this.setState({interpretaciones: interpretaciones});
    }, () => {
      this.obtenerInterpretaciones();
    }, (error) => {
      console.log(error);
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  toggleGenerarInterpretacionModal() {
    this.setState({
      generarInterpretacionModal: !this.state.generarInterpretacionModal,
      inputSuenio: this.props.suenio.descripcion,
    });
  }

  // Dado us string de tipo lista "[arquetipo1, arquetipo2, ...]" devuelve un string de la forma:
  // "- Arquetipo1 
  //  - Arquetipo2 
  // ..."
  // Cada arquetipo debe comenzar en mayúsculas. Si el string no es una lista, devuelve el string original.
  procesarArquetipos(texto) {
    // No es una lista si no comienza por "[" y termina por "]".
    if(texto.charAt(0) !== "[" || texto.charAt(texto.length - 1) !== "]") return texto;
    let arquetipos = texto.substring(1, texto.length - 1).split(',');
    let resultado = '';
    for(let i = 0; i < arquetipos.length; i++) {
      resultado += '- ' + arquetipos[i].trim().charAt(0).toUpperCase() + arquetipos[i].trim().substring(1) + '\n';
    }
    return resultado;
  }

  generarJustificacion(id) {
    this.setState({generandoInterpretacion: true, esInterpretacion: false, });
    let interpretaciones = this.state.interpretaciones;
    let indice = -1;
    for(let i = 0; i < interpretaciones.length; i++) {
      if(interpretaciones[i].pk === id) {
        interpretaciones[i].justificacion = '';
        interpretaciones[i].tieneJustificacion = true;
        indice = i;
        break;
      }
    }
    this.setState({interpretaciones});
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/gpt3/${id}/justificacion/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/gpt3/${id}/justificacion/`;

    fetch_stream(endpoint, {}, (mensaje) => {
      interpretaciones[indice].justificacion += mensaje;
      this.setState({interpretaciones: interpretaciones});
    }, () => {
      this.obtenerInterpretaciones();
    }, (error) => {
      console.log(error);
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  reutilizarAnterior() {
    let interpretacion = this.state.interpretaciones.sort((a, b) => a.fecha_creacion > b.fecha_creacion ? 1 : -1)[0];
    this.setState({inputSuenio: interpretacion.input_suenio});
  }

  scrollToInter = () => {
    if (this.state.refInter === null) return;
    this.state.refInter.scrollIntoView({ behavior: "smooth" });
  }

  cambiarRefInter = (element) => {
    this.setState({refInter: element});
  }

  render() {

        //Cargar interpretaciones
        let interpretaciones = this.state.interpretaciones.map((interpretacion) => {
    
          //Fecha en formato lindo
          let fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit"}).format(new Date(interpretacion.fecha_creacion))
          
          //Cartel de advertencia si la interpretación es sensible
          let sensibilidadMsg = interpretacion.sensibilidad === 1? (<Alert  className='mb-3' severity="warning">
            Se ha detectado que parte del contenido de la interpretación podría herir la sensibilidad del que la recibe.
          </Alert>) : null

          let texto = interpretacion.texto.trim();
          if (interpretacion.tipo === "Arquetipos") {
            texto = this.procesarArquetipos(texto);
            if (interpretacion.tieneJustificacion) {
              texto += '\nJustificación:\n' + interpretacion.justificacion;
            }
          }

          let btnJustificar = null;
          if (interpretacion["tieneJustificacion"] !== undefined && !interpretacion.tieneJustificacion) {
            btnJustificar = (this.state.generandoInterpretacion && !this.state.esInterpretacion? <Spinner/> : <Button color="link" className="mb-2" onClick={this.generarJustificacion.bind(this, interpretacion.pk)}>Pedir justificación</Button>)
          }

          if (interpretacion.sensibilidad !== 2) {
            return (
            <Toast key={interpretacion.pk} style={{'maxWidth': '100%', 'backgroundColor': interpretacion.tipo === "Interpretacion" || interpretacion.tipo === "Streaming"? '#01F7E2' : '#ADF7F0'}}>
              <ToastHeader>
                  {interpretacion.tipo === "Interpretacion" || interpretacion.tipo === "Streaming"? "INTERPRETACIÓN - interpretador inteligente" : "ARQUETIPOS - interpretador inteligente"}
              </ToastHeader>
              <ToastBody>
                {sensibilidadMsg}
                <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{texto}</p>
                {btnJustificar}
                {interpretacion.tipo !== 'Streaming' &&
                <div className='derecha'>
                  <p className='letraChica mr-2 my-auto'>{fecha}</p>
                  <IconButton aria-label="ThumbUp" color={interpretacion.me_gusta == null ? 'default' : interpretacion.me_gusta ? 'secondary' : 'default'} size='small' onClick={() => {this.cambiarMeGusta(interpretacion.pk, interpretacion.me_gusta ? null : true)}} className='btnSinBorde'>
                    <ThumbUpIcon fontSize="inherit"/>
                  </IconButton>
                  <IconButton className='mr-2 btnSinBorde' aria-label="ThumbDown" color={interpretacion.me_gusta === null ? 'default' : !interpretacion.me_gusta ? 'secondary' : 'default'} size='small' onClick={() => {this.cambiarMeGusta(interpretacion.pk, interpretacion.me_gusta === false ? null : false)}}>
                    <ThumbDownIcon fontSize="inherit"/>
                  </IconButton>
                  <IconButton className='mr-2 btnSinBorde' aria-label="report" color='default' size='small' onClick={() => 
                  {this.setState({interpretacionSeleccionada: interpretacion.pk}); this.toggleReportarProblemaModal(this)}
                  }>
                    <ReportProblemIcon fontSize="inherit"/>
                  </IconButton>
                  <IconButton color="danger" size='small' onClick={() => 
                  {this.setState({interpretacionSeleccionada: interpretacion.pk}); this.toggleBorrarInterpretacionModal(this)}
                  } className='btnSinBorde'>
                      <DeleteIcon fontSize="inherit"/>
                  </IconButton>
                </div>
                }
              </ToastBody>
            </Toast>)
          } else {
            return (
              <Accordion className="mt-4 mb-4">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Alert severity="error">
                    Se ha detectado que la interpretación puede resultar ofensiva, o puede herir la sensibilidad del que la recibe. Haga click si desea leerla igual.
                  </Alert>
                </AccordionSummary>
                <Toast key={interpretacion.pk} className='ml-2 mb-2 mr-2' style={{'maxWidth': '100%', 'backgroundColor': interpretacion.tipo === "Interpretacion"? '#01F7E2' : '#ADF7F0'}}>
                  <ToastHeader>
                    {interpretacion.tipo === "Interpretacion"? "INTERPRETACIÓN - interpretador inteligente" : "ARQUETIPOS - interpretador inteligente"}
                  </ToastHeader>
                  <ToastBody>
                    <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{texto}</p>
                    {btnJustificar}
                    <div className='derecha'>
                      <p className='letraChica mr-2 my-auto'>{fecha}</p>
                      <IconButton aria-label="ThumbUp" color={interpretacion.me_gusta == null ? 'default' : interpretacion.me_gusta ? 'secondary' : 'default'} size='small' onClick={() => {this.cambiarMeGusta(interpretacion.pk, interpretacion.me_gusta ? null : true)}} className='btnSinBorde'>
                        <ThumbUpIcon fontSize="inherit"/>
                      </IconButton>
                      <IconButton className='mr-2 btnSinBorde' aria-label="ThumbDown" color={interpretacion.me_gusta === null ? 'default' : !interpretacion.me_gusta ? 'secondary' : 'default'} size='small' onClick={() => {this.cambiarMeGusta(interpretacion.pk, interpretacion.me_gusta === false ? null : false)}}>
                        <ThumbDownIcon fontSize="inherit"/>
                      </IconButton>
                      <IconButton className='mr-2 btnSinBorde' aria-label="report" color='default' size='small' onClick={() => 
                      {this.setState({interpretacionSeleccionada: interpretacion.pk}); this.toggleReportarProblemaModal(this)}
                      }>
                        <ReportProblemIcon fontSize="inherit"/>
                      </IconButton>
                      <IconButton color="danger" size='small' onClick={() => 
                      {this.setState({interpretacionSeleccionada: interpretacion.pk}); this.toggleBorrarInterpretacionModal(this)}
                      } className='btnSinBorde'>
                          <DeleteIcon fontSize="inherit"/>
                      </IconButton>
                    </div>
                  </ToastBody>
                </Toast>
              </Accordion>
              )
          };
        });

    let botonGenerar = interpretaciones.length > 0 || !this.state.cargado || (this.state.generandoInterpretacion && !this.state.esInterpretacion)? null : this.state.generandoInterpretacion && this.state.esInterpretacion ?
    (<div className="centrar2">
      <Spinner />
      <p>Por favor mantener la ventana abierta, el procesamiento puede llevar unos segundos...</p>
    </div>) :
    (<div className="centrar2 mb-3">
      <ButtonUI variant="contained" color="primary" style={{ backgroundColor: '#01F7E2', color: 'black' }} size='large' startIcon={<FlareIcon />} onClick={() => {this.setState({esInterpretacion: true}); this.toggleGenerarInterpretacionModal(this)}}>
        Generar interpretación
      </ButtonUI>
    </div>)

    let botonGenerarOtro = interpretaciones.length === 0 || !this.state.cargado || (this.state.generandoInterpretacion && !this.state.esInterpretacion)? null : this.state.generandoInterpretacion && this.state.esInterpretacion ?
    (<div className="derecha">
      <ButtonUI variant="contained" color="default" disabled size='small' startIcon={<Spinner />}>
        Generando interpretación
      </ButtonUI>
    </div>) :
    (<div className="derecha">
      <Button color="link" size='sm' onClick={() => {this.setState({esInterpretacion: true}); this.toggleGenerarInterpretacionModal(this)}}>
        Generar otra interpretación
      </Button>
    </div>)

  let botonArquetipos = this.state.hayArquetipos || !this.state.cargado || (this.state.generandoInterpretacion && this.state.esInterpretacion) ? null : this.state.generandoInterpretacion && !this.state.esInterpretacion ? 
  (<div className="centrar2">
    <Spinner />
    <p>Por favor mantener la ventana abierta, el procesamiento puede llevar unos segundos...</p>
  </div>)  :
  (<div className="centrar2 mb-3">
    <ButtonUI variant="contained" color="primary" style={{ backgroundColor: '#01F7E2', color: 'black' }} size='large' startIcon={<ImportContactsIcon />} onClick={() => {this.setState({esInterpretacion: false}); this.toggleGenerarInterpretacionModal(this)}}>
      Identificar arquetipos
    </ButtonUI>
  </div>)

    let advertenciaLargo = this.state.datosPrevios.largo === 'Incorrecto'? (<Alert className='mb-3' severity="error">El sueño es demasiado largo para poder ser procesado. Se suguiere resumir partes del sueño o interpretar cada parte por separado.</Alert>) : this.state.datosPrevios.largo === 'Pasado' ? (<Alert  className='mb-3' severity="warning">El sueño es bastante largo, por lo que la interpretación puede quedar cortada. Se sugiere achicar el sueño o dividirlo en dos diferentes.</Alert>) : null

    let reutilizarAnterior = this.state.interpretaciones.length === 0 ? null : 
    (<div className='derecha mb-2'><Button outline onClick={this.reutilizarAnterior.bind(this)}>Reutilizar anterior</Button></div>  )

    //Ver si cargó lista de interpretaciones
    if (!this.state.cargado) return (<Spinner style={{ width: '3rem', height: '3rem' }}/>);

    return (
      <div>
        <Modal isOpen={this.state.generarInterpretacionModal} toggle={this.toggleGenerarInterpretacionModal.bind(this)}>
          <ModalHeader>
            ¿Desea cambiar algo del contenido del sueño antes de ser procesado?
            <p className='text-justify letraChica mt-2'>Estos cambios no modificarán el sueño, solo tendrán efecto en el interpretador inteligente.</p>
            <p className='text-justify letraChica mt-1'>Ejemplo: cambiar "Pedro estaba..." por "mi hermano estaba...".</p>
          </ModalHeader>
          <ModalBody>
            {advertenciaLargo}
            {reutilizarAnterior}
            <textarea
              className="form-control"
              rows="7"
              onChange={event => this.setState({inputSuenio: event.target.value})}
              value={this.state.inputSuenio}
            />
            <p style={{color:this.state.inputSuenio.length>MAX_TOKENS?'red':'black'}} className='derecha'>
              {this.state.inputSuenio.length}/{MAX_TOKENS}
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonUI className='mr-2' variant="contained" color="secondary" onClick={this.toggleGenerarInterpretacionModal.bind(this)}>
              Cancelar
            </ButtonUI>
            <ButtonUI variant="contained" color="default" style={this.state.inputSuenio.length > MAX_TOKENS || this.state.inputSuenio.length < 10? {} : { backgroundColor: '#01F7E2', color: 'black' }} disabled={this.state.inputSuenio.length > MAX_TOKENS || this.state.inputSuenio.length < 10} startIcon={<FlareIcon />} onClick={this.generar.bind(this)}>
              Generar
            </ButtonUI>
          </ModalFooter>
        </Modal>
        <div className="row mb-3">
          <div className="col-lg-6 d-flex align-items-stretch">
            <Card className="mb-2">
              <CardContent>
              <p className="text-justify mb-0" style={{fontSize: '14px'}}>Las interpretaciones son generadas mediante inteligencia artificial. Se utiliza el modelo GPT-3 provisto por <a href="https://openai.com/" target="_blank" rel="noopener noreferrer">OpenAI</a>. Los sueños son procesados automáticamente sin intervención de ninguna persona.</p>
              </CardContent>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card>
              <CardContent>
              <p className="text-justify mb-0" style={{fontSize: '14px'}}><strong>Advertencia:</strong> Se sugiere que las interpretaciones generadas sean entendidas de manera simbólica, y se utilicen como disparadoras para el trabajo personal o con el terapeuta. Tener en cuenta que el modelo puede cometer errores o asumir cosas incorrectas.</p>
              </CardContent>
            </Card>
          </div>
        </div>
        {botonGenerar}
        {botonArquetipos}
        <div style={{ float:"left", clear: "both" }}
          ref={this.cambiarRefInter}>
        </div>
        {interpretaciones}
        <Modal isOpen={this.state.borrarInterpretacionModal} toggle={this.toggleBorrarInterpretacionModal.bind(this)}>
          <ModalBody>
            ¿Estás seguro que deseas borrar la interpretacion? ¡No podrás recuperarla!
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.borrarInterpretacion.bind(this)}>Confirmar</Button>{' '}
            <Button color="secondary" onClick={this.toggleBorrarInterpretacionModal.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.reportarProblemaModal} toggle={this.toggleReportarProblemaModal.bind(this)}>
          <ModalBody>
            <p className='margenAbajo'>Por favor, redactar una breve explicación del problema encontrado:</p>
            <Input type="textarea" name="reclamo" id="reclamo" onChange={(e) => {
              this.setState({ reclamo: e.target.value });
            }}/>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.reportarProblema.bind(this)}>Confirmar</Button>{' '}
            <Button color="secondary" onClick={this.toggleReportarProblemaModal.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
        {botonGenerarOtro}
      </div>
    );
  }
}

export default withRouter(IAInterpretaciones);