import React from 'react';
import './Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Spinner, Alert } from 'reactstrap';
import { Link, withRouter} from "react-router-dom";

class Pacientes extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      authAxios: this.props.authAxios,
      pacientes: [],
      nuevoPacienteData: {
        nombre: '',
        sexo: 'no especifica',
        fecha_nacimiento: null,
        fecha_ingreso: null,
        ocupacion: '',
        notificar_mail: true,
      },
      pacienteSeleccionado: {
        id: '',
        nombre: '',
        sexo: '',
        fecha_nacimiento: '2020-01-01',
        fecha_ingreso: '2020-01-01',
        ocupacion: '',
        soniante: null,
        notificar_mail: true,
      },
      crearPacienteModal: false,
      verPacienteModal: false,
      borrarPacienteModal: false,
      agregarSonianteModal: false,
      usuarioSoniante: '',
      sonianteIncorrectoMsg: null,
      error: null,
      errorMsg: false,
      //Con esta variable booleana se establece si se hace post o put
      editandoPaciente: false,
      cargado: false,
    }
  }

  actualizarPacientes() {
    //Hacer get
    this.state.authAxios.get('/pacientes/')
    .then((Response) => {
      this.setState({
        pacientes: Response.data,
        cargado: true,
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  componentDidMount(){this.actualizarPacientes()}

  toggleCrearPacienteModal() {
    this.setState({
      crearPacienteModal: !this.state.crearPacienteModal
    }, () => { if (!this.state.crearPacienteModal) this.cerrarCrearPaciente();})   
  }

  crearPaciente() {
    this.state.authAxios.post('/pacientes/', this.state.nuevoPacienteData).then((Response => {
      this.setState({crearPacienteModal: false});
      this.actualizarPacientes();
    }))
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  capturarError(response) {
    if (response.data.nombre) this.setState({error: "El nombre del paciente no puede quedar en blanco."});
    else this.setState({error: "Ups! Algo salió mal. Por favor, revisar los datos ingresados."});
    this.setState({errorMsg: true})
  }

  cargarPacienteSeleccionado(paciente) {
    let { pacienteSeleccionado } = this.state;
    pacienteSeleccionado.id = paciente.id;
    pacienteSeleccionado.nombre = paciente.nombre;
    pacienteSeleccionado.sexo = paciente.sexo;
    pacienteSeleccionado.fecha_nacimiento = paciente.fecha_nacimiento;
    pacienteSeleccionado.fecha_ingreso = paciente.fecha_ingreso;
    pacienteSeleccionado.ocupacion = paciente.ocupacion;
    pacienteSeleccionado.soniante = paciente.soniante;
    pacienteSeleccionado.notificar_mail = paciente.notificar_mail;
    this.setState({
      pacienteSeleccionado: pacienteSeleccionado,
      verPacienteModal: true
    });
  }

  toggleVerPacienteModal() {
    this.setState({
      verPacienteModal: !this.state.verPacienteModal
    });
  }

  //Para editar un paciente, se utiliza el modal de crear paciente y el estado nuevoPacienteData
  toggleEditarPaciente() {
    this.setState({
      nuevoPacienteData: this.state.pacienteSeleccionado,
      verPacienteModal: false,
      crearPacienteModal: true,
      editandoPaciente: true
    })
  }

  editarPaciente() {
    this.state.authAxios.put(`/pacientes/${this.state.pacienteSeleccionado.id}/`, this.state.nuevoPacienteData).then((Response => {
      this.cerrarCrearPaciente();
      this.actualizarPacientes();
    }))
    .catch((error => {
      this.capturarError(error.response)
    }))
  }

  editarNotificarMail(checked) {
    let { pacienteSeleccionado } = this.state;
    pacienteSeleccionado.notificar_mail = checked;
    this.state.authAxios.put(`/pacientes/${this.state.pacienteSeleccionado.id}/`, pacienteSeleccionado).then((Response => {
      this.setState({pacienteSeleccionado: pacienteSeleccionado});
      this.actualizarPacientes();
    }))
    .catch((error => {
      this.capturarError(error.response)
    }))
  }

  toggleBorrarPacienteModal() {
    this.setState({
      borrarPacienteModal: !this.state.borrarPacienteModal
    });
  }
  
  toggleAgregarSonianteModal() {
    this.setState({
      agregarSonianteModal: !this.state.agregarSonianteModal,
      sonianteIncorrectoMsg: null
    })
  }

  borrarPaciente() {
    this.state.authAxios.delete(`/pacientes/${this.state.pacienteSeleccionado.id}/`);
    let {pacientes} = this.state;
    pacientes = this.state.pacientes.filter(paciente => paciente.id !== this.state.pacienteSeleccionado.id);
    this.setState({pacientes});
    this.toggleBorrarPacienteModal();
    this.toggleVerPacienteModal();
  }

  agregarSoniante() {
    this.state.authAxios.put(`/pacientes/${this.state.pacienteSeleccionado.id}/agregar_soniante/`, {usuario: this.state.usuarioSoniante}).then((Response => {
      this.toggleAgregarSonianteModal();
      let { pacienteSeleccionado } = this.state;
      pacienteSeleccionado.soniante = this.state.usuarioSoniante;
      this.setState({pacienteSeleccionado})
    }))
    .catch((error => {
      let msg = "No se encontró un usuario con ese nombre de usuario";
      if (error.response.status === 400) msg = error.response.data.usuario
      this.setState({sonianteIncorrectoMsg: msg})
    }))
  }

  toggleErrorMsg() {
    this.setState({errorMsg: !this.state.errorMsg})
  }

  cerrarCrearPaciente() {
    this.setState({
      crearPacienteModal: false,
      editandoPaciente: false,
      nuevoPacienteData: {
        nombre: '',
        sexo: 'no especifica',
        fecha_nacimiento: null,
        fecha_ingreso: null,
        ocupacion: ''
      },
      errorMsg: false,
    });
  }

  render() {
    // Cargar pacientes
    let pacientes = this.state.pacientes.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((paciente) => {
      return (
        <tr key={paciente.id}>
          <td><Link to={`/home/pacientes/${paciente.id}/suenios`} style={{ color: '#000' }}>{paciente.nombre}</Link></td>
          <td><Button outline color="info" onClick={this.cargarPacienteSeleccionado.bind(this, paciente)}>i</Button></td>
          {/* <td><Button outline color="primary">Contextos</Button></td> */}
        </tr>
      )
    });

    //Agregar soñante o mostrar usuario del soñante de un paciente
    let soniante = <Button color="link"  size="sm" className="active mr-auto" onClick={this.toggleAgregarSonianteModal.bind(this)  
    }>Agregar usuario al paciente</Button>;
    if (this.state.pacienteSeleccionado.soniante)
      soniante = <p size="sm" className="mr-auto">usuario: {this.state.pacienteSeleccionado.soniante}</p>

    //Ver si la lista de pacientes está vacía
    let noHayPacientes = <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    if (pacientes.length === 0 && this.state.cargado) noHayPacientes = (<p>Aún no se ha registrado ningún paciente.</p>);
    else if (this.state.cargado) noHayPacientes = null;

    return (
      <div className="container-sm">
        <h1 className="display-4">Pacientes</h1>
        <h2 className="lead">Administrar sueños de...</h2>
        {noHayPacientes}
        <div className="scrollbarListas" id='bordeScrollbar'>
          <Table>
            <tbody>
              {pacientes}
            </tbody>
          </Table>
        </div>
        <div className="leftbutton">
          <Button outline color="success" size="lg" className="margenAbajo" onClick={this.toggleCrearPacienteModal.bind(this)}>+</Button>
          <Modal isOpen={this.state.crearPacienteModal} toggle={this.toggleCrearPacienteModal.bind(this)}>
            <ModalHeader toggle={this.toggleCrearPacienteModal.bind(this)}>{this.state.editandoPaciente ? "Editar paciente" : "Agregar paciente"}</ModalHeader>
            <ModalBody>
             <Form>
                <FormGroup>
                  <Label for="nombre">Nombre</Label>
                  <Input id="nombre" placeholder="Introduzca nombre y apellido" value={this.state.nuevoPacienteData.nombre} onChange={(e) =>{
                    let { nuevoPacienteData } = this.state;
                    nuevoPacienteData.nombre = e.target.value;
                    this.setState({ nuevoPacienteData });
                  }}/>
                </FormGroup>
                <FormGroup>
                  <Label for="sexo">Género</Label>
                  <Input type="select" name="select" id="sexo" value={this.state.nuevoPacienteData.sexo} onChange={(e) =>{
                    let { nuevoPacienteData } = this.state;
                    nuevoPacienteData.sexo = e.target.value;
                    this.setState({ nuevoPacienteData });
                  }}>
                    <option>femenino</option>
                    <option>masculino</option>
                    <option>no binario</option>
                    <option>transgénero</option>
                    <option>otro</option>
                    <option>no especifica</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="fechaNacimiento">Fecha de nacimiento (opcional)</Label>
                  <Input value={this.state.nuevoPacienteData.fecha_nacimiento ? this.state.nuevoPacienteData.fecha_nacimiento : ""}
                    type="date"
                    name="date"
                    id="fechaNacimiento"
                    onChange={(e) =>{
                      let { nuevoPacienteData } = this.state;
                      nuevoPacienteData.fecha_nacimiento = e.target.value;
                      if (nuevoPacienteData.fecha_nacimiento === "") nuevoPacienteData.fecha_nacimiento = null;
                      this.setState({ nuevoPacienteData });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="fechaIngreso">Fecha de inicio de terapia (opcional)</Label>
                  <Input value={this.state.nuevoPacienteData.fecha_ingreso ? this.state.nuevoPacienteData.fecha_ingreso : ""}
                    type="date"
                    name="date"
                    id="fechaIngreso"
                    onChange={(e) =>{
                      let { nuevoPacienteData } = this.state;
                      nuevoPacienteData.fecha_ingreso = e.target.value;
                      if (nuevoPacienteData.fecha_ingreso === "") nuevoPacienteData.fecha_ingreso = null;
                      this.setState({ nuevoPacienteData });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ocupacion">Ocupación (opcional)</Label>
                  <Input id="ocupacion" placeholder="Introduzca ocupación del paciente" value={this.state.nuevoPacienteData.ocupacion}
                  onChange={(e) =>{
                    let { nuevoPacienteData } = this.state;
                    nuevoPacienteData.ocupacion = e.target.value;
                    this.setState({ nuevoPacienteData });
                  }}/>
                </FormGroup>
                <FormGroup check>
                  <Input id="notificar_mail" type="checkbox" checked={this.state.nuevoPacienteData.notificar_mail}
                  onChange={(e) =>{
                    let { nuevoPacienteData } = this.state;
                    nuevoPacienteData.notificar_mail = e.target.checked;
                    this.setState({ nuevoPacienteData });
                  }}/>
                  <Label for="notificar_mail">Notificarme los sueños nuevos por mail</Label>
                </FormGroup>
              </Form>
              <Alert color="danger" isOpen={this.state.errorMsg} toggle={this.toggleErrorMsg.bind(this)}>
                {this.state.error}
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" 
              onClick={this.state.editandoPaciente ? this.editarPaciente.bind(this) : this.crearPaciente.bind(this)}>
                {this.state.editandoPaciente ? "Confirmar" : "Agregar"}
              </Button>{' '}
              <Button color="secondary" onClick={this.cerrarCrearPaciente.bind(this)}>Cancelar</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.verPacienteModal} toggle={this.toggleVerPacienteModal.bind(this)}>
            <ModalHeader toggle={this.toggleVerPacienteModal.bind(this)}>{this.state.pacienteSeleccionado.nombre}</ModalHeader>
            <ModalBody>
              <h6>Género</h6>
              <p>{this.state.pacienteSeleccionado.sexo}</p>
              <h6>Fecha de nacimiento</h6>
              <p>
                {this.state.pacienteSeleccionado.fecha_nacimiento ? 
                isNaN(new Date(this.state.pacienteSeleccionado.fecha_nacimiento + 'T00:00:00')) ? "" :
                new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"})
                .format(new Date(this.state.pacienteSeleccionado.fecha_nacimiento + 'T00:00:00'))
                : "sin especificar"}
              </p>
              <h6>Fecha de inicio de terapia</h6>
              <p>
                {this.state.pacienteSeleccionado.fecha_ingreso ? 
                isNaN(new Date(this.state.pacienteSeleccionado.fecha_ingreso + 'T00:00:00')) ? "" :
                new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"})
                .format(new Date(this.state.pacienteSeleccionado.fecha_ingreso + 'T00:00:00'))
                : "sin especificar"}
              </p>
              <h6>Ocupación</h6>
              <p>{this.state.pacienteSeleccionado.ocupacion !== "" ? this.state.pacienteSeleccionado.ocupacion : "sin especificar"}</p>
              <FormGroup check>
                <Input id="notificar_mail_insitu" type="checkbox" checked={this.state.pacienteSeleccionado.notificar_mail}
                  onChange={(e) =>{ this.editarNotificarMail(e.target.checked)
                }}/>
                <Label for="notificar_mail_insitu"><h6>Notificarme los sueños nuevos por mail</h6></Label>
              </FormGroup>
              <Modal isOpen={this.state.borrarPacienteModal} toggle={this.toggleBorrarPacienteModal.bind(this)}>
                <ModalBody>
                  El paciente se borrará permanentemente, junto con todos sus sueños. ¿Estás seguro que deseas continuar?
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.borrarPaciente.bind(this)}>Confirmar</Button>{' '}
                  <Button color="secondary" onClick={this.toggleBorrarPacienteModal.bind(this)}>Cancelar</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.agregarSonianteModal} toggle={this.toggleAgregarSonianteModal.bind(this)}>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label for="usuarioSoniante">Nombre de usuario</Label>
                      <Input invalid={this.state.sonianteIncorrectoMsg} name="usuarioSoniante" onChange={(e) =>{
                          let { usuarioSoniante } = this.state;
                          usuarioSoniante = e.target.value;
                          this.setState({ usuarioSoniante });
                        }}/>
                      <FormFeedback>{this.state.sonianteIncorrectoMsg}</FormFeedback>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.agregarSoniante.bind(this)}>Confirmar</Button>{' '}
                  <Button color="secondary" onClick={this.toggleAgregarSonianteModal.bind(this)}>Cancelar</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              {soniante}
              <Button color="primary" onClick={this.toggleEditarPaciente.bind(this)}><strong>✎</strong><p className='letraChica'>Editar</p></Button>
              <Button color="danger" onClick={this.toggleBorrarPacienteModal.bind(this)}><strong>🗑</strong><p className='letraChica'>Borrar</p></Button>
              <Button color="secondary" onClick={this.toggleVerPacienteModal.bind(this)}><strong>X</strong><p className='letraChica'>Cerrar</p></Button>
            </ModalFooter>
          </Modal>
        </div> 
      </div>
    );
  }
}

export default withRouter(Pacientes);