import React from 'react';
import '../Recursos.css';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router-dom";
import ModalImage from "react-modal-image";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Galeria from '../Galeria';

class Imagenes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cargado: false,
      galeria: false,
      mensaje: '',
      error: null,
    }
  }

  marcarFavorito(img) {
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/imagen_favorito/${img}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/imagen_favorito/${img}/`;
    this.props.authAxios.post(endpoint, {})
    .then((Response) => {
      this.props.actualizarImagenes();
    }).catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    })
  }

  seleccionarImagen(img) {
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/agregar_imagen/${img}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/agregar_imagen/${img}/`;
    this.props.authAxios.post(endpoint, {})
    .then((Response) => {
      this.setState({
        galeria: false
      }); 
      this.props.actualizarImagenes();
    }).catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    })
  }

  quitarImagen(img) {
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/quitar_imagen/${img}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/quitar_imagen/${img}/`;
    this.props.authAxios.post(endpoint, {})
    .then((Response) => {
      this.props.actualizarImagenes();
    }).catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    })
  }

  toggleGaleria() {
    this.setState({
      galeria: !this.state.galeria
    }, () => {
      if(!this.state.galeria) {
        this.props.actualizarImagenes();
      }
    });
  }
  
  render() {
    
    return (
      <div>
        {this.props.imagenes.length === 0 ? <p>Aún no se han asociado imágenes a este sueño.</p> : null}
        <ImageList rowHeight={'auto'} cols={window.screen.width < 800 ? 2 : 3}>
          {this.props.imagenes.sort((a, b) => a.fecha > b.fecha ? -1 : 1).map((img) => (
            <ImageListItem style={{cursor: 'pointer'}} key={img.id} cols={1} >
              <ModalImage small={img.imagen} large={img.imagen} showRotate={true} hideZoom={true} alt={<p className='letraChica'>{img.descripcion !== 'null'? img.descripcion : ''}</p>} />
              <ImageListItemBar
                style={{backgroundColor: 'transparent'}}
                actionIcon={
                  <IconButton aria-label={`star ${img.descripcion}`} onClick={this.marcarFavorito.bind(this, img.id)} className='btnSinBorde'>
                    <StarIcon style={{color:img.favorito? 'yellow' : 'inherit'}} />
                  </IconButton>
                }
              />
              <ImageListItemBar
                style={{backgroundColor: 'transparent'}}
                position="top"
                actionIcon={
                  <IconButton aria-label={`delete ${img.descripcion}`} className='btnSinBorde' onClick={this.quitarImagen.bind(this, img.id)}>
                    <DeleteIcon color='secondary' />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Modal isOpen={this.state.galeria} toggle={this.toggleGaleria.bind(this)}>
          <ModalHeader toggle={this.toggleGaleria.bind(this)}>Galería</ModalHeader>
          <ModalBody>
            <Galeria authAxios={this.props.authAxios} seleccionarImagen={this.seleccionarImagen.bind(this)} permisoGPT3={this.props.permisoGPT3} />
          </ModalBody>
        </Modal>
        <div className='derecha mt-2'>
          <Button outline color="success" size="lg" className="mb-2" onClick={this.toggleGaleria.bind(this)}>+</Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Imagenes);