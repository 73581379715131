import React from 'react';
import './Recursos.css';
import {} from 'reactstrap';
import {Link} from "react-router-dom";

class InternalServerError extends React.Component {

  render() {    
    return (
      <div className='centrar2'>
        <h1 className='display-4'>Ups! Ha sucedido un error interno.</h1>
        <p>Por favor, notificar del error enviando un mail a comunicacion.mercurio@gmail.com explicando lo sucedido.</p>
        <Link to='/'><p className='lead'>Ir a la página principal</p></Link>
      </div>
    );
  }
}

export default InternalServerError;