import React from 'react';
import './Recursos.css';
import { Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import NuevoInforme from './NuevoInforme';
import Informes from './Informes';
import PropTypes from 'prop-types';

class Analizar extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      nuevoInformeActive: false,
      informesAnterioresActive: true,
    };
  }

  toggleNuevoInforme() {
    this.setState({nuevoInformeActive: true, informesAnterioresActive: false})
  }

  toggleInformesAnteriores() {
    this.setState({nuevoInformeActive: false, informesAnterioresActive: true})
  }

  render() {

    let componente = null;
    if (this.state.nuevoInformeActive) {
      componente = (<NuevoInforme toggleInformesAnteriores={this.toggleInformesAnteriores.bind(this)} authAxios={this.props.authAxios}></NuevoInforme>)
    } else if (this.state.informesAnterioresActive) {
      componente = (<Informes toggleNuevoInforme={this.toggleNuevoInforme.bind(this)} authAxios={this.props.authAxios}></Informes>)
    }

    Nav.PropTypes = {color: '#e9feff'}

    return (
      <div>
        <Nav tabs fill>
          <NavItem>
            <NavLink active={this.state.informesAnterioresActive} onClick={this.toggleInformesAnteriores.bind(this)} >Informes anteriores</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={this.state.nuevoInformeActive} onClick={this.toggleNuevoInforme.bind(this)}>Nuevo informe</NavLink>
          </NavItem>
        </Nav>
        {componente}
      </div>
    );
  }

}

export default withRouter(Analizar);