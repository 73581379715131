import React, { Component } from 'react';
import './Recursos.css';
import {} from 'reactstrap';
import {Link} from "react-router-dom";

class Exit extends React.Component {

  constructor(props){
    super(props);
    sessionStorage.clear();
  }

  render() {    
    return (
      <div className='centrar'>
        <h1 className='display-4'>Sesión finalizada</h1>
        <Link to='/'><p className='lead'>Regresar</p></Link>
      </div>
    );
  }
}

export default Exit;