import React, { Component } from 'react';
import './Recursos.css';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Button, ButtonGroup, ModalFooter } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, withRouter, Redirect} from "react-router-dom";
import CambiarPassword from './CambiarPassword';
import Notificaciones from './Notificaciones';
import logo from './mercurio_logo.png'

class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navBarCollapsed: false,
      usuario: {rol: []},
      opcUsuarioAbierto: false,
      cambiarPasswordModal: false,
      rolesModal: false,
    }
  }

  componentDidMount() {
    this.obtenerUsuario();
  }

  toggleNavbar() {
    this.setState({navBarCollapsed: !this.state.navBarCollapsed})
  }

  obtenerUsuario() {
    const config = {headers: {Authorization: `Token ${sessionStorage.token}`}}
    this.props.authAxios.get('/usuario/', config)
    .then((Response) => {
      let usuario = Response.data;
      //Quitar el rol "PermisoGPT3"
      usuario.rol = usuario.rol.filter((rol) => {return rol !== 'PermisoGPT3'})
      this.setState({
        usuario: usuario,
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  toggleOpcUsuario() {
    this.setState({opcUsuarioAbierto: !this.state.opcUsuarioAbierto})
  }

  toggleCambiarPasswordModal() {
    this.setState({cambiarPasswordModal: !this.state.cambiarPasswordModal})
  }

  toggleRolesModal() {
    this.setState({rolesModal: !this.state.rolesModal})
  }

  asignarRol(rol) {
    sessionStorage.setItem('rol', rol);
  }

  render() {
    //Elegir opciones del menu en funcion del rol. Opciones tiene el rol analista por defecto
    let opciones = (
    <Nav className="mr-auto" navbar>
      <NavItem>
        <NavLink href="/home/pacientes/">Pacientes</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="/home/informes/">Informes</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="/home/galeria/">Galería</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="mailto:comunicacion.mercurio@gmail.com">Contacto</NavLink>
      </NavItem>
    </Nav>
    )
    if (sessionStorage.rol === "Soñante")
      opciones = (
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/home/suenios/">Sueños</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/home/informes/">Informes</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/home/galeria/">Galería</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="mailto:comunicacion.mercurio@gmail.com">Contacto</NavLink>
          </NavItem>
        </Nav>
        )
    
    //Agregar opcion de cambiar rol si usuario tiene más de un rol
    let btnCambiarRol = null;
    if (this.state.usuario.rol.length > 1)
      btnCambiarRol = <DropdownItem onClick={this.toggleRolesModal.bind(this)}>Cambiar de rol</DropdownItem>

    //Crear botones para los roles
    let roles = this.state.usuario.rol.map((rol) => {
      return (<Button key={rol} onClick={() => this.asignarRol(rol)}>{rol === "Analista" ? "Terapeuta" : rol}</Button>)
    });

    return (
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="/"><img className='logo' src={logo} alt="mercurio"></img></NavbarBrand>
        <Collapse isOpen={this.state.navBarCollapsed} navbar>
          {opciones}
          <Dropdown isOpen={this.state.opcUsuarioAbierto} toggle={this.toggleOpcUsuario.bind(this)}>
            <DropdownToggle tag="a" className="nav-link botonUsuario" caret>
              {this.state.usuario.usuario}
            </DropdownToggle>
            <DropdownMenu right >
              <div style={{textAlign:"center"}}>
              <DropdownItem header >{this.state.usuario.nombre + " " + this.state.usuario.apellido}</DropdownItem>
              {/*<DropdownItem>Administrar usuario</DropdownItem>*/}
              {btnCambiarRol}
              <DropdownItem onClick={this.toggleCambiarPasswordModal.bind(this)}>Cambiar contraseña</DropdownItem>
              <DropdownItem divider />
              <DropdownItem href={"/exit"}>Cerrar sesión</DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </Collapse>
        <div style={{'marginLeft': '1%', 'marginRight': '1%'}}><Notificaciones authAxios={this.props.authAxios}/></div>
        <NavbarToggler onClick={this.toggleNavbar.bind(this)} className="mr-2" />
        <Modal isOpen={this.state.cambiarPasswordModal} toggle={this.toggleCambiarPasswordModal.bind(this)}>
          <ModalHeader toggle={this.toggleCambiarPasswordModal.bind(this)}>Cambiar contraseña</ModalHeader>
          <ModalBody>
            <CambiarPassword toggleCambiarPasswordModal={this.toggleCambiarPasswordModal.bind(this)} authAxios={this.props.authAxios}/>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.rolesModal} >
          <ModalHeader>Seleccione un rol</ModalHeader>
          <ModalBody className='centrar'>
            <ButtonGroup>
              {roles}
            </ButtonGroup>
          </ModalBody>
          <ModalFooter><Button color='success' size='sm' href='/home'>Seleccionar</Button></ModalFooter>
        </Modal>
      </Navbar>
    );
  }
}

export default withRouter(NavBar);