import React from 'react';
import Login from './Login';
import NavBar from './NavBar';
import axios from 'axios';
import './App.css';
import Pacientes from './Pacientes';
import Suenios from './Suenios'
import Exit from './Exit'
import Analizar from './Analizar'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import IngresarSuenio from './IngresarSuenio';
import VerInforme from './VerInforme';
import Landing from './Landing';
import InternalServerError from './InternalServerError';
import PacienteSoniante from './PacienteSoniante';
import Mitologias from './eneas/Mitologias';
import Mitos from './eneas/Mitos';
import IngresarMito from './eneas/IngresarMito';
import Suenio from './suenio/Suenio';
import VerGaleria from './VerGaleria';
import ListaChats from './quiron/ListaChats';
import Chat from './quiron/Chat';
import { API_URL } from './utils/Constants';
import ListaClusters from './clusteringJerarquico/ListaClusters';
import ArbolJerarquico from './clusteringJerarquico/ArbolJerarquico';

//Crear axios que se usa para todos las llamadas al backend. Se pasa como parámetro a cada componente
const authAxios = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Token ${sessionStorage.token}`
  }
});

let routing = (
  <Router>
    <Route path="*" exact component={() => <Login authAxios={authAxios} apiUrl={API_URL}/>}/>
  </Router>
);

if (sessionStorage.rol === 'Analista')  {
  routing = (
    <Router>
      <Route path="/" exact component={() => 
        {if(!sessionStorage.token) return(<Login authAxios={authAxios} apiUrl={API_URL}/>); else return(<Redirect to="/home"/>)}}/>
      <Route path="/home" component={() => <NavBar authAxios={authAxios}/>}/>
      <Route path="/home" exact component={() => <Landing />}/>
      <Route path="/home/pacientes" exact component={() => <Pacientes authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:id/suenios" exact component={() => <Suenios authAxios={authAxios}/>}/>
      <Route path="/exit" component={Exit}/>
      <Route path="/home/pacientes/:id/agregar_suenio" component={() => <IngresarSuenio authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:id/suenios/:idSuenio/editar" exact component={() => <IngresarSuenio authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:idPaciente/suenios/:idSuenio" exact component={() => <Suenio authAxios={authAxios}/>}/>
      <Route path="/home/informes" component={() => <Analizar authAxios={authAxios}/>}/>
      <Route path="/home/informe/:id" component={() => <VerInforme authAxios={authAxios}/>}/>
      <Route path="/home/galeria" component={() => <VerGaleria authAxios={authAxios}/>}/>
      <Route path="/ErrorInterno" component={() => <InternalServerError/>}/>
      <Route path="/home/eneas" exact component={() => <Mitologias authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/mitos" exact component={() => <Mitos authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/agregar_mito" component={() => <IngresarMito authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/mitos/:idMito" component={() => <IngresarMito authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:id/quiron/" exact component={() => <ListaChats authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:idPaciente/quiron/:id" component={() => <Chat authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:id/arboles" exact component={() => <ListaClusters authAxios={authAxios}/>}/>
      <Route path="/home/pacientes/:idPaciente/arboles/:idCluster" component={() => <ArbolJerarquico authAxios={authAxios}/>}/>
    </Router>
  )
} else if (sessionStorage.rol === 'Investigador') {
  routing = (
    <Router>
      <Route path="/" exact component={() =>
        {if(!sessionStorage.token) return(<Login authAxios={authAxios} apiUrl={API_URL}/>); else return(<Redirect to="/home"/>)}}/>
      <Route path="/home" component={() => <NavBar authAxios={authAxios}/>}/>
      <Route path="/home" exact component={() => <Landing />}/>
      <Route path="/exit" component={Exit}/>
      <Route path="/ErrorInterno" component={() => <InternalServerError/>}/>
    </Router>
  )
} else if (sessionStorage.rol === 'Soñante')  {
  routing = (
    <Router>
      <Route path="/" exact component={() => 
        {if(!sessionStorage.token) return(<Login authAxios={authAxios} apiUrl={API_URL}/>); else return(<Redirect to="/home"/>)}}/>
      <Route path="/home" component={() => <NavBar authAxios={authAxios}/>}/>
      <Route path="/home" exact component={() => <Landing />}/>
      <Route path="/home/suenios" exact component={() => <Suenios authAxios={authAxios}/>}/>
      <Route path="/home/agregar_suenio" component={() => <IngresarSuenio authAxios={authAxios}/>}/>
      <Route path="/home/suenios/:idSuenio/editar" component={() => <IngresarSuenio authAxios={authAxios}/>}/>
      <Route path="/home/suenios/:idSuenio" exact component={() => <Suenio authAxios={authAxios}/>}/>
      <Route path="/exit" component={Exit}/>
      <Route path="/ErrorInterno" component={() => <InternalServerError/>}/>
      <Route path="/home/informes" component={() => <Analizar authAxios={authAxios}/>}/>
      <Route path="/home/informe/:id" component={() => <VerInforme authAxios={authAxios}/>}/>
      <Route path="/home/inicio" component={() => <PacienteSoniante authAxios={authAxios}/>}/>
      <Route path="/home/galeria/" component={() => <VerGaleria authAxios={authAxios}/>}/>
      <Route path="/home/eneas" exact component={() => <Mitologias authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/mitos" exact component={() => <Mitos authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/agregar_mito" component={() => <IngresarMito authAxios={authAxios}/>}/>
      <Route path="/home/eneas/:id/mitos/:idMito" component={() => <IngresarMito authAxios={authAxios}/>}/>
      <Route path="/home/quiron/" exact component={() => <ListaChats authAxios={authAxios}/>}/>
      <Route path="/home/quiron/:id" component={() => <Chat authAxios={authAxios}/>}/>
      <Route path="/home/arboles" exact component={() => <ListaClusters authAxios={authAxios}/>}/>
      <Route path="/home/arboles/:idCluster" component={() => <ArbolJerarquico authAxios={authAxios}/>}/>
    </Router>
  )
}

function App() {
  return (
    <div className='bgImage'>
      <div className="container-sm">
        {routing}
      </div>
    </div>
  );
}

export default App;
