import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, PopoverBody, UncontrolledPopover, Form, Spinner, Badge, Row, Col } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";
import Comentarios from './Comentarios.js';

class VerSuenio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      borrarSuenioModal: false,
      descargarPDF: false,
    }
  }

  getFavorito() {
    if (!this.props.imagenes || this.props.imagenes.length === 0) {
      return null;
    }
    let imagenes = this.props.imagenes.filter((img) => img.favorito===true)
    if(imagenes.length > 0) return imagenes[0].imagen
    else return this.props.imagenes.sort((a,b) => a.fecha > b.fecha ? -1 : 1)[0].imagen
  }
  
  render() {
    //Cargar Simbolos, Sentimientos y Contextos de sueño seleccionado
    let simbolos = this.props.suenio.simbolos.map((simbolo) => {
      return (<h5 key={simbolo.id}><Badge color="primary" className="mr-2" pill>{ simbolo.nombre }</Badge></h5>)
    });
    let sentimientos = this.props.suenio.sentimientos.map((sentimiento) => {
      return (<h5 key={sentimiento.id}><Badge color="warning" className="mr-2" pill>{sentimiento.nombre}</Badge></h5>)
    });
    let contextos = this.props.suenio.contextos.map((contexto) => {
      return (<h5 key={contexto.id}><Badge color="info" className="mr-2" pill>{ contexto.nombre }</Badge></h5>)
    });

    let favorito = this.getFavorito()

    //Sacar los símbolos utilizados para ocultar información del sueño
    let descripcion = this.props.suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');

    return (
      <div>
        <div className="row">
          <div className={window.screen.width <= 800 || favorito === null ? "col-12" : "col-8"}>
            <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{descripcion}</p>
          </div>
          {favorito === null ? null :
          <div className={window.screen.width > 800 ? "col-4 mb-4" : "col-12 mb-4"}>
            <img src={favorito} alt="Imagen del sueño" className="img-fluid" />
          </div>}
        </div>
        <h6>Rol del soñante</h6>
        <p>{this.props.suenio.es_pasivo ? 'Pasivo' : 'Activo'}</p>
        <h6>Símbolos</h6>
        {simbolos.length ? <Form inline className='margenAbajo'>{simbolos}</Form> : <p>El sueño no tiene símbolos asociados</p>}
        <h6>Sentimientos y emociones</h6>
        {sentimientos.length ? <Form inline className='margenAbajo'>{sentimientos}</Form> : <p>El sueño no tiene sentimientos o emociones asociadas</p>}
        <h6>Contextos del soñante</h6>
        {contextos.length ? <Form inline className='margenAbajo'>{contextos}</Form> : <p>El sueño no tiene contextos asociados</p>}
      </div>
    );
  }
}

export default withRouter(VerSuenio);