import React, { Component } from 'react';
import '../Recursos.css';
import { Button, Label, Input, Row, Col, ListGroup, ListGroupItem, Badge, Alert, Form, Spinner } from 'reactstrap';
// import {Badge as BadgeUI} from '@material-ui/core'
import { Editor, EditorState, } from 'draft-js';
import 'draft-js/dist/Draft.css';
import {stateToHTML}  from 'draft-js-export-html'
import {stateFromHTML}  from 'draft-js-import-html'
import '../SelectorTags.css'
import { Redirect, withRouter } from 'react-router-dom';
import SugerirSimbolos from '../SugerirSimbolos';

class IngresarMito extends React.Component {

  constructor(props){
    super(props);
      
    this.state = {
      editorState: EditorState.createEmpty(),
      simbolos: [],
      simbolosBuscados: [],
      arquetipos: [],
      arquetiposBuscados: [],
      mitoData: {
        nombre: '',
        descripcion: '',
        simbolos: [],
        arquetipos: [],
      },
      redirectListarMitos: false,
      nuevoSimbolo: '',
      nuevoArquetipo: '',
      error: null,
      hayError: false,
      sugerirSimbolosModal: false,
      permisos: true,
      cargado: false,
    };

    //Cargar terminos
    this.obtenerTerminos();

    //Para el editor de texto...
    this.onChange = editorState => this.setState({editorState});

    //Fijarse que usuario sea admin (staff)
    this.props.authAxios.get('/usuario/')
    .then((Response) => {
      if (!Response.data.es_admin) this.setState({permisos: false});
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  //Funciones para el manejo de tags
  obtenerTerminos() {
    //Hacer get simbolos
    this.props.authAxios.get(`/sol/simbolos/`)
    .then((Response1) => {
      //Hacer get arquetipos
      this.props.authAxios.get(`/eneas/arquetipos/`)
      .then((Response2) => {
        //Guardar el estado, y al finalizar fijarse si hay caso de uso de editar mito y cargarlo
        this.setState({
          simbolos: Response1.data, simbolosBuscados: Response1.data,
          arquetipos: Response2.data, arquetiposBuscados: Response2.data,
        }, () => {if (this.props.match.params.idMito) this.cargarMito();
                  else this.setState({cargado: true})})
    })})
  }

  agregarSimbolo(nombre) {
    let { mitoData } = this.state;
    mitoData.simbolos = [...this.state.mitoData.simbolos, {nombre: nombre}]
    this.setState({
      mitoData,
      simbolos: this.state.simbolos.filter(simbolo => simbolo.nombre !== nombre),
      simbolosBuscados: this.state.simbolosBuscados.filter(simbolo => simbolo.nombre !== nombre)
    });
  }

  quitarSimbolo(nombre) {
    let { mitoData } = this.state;
    mitoData.simbolos = this.state.mitoData.simbolos.filter(simbolo => simbolo.nombre !== nombre);
    this.setState({
      mitoData,
      simbolos: [...this.state.simbolos, {id: nombre, nombre: nombre}],
      simbolosBuscados:[...this.state.simbolosBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaSimbolos(busquedaSimbolo) {
    const { simbolos } = this.state;
    let simbolosBuscados = simbolos.filter(simbolo => simbolo.nombre.toLowerCase().includes(busquedaSimbolo.toLowerCase()));
    this.setState({simbolosBuscados});
  }

  agregarArquetipo(nombre) {
    let { mitoData } = this.state;
    mitoData.arquetipos = [...this.state.mitoData.arquetipos, {nombre: nombre}];
    this.setState({
      mitoData,
      arquetipos: this.state.arquetipos.filter(arquetipo => arquetipo.nombre !== nombre),
      arquetiposBuscados: this.state.arquetiposBuscados.filter(arquetipo => arquetipo.nombre !== nombre)
    });
  }

  quitarArquetipo(nombre) {
    let { mitoData } = this.state;
    mitoData.sentimientos = this.state.mitoData.arquetipos.filter(arquetipo => arquetipo.nombre !== nombre);
    this.setState({
      mitoData,
      arquetipos: [...this.state.arquetipos, {id: nombre, nombre: nombre}],
      arquetiposBuscados:[...this.state.arquetiposBuscados, {id: nombre, nombre: nombre}]
    });
  }

  busquedaArquetipos(busquedaArquetipo) {
    const { arquetipos } = this.state;
    let arquetiposBuscados = arquetipos.filter(arquetipo => arquetipo.nombre.toLowerCase().includes(busquedaArquetipo.toLowerCase()));
    this.setState({arquetiposBuscados});
  }

  redirectListarMitos() {
    this.setState({redirectListarMitos: true})
  }

  procesarDescripcion() {
    //Convierto el texto actual del editor a HTML
    let texto = stateToHTML(this.state.editorState.getCurrentContent());
    //Saco los <p>, </p>, <br> y &nbsp; cuando corresponda, y los cambio por /n si implican un enter. Cambio &amp; por &.
    texto = texto.replace(/<\/p><p>/g, '\n').replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<br>/g, '').replace(/&nbsp;/g, '').replace(/&amp;/g, '&');

    let { mitoData } = this.state;
    mitoData.descripcion = texto;
    this.setState({mitoData});
  }

  guardarMito() {
    this.procesarDescripcion()

    if (this.props.match.params.idMito) {
      let endpoint = `/eneas/mitologias/${this.props.match.params.id}/mitos/${this.props.match.params.idMito}/`;;
      //Hacer put
      this.props.authAxios.put(endpoint, this.state.mitoData).then((Response => {
        this.setState({redirectListarMitos: true})
      }))
      .catch((error => {
        this.capturarError(error.response);
      }))
    } else {
      //Hacer post
      this.props.authAxios.post(`/eneas/mitologias/${this.props.match.params.id}/mitos/`, this.state.mitoData).then((Response => {
        this.setState({redirectListarMitos: true})
      }))
      .catch((error => {
        this.capturarError(error.response);
      }))
    }
  }

  capturarError(response) {
    if (response.data.nombre) this.setState({error: "El nombre no puede quedar en blanco."});
    else if (response.data.descripcion) this.setState({error: "El contenido no puede quedar en blanco."});
    else this.setState({error: "Ups! Algo salió mal. Por favor, revisar los datos ingresados."});
    this.setState({hayError: true})
  }

  cargarMito() {
    let endpoint = `/eneas/mitologias/${this.props.match.params.id}/mitos/${this.props.match.params.idMito}/`
    //Hacer get mito
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      //Cargar datos del mito
      let { mitoData } = this.state;
      let texto = "<p>" + Response.data.descripcion + "</p>"; 
      //Pongo los <p>, </p> en vez de los \n
      texto = texto.replace(/\n/g, '</p><p>');
      mitoData.nombre = Response.data.nombre;
      mitoData.descripcion = texto;
      mitoData.fecha_creacion = Response.data.fecha_creacion;
      mitoData.simbolos = Response.data.simbolos;
      mitoData.arquetipos = Response.data.arquetipos;
      this.setState({mitoData, editorState: EditorState.createWithContent(stateFromHTML(texto)), cargado: true})
      //Quitar simbolos y arquetipos del mito de las opciones para elegir
      for (const c of Response.data.simbolos) {
        this.setState({
          simbolos: this.state.simbolos.filter(simbolo => simbolo.nombre !== c.nombre),
          simbolosBuscados: this.state.simbolos.filter(simbolo => simbolo.nombre !== c.nombre),
        });
      }
      for (const a of Response.data.arquetipos) {
        this.setState({
          arquetipos: this.state.arquetipos.filter(arquetipo => arquetipo.nombre !== a.nombre),
          arquetiposBuscados: this.state.arquetipos.filter(arquetipo => arquetipo.nombre !== a.nombre), 
        });
      }
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  toggleErrorMsg() {
    this.setState({hayError: !this.state.hayError})
  }

  toggleSugerirSimbolos(estadisticas) {
    if (this.state.sugerirSimbolosModal)
      this.props.authAxios.post('/sol/estadisticas_sugerencias_simbolos/', estadisticas)
      .catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
      });
    else
      this.procesarDescripcion()
    this.setState({sugerirSimbolosModal: !this.state.sugerirSimbolosModal})
  }

  confirmarSugerencias(simbolosSeleccionados, estadisticas) {
    let { mitoData, simbolos, simbolosBuscados } = this.state
    for (const s of simbolosSeleccionados) {
      if (mitoData.simbolos.filter(simbolo => simbolo.nombre === s).length === 0) {
        mitoData.simbolos = [...mitoData.simbolos, {id: s, nombre: s}]
        simbolos = simbolos.filter(simbolo => simbolo.nombre !== s)
        simbolosBuscados = simbolosBuscados.filter(simbolo => simbolo.nombre !== s)
      }
    }
    this.setState({
      simbolos,
      simbolosBuscados,
      mitoData
    });
    this.toggleSugerirSimbolos(estadisticas)
  }

  render() {
    // Si no se cargó todo, mostrar spinner
    if (!this.state.cargado) return (<Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>)

    //Tirar error si usuario no tiene permisos
    if (!this.state.permisos)
      return(<div><h1 className="display-4">ERROR</h1><p className="lead">No tienes permiso para ver esta página.</p></div>)

    //Cargar simbolos y arquetipos
    const simbolos = this.state.simbolosBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((s) => {
      return (<ListGroupItem key={s.id} id={s.nombre} action onClick={(e) => this.agregarSimbolo(e.target.id)}>{s.nombre}</ListGroupItem>)
    })
    const arquetipos = this.state.arquetiposBuscados.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((a) => {
      return (<ListGroupItem key={a.id} id={a.nombre} action onClick={(e) => this.agregarSentimiento(e.target.id)}>{a.nombre}</ListGroupItem>)
    })

    //Cargar los tags
    const simbolosSel = this.state.mitoData.simbolos.map((s) => {
      return (<h5 key={s.nombre}><Badge color="primary" id={s.nombre} className="mr-2" onClick={(e) => this.quitarSimbolo(e.target.id)}>{ s.nombre + ' x' }</Badge></h5>)
    })
    const arquetiposSel = this.state.mitoData.arquetipos.map((a) => {
      return (<h5 key={a.nombre}><Badge color="warning" id={a.nombre} className="mr-2" onClick={(e) => this.quitarSentimiento(e.target.id)}>{ a.nombre + ' x' }</Badge></h5>)
    })

    //Redirigir a listar mitos cuando termina el caso de uso
    let redirectListarMitos = null;
    if (this.state.redirectListarMitos) 
      redirectListarMitos = (<Redirect to={`/home/eneas/${this.props.match.params.id}/mitos/`}/>);

    let sugerirSimbolos = null;
    if (this.state.sugerirSimbolosModal)
      sugerirSimbolos = <SugerirSimbolos authAxios={this.props.authAxios} historia={this.state.mitoData.descripcion} toggle={this.toggleSugerirSimbolos} father={this} confirmarSugerencias={this.confirmarSugerencias}/>
    
    return (
      <div>
        {redirectListarMitos}
        <h1 className="display-4">{this.props.match.params.idMito ? "Editar mito" : "Ingresa un mito nuevo"}</h1>
        <br/>
        <Row>
          <Col xs="auto">
            <Label for="titulo" className="mr-sm-2" style={{fontSize: 30}}>Título:</Label>
          </Col>
          <Col xs="10">
            <Input name="titulo" id="titulo" className='inputLinea' size="lg" value={this.state.mitoData.nombre} onChange={(e) =>{
                      let { mitoData } = this.state;
                      mitoData.nombre = e.target.value;
                      this.setState({ mitoData });
                    }}/>
          </Col>
        </Row>
        <br/>
        <Label className="mr-sm-2" style={{fontSize: 30}}>Contenido:</Label>
        <br/>
        <div className='contorno scrollbar' id='bordeScrollbar'>
          <Editor editorState={this.state.editorState} onChange={this.onChange} placeholder="Escriba el mito aquí..."/>
        </div>
        <br/>
        <hr/>
        <div>
          <h1 className="display-4"><Badge color="primary" pill> </Badge> Símbolos</h1>
          <Row>
            <Col xs="6" >
              <Input name="simbolos" size="lg" placeholder="Buscar en la lista" onChange={(e) => {
                        let busquedaSimbolo = e.target.value;
                        this.busquedaSimbolos(busquedaSimbolo);
                      }}/>
              <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
                {simbolos}
              </ListGroup>
            </Col>
            <Col xs="6">
              <div className='justify-content-center d-flex'>
                <Button className='mb-4' size='sm' variant="contained" color="primary" onClick={this.toggleSugerirSimbolos.bind(this)}>Sugerencia de símbolos</Button>
                {sugerirSimbolos}
              </div>
              <p>Si el símbolo que buscas no se encuentra en la lista, puedes escribirlo aquí:</p>
              <Form inline className='justify-content-center d-flex'>
                <Input name="simbolos" placeholder="Escribir aquí..." className='' onChange={(e) => {
                            let nuevoSimbolo = e.target.value;
                            this.setState({nuevoSimbolo});
                          }}/>
                <Button outline color="success" className="ml-sm-2" onClick={() => {
                            let { mitoData, nuevoSimbolo } = this.state;
                            if (nuevoSimbolo !== '' && !mitoData.simbolos.some(({nombre}) => nombre.toLowerCase() === nuevoSimbolo.toLowerCase())) {
                              mitoData.simbolos = [...this.state.mitoData.simbolos, {nombre: nuevoSimbolo.toLowerCase()}];
                              this.setState({mitoData});
                            }
                }}>Agregar</Button>
              </Form>
            </Col>
          </Row>
          <Form inline>{simbolosSel}</Form>
        </div>
        <hr/>
        <div>
          <h1 className="display-4"><Badge color="warning" pill> </Badge> Arquetipos</h1>
          <Row>
            <Col xs="6" >
              <Input name="arquetipos" size="lg" placeholder="Buscar en la lista" onChange={(e) => {
                      let busquedaArquetipo = e.target.value;
                      this.busquedaArquetipos(busquedaArquetipo);
                    }}/>
              <ListGroup className='scrollbar contorno margenAbajo' id='bordeScrollbar' style={{height: '30vh'}}>
                {arquetipos}
              </ListGroup>
            </Col>
            <Col xs="6">
              <p>Si el arquetipo que buscas no se encuentra en la lista, puedes escribirlo aquí:</p>
              <Form inline className='justify-content-center d-flex'>
                <Input name="arquetipos" placeholder="Escribir aquí..." className='' onChange={(e) => {
                            let nuevoArquetipo = e.target.value;
                            this.setState({nuevoArquetipo});
                          }}/>
                <Button outline color="success" className="ml-sm-2" onClick={() => {
                            let { mitoData, nuevoArquetipo } = this.state;
                            if (nuevoArquetipo !== '' && !mitoData.arquetipos.some(({nombre}) => nombre.toLowerCase() === nuevoArquetipo.toLowerCase())) {
                              mitoData.arquetipos = [...this.state.mitoData.arquetipos, {nombre: nuevoArquetipo.toLowerCase()}];
                              this.setState({mitoData});
                            }
                }}>Agregar</Button>
              </Form>
            </Col>
          </Row>
          <Form inline>{arquetiposSel}</Form>
        </div>
        <hr/>
        <div className='centrar2'>
          <Alert color="danger" isOpen={this.state.hayError} toggle={this.toggleErrorMsg.bind(this)}>
            {this.state.error}
          </Alert>
          <h1 className="display-4 margenAbajo">¿Deseas guardar el mito?</h1>
          <Row className='mb-4'>
            <Button color="success" size='lg' className="mr-2" onClick={this.guardarMito.bind(this)}>Guardar</Button>
            <Button outline color="danger" size='lg' className="mr-sm-2" onClick={this.redirectListarMitos.bind(this)}>Cancelar</Button>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(IngresarMito);