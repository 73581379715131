import React, { Component } from 'react';
import './Recursos.css';
import {withRouter} from "react-router-dom";
import landingImg from './landingImg.png'

class Landing extends React.Component {

  render() {    
    return (
      <div>
      <div id="carouselExampleSlidesOnly" className="carousel slide" style={{"marginTop": "2%", "marginBottom": "1%",}} data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
              <img src={landingImg} className="d-block w-100" alt="..."/>
              <div className="carousel-caption d-none d-md-block">
                <h1>Bienvenido a mercurio</h1>
                <p><i>"El sueño es la pequeña puerta escondida en el más profundo y más íntimo santuario del alma" C. G. Jung</i></p>
              </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Landing);