import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalBody, Form, Spinner, Badge, } from 'reactstrap';
import {Link, withRouter, } from "react-router-dom";
import VerMito from '../eneas/VerMito.js';
import VerSuenio from './VerSuenio.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListaSueniosFiltrados from './ListaSueniosFiltrados.js';
import Alert from '@material-ui/lab/Alert';
import AnnouncementIcon from '@material-ui/icons/Announcement';

class InformeSuenio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sueniosSimilares: [],
      mitosSimilares: [],
      simbolo_mas_repetido: {cantidad: 0},
      sentimiento_mas_repetido: {cantidad: 0},
      simbolo_mas_repetido_continuo: {cantidad: 0},
      sentimiento_mas_repetido_continuo: {cantidad: 0},
      cargadoSuenios: false,
      cargadoMitos: false,
      cargadoEstadisticas: false,
      suenioSeleccionado: {
        id: "",
        descripcion: "",
        nombre: "",
        fecha: "",
        es_pasivo: false,
        paciente: null,
        simbolos: [],
        sentimientos: [],
        contextos: []
    },
      mitoSeleccionado: {
        id: "",
        nombre: "",
        descripcion: "",
        fecha_creacion: "",
        simbolos: [],
        arquetipos: []
    },
      verSuenioModal: false,
      verMitoModal: false,
      mitoActual: 0,
      suenioActual: 0
    }
  }

  componentDidMount(){
    if (this.props.location.state && this.props.location.state.refresh) {
      this.generarMitosSimilares();
      this.generarSueniosSimilares();
    } else {
      this.obtenerSueniosSimilares();
      this.obtenerMitosSimilares();
    }
      this.obtenerEstadisticas();
  }

  obtenerSueniosSimilares(){
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/suenios_similares/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/suenios_similares/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      if (Response.data.length > 0) {
        this.setState({
          sueniosSimilares: Response.data,
          cargadoSuenios: true
        });
      } else {
        this.generarSueniosSimilares()
      }
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }
  
  obtenerMitosSimilares(){
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/mitos_similares/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/mitos_similares/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      if (Response.data.length > 0) {
        this.setState({
          mitosSimilares: Response.data,
          cargadoMitos: true
        });
      } else {
        this.generarMitosSimilares()
      }
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  obtenerEstadisticas() {
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/estadisticas/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/estadisticas/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        simbolo_mas_repetido: Response.data.simbolo_mas_repetido,
        sentimiento_mas_repetido: Response.data.sentimiento_mas_repetido,
        simbolo_mas_repetido_continuo: Response.data.simbolo_mas_repetido_continuo,
        sentimiento_mas_repetido_continuo: Response.data.sentimiento_mas_repetido_continuo,
        cargadoEstadisticas: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  generarSueniosSimilares(){
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/suenios_similares/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/suenios_similares/`;
    //Hacer post
    this.props.authAxios.post(endpoint)
    .then((Response) => {
      this.setState({
        sueniosSimilares: Response.data,
        cargadoSuenios: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  generarMitosSimilares(){
    //Determinar rol (soñante o analista)
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/mitos_similares/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/mitos_similares/`;
    //Hacer post
    this.props.authAxios.post(endpoint)
    .then((Response) => {
      this.setState({
        mitosSimilares: Response.data,
        cargadoMitos: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });
  }

  //Recibe el mito seleccionado y la fecha ya convertida correctamente.
  cargarMitoSeleccionado(mito) {
    let { mitoSeleccionado } = this.state;
    mitoSeleccionado.id = mito.id;
    mitoSeleccionado.nombre = mito.nombre;
    mitoSeleccionado.descripcion = mito.descripcion;
    mitoSeleccionado.fecha_creacion = mito.fecha_creacion;
    mitoSeleccionado.simbolos = mito.simbolos;
    mitoSeleccionado.sentimientos = mito.arquetipos;
    this.setState({
      mitoSeleccionado: mitoSeleccionado,
      verMitoModal: true,
    });
  }

  toggleVerMitoModal() {
    this.setState({
      verMitoModal: !this.state.verMitoModal
    });
  }

  //Recibe el suenio seleccionado, la descripción ya procesada y la fecha ya convertida correctamente.
  cargarSuenioSeleccionado(suenio, desc, fecha) {
    let { suenioSeleccionado } = this.state;
    suenioSeleccionado.id = suenio.id;
    suenioSeleccionado.descripcion = desc;
    suenioSeleccionado.nombre = suenio.nombre;
    suenioSeleccionado.fecha = fecha;
    suenioSeleccionado.es_pasivo = suenio.es_pasivo;
    suenioSeleccionado.paciente = suenio.paciente;
    suenioSeleccionado.simbolos = suenio.simbolos;
    suenioSeleccionado.sentimientos = suenio.sentimientos;
    suenioSeleccionado.contextos = suenio.contextos;
    this.setState({
      suenioSeleccionado: suenioSeleccionado,
      verSuenioModal: true,
      descargarPDF: false,
    });
  }

  toggleVerSuenioModal() {
    this.setState({
      verSuenioModal: !this.state.verSuenioModal,
    });
  }

  siguienteMito(mitos) {
    if (this.state.mitoActual < mitos.length-1)
      this.setState({mitoActual: this.state.mitoActual+1})
  }

  anteriorMito() {
    if (this.state.mitoActual > 0)
      this.setState({mitoActual: this.state.mitoActual-1})
  }
  
  siguienteSuenio(suenios) {
    if (this.state.suenioActual < suenios.length-1)
      this.setState({suenioActual: this.state.suenioActual+1})
  }

  anteriorSuenio() {
    if (this.state.suenioActual > 0)
      this.setState({suenioActual: this.state.suenioActual-1})
  }

  render() {
    //Sacar los símbolos utilizados para ocultar información del sueño
    let descChica = this.props.suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
    //Achicar descripcion a a 250 caracteres y concatenar '...'
    if (descChica.length > 250) {descChica = descChica.substring(0, 250) + "...";}
    let suenio = <p className="text-justify">{descChica}<Button color="link" size="sm" className="active" onClick={this.props.toggleSuenio.bind(this)}>Ver más</Button></p>
    let mitos = this.state.mitosSimilares.sort((a, b) => {if(a.similitud === b.similitud) { return (a.mito.nombre > b.mito.nombre) ? 1 : 1;
    } else { return (a.similitud > b.similitud) ? -1 : 1; }}).map((mito) => {
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = mito.mito.descripcion;
      if (descChica.length > 250) {descChica = descChica.substring(0, 250) + "...";}
      return (
        <tr key={mito.mito.id}>
          <td><Link style={{ color: '#000' }} onClick={this.cargarMitoSeleccionado.bind(this, mito.mito)}>{mito.mito.nombre}</Link>{mito.similitud >= 0.9 ? <p className="letraChica" style={{ color: '#169F02' }}><strong>MUY ALTA</strong></p> : mito.similitud >= 0.85? <p className="letraChica" style={{ color: '#70C663' }}><strong>ALTA</strong></p> :  mito.similitud>= 0.8? <p className="letraChica" style={{ color: '#FFC300' }}><strong>MEDIA</strong></p> : <p className="letraChica" style={{ color: '#FF0000' }}><strong>BAJA</strong></p>}</td>
          <td><p className="text-justify">{descChica}<Button color="link"  size="sm" className="active" onClick={this.cargarMitoSeleccionado.bind(this, mito.mito)}>Ver más</Button></p></td>
        </tr>
      )
    })
    let suenios = this.state.sueniosSimilares.sort((a, b) => {if(a.similitud === b.similitud) { return (a.suenio.fecha > b.suenio.fecha) ? 1 : 1;
    } else { return (a.similitud > b.similitud) ? -1 : 1; }}).map((suenio) => {
      //Sacar los símbolos utilizados para ocultar información del sueño
      let desc = suenio.suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
      //Achicar descripcion a a 250 caracteres y concatenar '...'
      let descChica = desc;
      if (descChica.length > 250) {descChica = descChica.substring(0, 250) + "...";}
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(suenio.suenio.fecha + 'T00:00:00'));
      return (
        <tr key={suenio.suenio.id}>
          <td><Link style={{ color: '#000' }} onClick={this.cargarSuenioSeleccionado.bind(this, suenio.suenio, desc, fecha)}>{suenio.suenio.nombre}</Link>{suenio.similitud >= 0.9 ? <p className="letraChica" style={{ color: '#169F02' }}><strong>MUY ALTA</strong></p> : suenio.similitud >= 0.87? <p className="letraChica" style={{ color: '#70C663' }}><strong>ALTA</strong></p> : suenio.similitud>= 0.85? <p className="letraChica" style={{ color: '#FFC300' }}><strong>MEDIA</strong></p> : <p className="letraChica" style={{ color: '#FF0000' }}><strong>BAJA</strong></p>}</td>
          <td><p className="text-justify">{descChica}<Button color="link" size="sm" className="active" onClick={this.cargarSuenioSeleccionado.bind(this, suenio.suenio, desc, fecha)}>Ver más</Button></p></td>
        </tr>
      )
    })
    let simboloRep = this.state.simbolo_mas_repetido.cantidad <= 1? null : this.state.simbolo_mas_repetido.cantidad === 2?
      <Form inline><Badge color="primary" pill> </Badge><span className="ml-2 lead">El símbolo <i>{this.state.simbolo_mas_repetido.simbolo.nombre}</i> ya ha aparecido en un sueño. <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} simbolo={this.state.simbolo_mas_repetido.simbolo} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form> :
      <Form inline><Badge color="primary" pill> </Badge><span className="ml-2 lead">El símbolo <i>{this.state.simbolo_mas_repetido.simbolo.nombre}</i> ya ha aparecido en {this.state.simbolo_mas_repetido.cantidad - 1} sueños. <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} simbolo={this.state.simbolo_mas_repetido.simbolo} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form>
    let sentimientoRep = this.state.sentimiento_mas_repetido.cantidad <= 1? null : this.state.sentimiento_mas_repetido.cantidad === 2?
      <Form inline className="mt-2"><Badge color="warning" pill> </Badge><span className="ml-2 lead">El sentimiento <i>{this.state.sentimiento_mas_repetido.sentimiento.nombre}</i> ya ha aparecido en un sueño. <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} sentimiento={this.state.sentimiento_mas_repetido.sentimiento} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form> :
      <Form inline className="mt-2"><Badge color="warning" pill> </Badge><span className="ml-2 lead">El sentimiento <i>{this.state.sentimiento_mas_repetido.sentimiento.nombre}</i> ya ha aparecido en {this.state.sentimiento_mas_repetido.cantidad - 1} sueños. <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} sentimiento={this.state.sentimiento_mas_repetido.sentimiento} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form>
    let simboloRepCont = this.state.simbolo_mas_repetido_continuo.cantidad <= 1? null : this.state.simbolo_mas_repetido_continuo.cantidad === 2?
      <Form inline className="mt-2"><Badge color="primary" pill> </Badge><span className="ml-2 lead">¡En el sueño anterior a este también estuvo el símbolo <i>{this.state.simbolo_mas_repetido_continuo.simbolo.nombre}</i>! <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} simbolo={this.state.simbolo_mas_repetido_continuo.simbolo} fecha_suenio_hasta={this.props.suenio.fecha} cantidad={this.state.simbolo_mas_repetido_continuo.cantidad} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form> :
      <Form inline className="mt-2"><Badge color="primary" pill> </Badge><span className="ml-2 lead">¡En los {this.state.simbolo_mas_repetido_continuo.cantidad - 1} sueños anteriores a este también estuvo el símbolo <i>{this.state.simbolo_mas_repetido_continuo.simbolo.nombre}</i>!  <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} simbolo={this.state.simbolo_mas_repetido_continuo.simbolo} fecha_suenio_hasta={this.props.suenio.fecha} cantidad={this.state.simbolo_mas_repetido_continuo.cantidad} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form>
    let sentimientoRepCont = this.state.sentimiento_mas_repetido_continuo.cantidad <= 1? null : this.state.sentimiento_mas_repetido_continuo.cantidad === 2?
      <Form inline className="mt-2"><Badge color="warning" pill> </Badge><span className="ml-2 lead">¡En el sueño anterior a este también estuvo el sentimiento <i>{this.state.sentimiento_mas_repetido_continuo.sentimiento.nombre}</i>! <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} sentimiento={this.state.sentimiento_mas_repetido.sentimiento} fecha_suenio_hasta={this.props.suenio.fecha} cantidad={this.state.sentimiento_mas_repetido_continuo.cantidad} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form> :
      <Form inline className="mt-2"><Badge color="warning" pill> </Badge><span className="ml-2 lead">¡En los {this.state.sentimiento_mas_repetido_continuo.cantidad - 1} sueños anteriores a este también estuvo el sentimiento <i>{this.state.sentimiento_mas_repetido_continuo.sentimiento.nombre}</i>! <ListaSueniosFiltrados idPaciente={this.props.suenio.paciente.id} sentimiento={this.state.sentimiento_mas_repetido.sentimiento} fecha_suenio_hasta={this.props.suenio.fecha} cantidad={this.state.sentimiento_mas_repetido_continuo.cantidad} cargarSuenioSeleccionado={this.cargarSuenioSeleccionado.bind(this)} authAxios={this.props.authAxios}/></span></Form>
    let estadisticas = !this.state.cargadoEstadisticas? <Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/> : 
      <div>{simboloRep}{sentimientoRep}{simboloRepCont}{sentimientoRepCont}</div>
    if (simboloRep === null && sentimientoRep === null && simboloRepCont === null && sentimientoRepCont === null && this.state.cargadoEstadisticas) estadisticas = 'No hay estadísticas para mostrar.'

    return (
      <div>
        {suenio}
        <Alert icon={<AnnouncementIcon fontSize="inherit" />} severity="error">
          <strong>¡NUEVO!</strong> Responde preguntas generadas con inteligencia artificial para amplificar el sueño. <Link onClick={this.props.togglePreguntas.bind(this)}>¡Haz click aquí para responder!</Link>
        </Alert>
        <h6 className="mt-4">Estadísticas</h6>
        {estadisticas}
        <Form inline className="mt-4">
          <h6 className="mb-0  mr-1">Sueños similares</h6>
          <Tooltip enterTouchDelay={0}
            title={
              <p className="text-justify mb-0">
                Se obtienen los sueños similares mediante el uso de Inteligencia Artificial teniendo en cuenta el texto, los símbolos y los sentimientos de los sueños.<strong><br/>Ningún humano lee los sueños para este procesamiento.</strong>
              </p>
            }
          >
            <IconButton size="small" style={{outline: 'none'}}>
              <HelpOutlineIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Form>
        <p className="letraChica mt-0">Sueños anteriores que tienen alguna similitud con este.</p>
        <div>
          {!this.state.cargadoSuenios? <Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/> 
          : suenios.length ?
          <div>
            <Table className="mb-0">
              <tbody>
                {suenios[this.state.suenioActual]}
              </tbody>
            </Table>
            <div className="derecha">
              <IconButton disabled={this.state.suenioActual === 0} onClick={this.anteriorSuenio.bind(this)} aria-label="ant" size="small" style={{outline: 'none'}}>
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              <IconButton disabled={this.state.suenioActual === suenios.length-1} onClick={this.siguienteSuenio.bind(this,suenios)} aria-label="sig" size="small" style={{outline: 'none'}}>
                <ArrowForwardIosIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
          : <p>Aún no has subido otros sueños.</p>}
        </div>
        <Form inline className="mt-4">
          <h6 className="mb-0 mr-1">Mitos afines</h6>
          <Tooltip enterTouchDelay={0}
            title={
              <p className="text-justify mb-0">
                Se obtienen los mitos afines mediante el uso de Inteligencia Artificial teniendo en cuenta el texto y los símbolos del sueño y los mitos. <strong><br/>Ningún humano lee los sueños para este procesamiento.</strong>
              </p>
            }
          >
            <IconButton size="small" style={{outline: 'none'}}>
              <HelpOutlineIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Form>
        <p className="letraChica mt-0">Algunos mitos que podrían estar relacionados con el sueño.</p>
        <div>
          {!this.state.cargadoMitos? <Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/> 
          : mitos.length ?
          <div>
            <Table className="mb-0">
              <tbody>
                {mitos[this.state.mitoActual]}
              </tbody>
            </Table>
            <div className="derecha">
              <IconButton disabled={this.state.mitoActual === 0} onClick={this.anteriorMito.bind(this)} aria-label="ant" size="small" style={{outline: 'none'}}>
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              <IconButton disabled={this.state.mitoActual === mitos.length-1} onClick={this.siguienteMito.bind(this,mitos)} aria-label="sig" size="small" style={{outline: 'none'}}>
                <ArrowForwardIosIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
          : <p>El sueño no tiene mitos similares.</p>}
        </div>
        <Modal isOpen={this.state.verMitoModal} toggle={this.toggleVerMitoModal.bind(this)}>
          <ModalHeader toggle={this.toggleVerMitoModal.bind(this)}>{this.state.mitoSeleccionado.nombre}</ModalHeader>
          <ModalBody>
            <VerMito mito={this.state.mitoSeleccionado} authAxios={this.props.authAxios}/>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.verSuenioModal} toggle={this.toggleVerSuenioModal.bind(this)}>
          <ModalHeader toggle={this.toggleVerSuenioModal.bind(this)}>{this.state.suenioSeleccionado.nombre}<p className='letraChica'>{this.state.suenioSeleccionado.fecha} - <a href={sessionStorage.rol === 'Soñante'? `/home/suenios/${this.state.suenioSeleccionado.id}/` : `/home/pacientes/${this.props.match.params.idPaciente}/suenios/${this.state.suenioSeleccionado.id}/`}>Ver resumen</a></p></ModalHeader>
          <ModalBody>
            <VerSuenio suenio={this.state.suenioSeleccionado} authAxios={this.props.authAxios}/>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(InformeSuenio);