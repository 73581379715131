import React from 'react';
import '../Recursos.css';
import { Table, Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Badge, Row, Col } from 'reactstrap';
import {Button as ButtonUI} from '@material-ui/core';
import {Link, Redirect, withRouter} from "react-router-dom";
import { InformeAPDF } from '../PDF/CreadorPDF'
import { PDFDownloadLink } from '@react-pdf/renderer';
import Burbujas from './Burbujas';

const MAX = 5;

class ResumenInforme extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      simbolosTotales: this.contarSimbolos(),
      sentimientosTotales: this.contarSentimientos(),
      contextosTotales: this.contarContextos(),
      descargarPDF: false,
      maxSimbolos: MAX,
      maxSentimientos: MAX,
      maxContextos: MAX,
      verMasSimbolos: false,
      verMasSentimientos: false,
      verMasContextos: false
    }
  }

  //Se retorna un array de arrays [a, b] donde a es un simbolo que aparece en al menos un sueño y b es la cantidad de apariciones de a en todos los sueños 
  contarSimbolos() {
    let simbolos = [];
    for(const suenio of this.props.informe.suenios) {
      for(const simbolo of suenio.simbolos) {
        let yaEstaba = false;
        for(let c of simbolos) {
          if (c[0] === simbolo.nombre) {
            c[1]++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) simbolos = [...simbolos, [simbolo.nombre, 1]]
      }
    }
    return simbolos.sort((a, b) => a[1] < b[1] ? 1 : -1)
  }

  //Se retorna un array de arrays [a, b] donde a es un sentimiento que aparece en al menos un sueño y b es la cantidad de apariciones de a en todos los sueños 
  contarSentimientos() {
    let sentimientos = [];
    for(const suenio of this.props.informe.suenios) {
      for(const sentimiento of suenio.sentimientos) {
        let yaEstaba = false;
        for(let s of sentimientos) {
          if (s[0] === sentimiento.nombre) {
            s[1]++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) sentimientos = [...sentimientos, [sentimiento.nombre, 1]]
      }
    }
    return sentimientos.sort((a, b) => a[1] < b[1] ? 1 : -1)
  }

  //Se retorna un array de arrays [a, b] donde a es un contexto que aparece en al menos un sueño y b es la cantidad de apariciones de a en todos los sueños 
  contarContextos() {
    let contextos = [];
    for(const suenio of this.props.informe.suenios) {
      for(const contexto of suenio.contextos) {
        let yaEstaba = false;
        for(let c of contextos) {
          if (c[0] === contexto.nombre) {
            c[1]++;
            yaEstaba = true;
          }
        }
        if(!yaEstaba) contextos = [...contextos, [contexto.nombre, 1]]
      }
    }
    return contextos.sort((a, b) => a[1] < b[1] ? 1 : -1)
  }

  verMasSimbolos() {
    this.setState({
      maxSimbolos: !this.state.verMasSimbolos? this.state.simbolosTotales.length : MAX, 
      verMasSimbolos: !this.state.verMasSimbolos
    })
  }

  verMasSentimientos() {
    this.setState({
      maxSentimientos: !this.state.verMasSentimientos? this.state.sentimientosTotales.length : MAX,
      verMasSentimientos: !this.state.verMasSentimientos
    })
  }

  verMasContextos() {
    this.setState({
      maxContextos: !this.state.verMasContextos? this.state.contextosTotales.length : MAX,
      verMasContextos: !this.state.verMasContextos
    })
  }

  render() {

    //Cargar frases de repeticiones de términos
    var simbolos = this.state.simbolosTotales.slice(0, this.state.maxSimbolos).map((c, index) => {
      return <p key={c[0]}>{index+1}. <strong>{c[0]}</strong> apareció {c[1] + (c[1] > 1 ? " veces." : " vez.")} </p>
    })

    var sentimientos = this.state.sentimientosTotales.slice(0, this.state.maxSentimientos).map((s, index) => {
      return <p key={s[0]}>{index+1}. <strong>{s[0]}</strong> apareció {s[1] + (s[1] > 1 ? " veces." : " vez.")} </p>
    })

    var contextos = this.state.contextosTotales.slice(0, this.state.maxContextos).map((c, index) => {
      return <p key={c[0]}>{index+1}. <strong>{c[0]}</strong> apareció {c[1] + (c[1] > 1 ? " veces." : " vez.")} </p>
    })

    var sobreSimbolos = (<p>No hay ningún símbolo en los sueños retornados.</p>)
    if (simbolos.length > 0) sobreSimbolos = (<p>En total, hay <strong>{this.state.simbolosTotales.length} símbolos diferentes</strong> en los sueños retornados. 
    A continuación se listan los <strong>{simbolos.length} más recurrentes:</strong></p>)

    var sobreSentimientos = (<p>No hay ningún sentimiento/emoción en los sueños retornados.</p>)
    if (sentimientos.length > 0) sobreSentimientos = (<p>En total, hay <strong>{this.state.sentimientosTotales.length} sentimientos/emociones diferentes</strong> en los sueños retornados. 
      A continuación se listan los <strong>{sentimientos.length} más recurrentes:</strong></p>)

    var sobreContextos = (<p>No hay ningún contexto en los sueños retornados.</p>)
    if (contextos.length > 0) sobreContextos = (<p>En total, hay <strong>{this.state.contextosTotales.length} contextos diferentes</strong> en los sueños retornados. 
      A continuación se listan los <strong>{contextos.length} más recurrentes:</strong></p>)

    var verMasSimbolos = <ButtonUI color="primary" onClick={this.verMasSimbolos.bind(this)}>{this.state.verMasSimbolos ? 'Ver menos' : 'Ver todos'}</ButtonUI>
    var verMasSentimientos = <ButtonUI color="primary" onClick={this.verMasSentimientos.bind(this)}>{this.state.verMasSentimientos ? 'Ver menos' : 'Ver todos'}</ButtonUI>
    var verMasContextos = <ButtonUI className="mb-2" color="primary" onClick={this.verMasContextos.bind(this)}>{this.state.verMasContextos ? 'Ver menos' : 'Ver todos'}</ButtonUI>

    //Agregar botón de descarga de sueños sólo cuando se cargaron los sueños y se apreta el botón de descargar (evitar rerender)
    let PDF = (<Button color="link" style={{color : 'black'}} onClick={() => this.setState({descargarPDF: true})}>Descargar informe</Button>);
    if (this.state.descargarPDF) PDF = (<PDFDownloadLink document={<InformeAPDF informe={this.props.informe} simbolosTotales={this.state.simbolosTotales} sentimientosTotales={this.state.sentimientosTotales} contextosTotales={this.state.contextosTotales} max={MAX}/>} fileName={`informe ${this.props.informe.fecha_creacion}.pdf`}>
      {({ blob, url, loading, error }) => (loading ? 'Generando PDF...' : 'Click aquí!')}
    </PDFDownloadLink>);

    return (
      <div className="container-sm">
        <Form inline>
          <p className="mt-2">Cantidad de sueños en el informe: {this.props.informe.suenios.length}</p>
          <hr/>
          {PDF}
        </Form>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="mr-sm-2 text-justify"><Badge color="primary" pill> </Badge> Sobre símbolos...</h5>
            {sobreSimbolos}
            {simbolos}
            {verMasSimbolos}
          </div>
          <div className="col-sm-6">
            {simbolos.length > 0 ? <Burbujas data={this.state.simbolosTotales}/> : null}
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="mr-sm-2 text-justify"><Badge color="warning" pill> </Badge> Sobre sentimientos y emociones...</h5>
            {sobreSentimientos}
            {sentimientos}
            {verMasSentimientos}
          </div>
          <div className="col-sm-6">
            {sentimientos.length > 0 ? <Burbujas data={this.state.sentimientosTotales}/> : null}
          </div>
        </div>
        <hr/>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="mr-sm-2 text-justify"><Badge color="info" pill> </Badge> Sobre contextos...</h5>
            {sobreContextos}
            {contextos}
            {verMasContextos}
          </div> 
          <div className="col-sm-6">
            {contextos.length > 0 ? <Burbujas data={this.state.contextosTotales}/> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResumenInforme);