import React from 'react';
import './Recursos.css';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import BrushIcon from '@material-ui/icons/Brush';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import { withRouter} from "react-router-dom";
import {Button, Form, FormGroup, Input, Modal, ModalFooter, ModalBody, Spinner, Alert} from "reactstrap";
import {Button as ButtonUI} from '@material-ui/core';
import { fetch_stream } from './utils/Streaming';


class Galeria extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imagenes: [],
      cargado: false,
      imagen: {
        imagen: null,
        descripcion: null
      },
      error: null,
      borrarImagenModal: false,
      seleccionada: null,
      generando: false,
      modelo: "stable_diffusion",
      mensaje: '',
    }
  }

  componentDidMount() {
    this.actualizarImagenes();
  }

  actualizarImagenes() {
    let endpoint =`/febo/galeria/imagenes/`;
    this.props.authAxios.get(endpoint) //Hacer get de las imagenes del sueño
      .then((Response) => {
        this.setState({
          imagenes: Response.data,
          cargado: true
        });
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }

  subirImagen() {
    const formData = new FormData();
    formData.append("imagen", this.state.imagen.imagen);
    formData.append("descripcion", this.state.imagen.descripcion);

    let endpoint =`/febo/galeria/imagenes/`;
    this.props.authAxios.post(endpoint, formData)
      .then((Response) => {
        this.props.seleccionarImagen(Response.data.id);
      }).catch((error) => {
        this.setState({error: error.response.data.imagen});
    })
  }

  generarImagen(modelo) {
    let endpoint =`/pacientes/${this.props.match.params.idPaciente}/suenios/${this.props.match.params.idSuenio}/generar_imagen/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.match.params.idSuenio}/generar_imagen/`;
    let imagen = null;
    fetch_stream(endpoint, {"modelo": modelo}, (mensaje) => {
      if (!mensaje.startsWith('{')) {
        this.setState({mensaje: mensaje});
      } else {
        imagen = mensaje;
      }
    }, () => {
      imagen = JSON.parse(imagen);
      if (imagen.error) {
        this.setState({error: imagen.error});
        console.log(imagen.error);
        return;
      }
      this.props.seleccionarImagen(imagen.id);
    }, (error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  toggleBorrarImagenModal() {
    this.setState({
        borrarImagenModal: !this.state.borrarImagenModal
    });
  }

  borrarImagen() {
    let endpoint =`/febo/galeria/imagenes/${this.state.seleccionada}/`;
    this.props.authAxios.delete(endpoint)
      .then((Response) => {
        this.actualizarImagenes();
        this.toggleBorrarImagenModal();
      }).catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        this.props.history.push('/ErrorInterno')
    })
  }
  
  render() {
    if(!this.state.cargado) {
      return <Spinner className="mt-3" style={{ width: '3rem', height: '3rem' }}/>
    }

    let generarImagen = null;
    if (this.props.permisoGPT3) {
        generarImagen = (<div><hr/><h6>Crear imagen con inteligencia artificial</h6>{
        this.state.generando ?
        (<div className="centrar2">
          <Spinner className='mb-2'/>
          <i className="text-center">{this.state.mensaje}</i>
        </div>) :
        (<div className="centrar2 mb-3">
            <select id="modelo" className="form-control" value={this.state.modelo} onChange={(e) => this.setState({modelo: e.target.value})}>
                <option value="stable_diffusion">Stable Diffusion</option>
                <option value="dalle2">Dalle 2</option>
            </select>
            <ButtonUI variant="contained" color="primary" className='mt-3' style={{ backgroundColor: '#01F7E2', color: 'black' }} size='large' startIcon={<BrushIcon />} onClick={() => {this.setState({generando: true}); this.generarImagen(this.state.modelo);}}>
                Crear imagen
            </ButtonUI>
        </div>)}
        </div>);
    }

    return (
      <div>
        <h6>Subir imagen</h6>
        <Form>
            <Input
            id="imageUpload"
            name="imageUpload"
            type="file"
            onChange={(e) => this.setState({imagen: {imagen: e.target.files[0], descripcion: this.state.imagen.descripcion}})}
            />
            <FormGroup>
                <Input
                className="mt-2"
                id="descripcionImg"
                name="descripcionImg"
                type="textarea"
                placeholder="Breve descripción de la imagen (opcional)"
                onChange={(e) => this.setState({imagen: {imagen: this.state.imagen.imagen, descripcion: e.target.value}})}
                />
            </FormGroup>
            <div className='derecha mb-2'>
                <Button color='primary' onClick={this.subirImagen.bind(this)}>
                    Subir imagen
                </Button>
            </div>
            <Alert color="danger" isOpen={this.state.error}>
                {this.state.error}
            </Alert>
        </Form>
        {generarImagen}
        {this.state.imagenes.length === 0 ? null :
        (<div>
            <hr/>
            <h6>Elegir imagen de galería</h6>
            <ImageList rowHeight={160} cols={6}>
            {this.state.imagenes.sort((a, b) => a.fecha > b.fecha ? -1 : 1).map((img) => (
                <ImageListItem key={img.id} cols={1} >
                <img src={img.imagen} alt={img.descripcion}/>
                <ImageListItemBar
                    style={{backgroundColor: 'transparent'}}
                    actionIcon={
                    <IconButton aria-label={`select ${img.id}`} className='btnSinBorde' onClick={() => {this.props.seleccionarImagen(img.id)}}>
                        <CheckCircleIcon style={{color:'green'}} />
                    </IconButton>
                    }
                />
                <ImageListItemBar
                    style={{backgroundColor: 'transparent'}}
                    position="top"
                    actionIcon={
                    <IconButton aria-label={`delete ${img.id}`} className='btnSinBorde' onClick={() => {this.setState({seleccionada: img.id}); this.toggleBorrarImagenModal()}}>
                        <DeleteIcon color='secondary' />
                    </IconButton>
                    }
                />
                </ImageListItem>
            ))}
            </ImageList>
        </div>)
        }
        <Modal isOpen={this.state.borrarImagenModal} toggle={this.toggleBorrarImagenModal.bind(this)}>
            <ModalBody>
                La imagen se borrará permanentemente. ¿Estás seguro que deseas continuar?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.borrarImagen.bind(this)}>Confirmar</Button>{' '}
                <Button color="secondary" onClick={this.toggleBorrarImagenModal.bind(this)}>Cancelar</Button>
            </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Galeria);