import React from 'react';
import '../Recursos.css';
import { Table, Button, Spinner, Alert, Form } from 'reactstrap';
import { Link, withRouter} from "react-router-dom";
import {IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


class ListaChats extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      chats: [],
      chatSeleccionado: null,
      paciente: null,
      error: null,
      errorMsg: false,
      cargado: false,
    }

    //Hacer get de paciente para obtener nombre, si rol es analista
    if(sessionStorage.rol !== 'Soñante') {
      this.props.authAxios.get(`/pacientes/${this.props.match.params.id}/`)
      .then((Response) => {
        this.setState({
          paciente: Response.data.nombre
        });
      })
      .catch((error) => {
        this.capturarError(error.response);
      });
    }
  }

  actualizarChats() {
    //Hacer get
    const path = sessionStorage.rol === 'Soñante' ? '/quiron/chats/' : `/quiron/pacientes/${this.props.match.params.id}/`;
    this.props.authAxios.get(path)
    .then((Response) => {
      this.setState({
        chats: Response.data,
        cargado: true,
      });
    })
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  componentDidMount(){this.actualizarChats()}

  crearChat() {
    const path = sessionStorage.rol === 'Soñante' ? '/quiron/chats/' : `/quiron/pacientes/${this.props.match.params.id}/`;
    this.setState({cargado: false})
    this.props.authAxios.post(path).then((Response => {
      if (sessionStorage.rol === 'Soñante')
        this.props.history.push(`/home/quiron/${Response.data.id}/`);
      else 
        this.props.history.push(`/home/pacientes/${this.props.match.params.id}/quiron/${Response.data.id}/`);
    }))
    .catch((error => {
      this.capturarError(error.response);
    }))
  }

  capturarError(response) {
    // Si el error es 403, significa que el usuario no tiene permisos para realizar la acción
    if (response.status === 403) this.setState({error: "No tiene permisos para realizar esta acción."});
    else if (response.status === 404) this.setState({error: "No se ha encontrado el recurso."});
    else this.props.history.push('/ErrorInterno');
    this.setState({errorMsg: true, cargado: true});
  }

  toggleErrorMsg() {
    this.setState({errorMsg: !this.state.errorMsg})
  }

  volverAtras() {
    //Determinar rol (soñante o analista)
    let dir = `/home/pacientes/${this.props.match.params.idPaciente}/`;
    if(sessionStorage.rol === 'Soñante') dir = `/home/suenios/`;
    this.props.history.push(dir);
  }

  render() {
    // Cargar chats
    let chats = this.state.chats.sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1).map((chat) => {
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit"}).format(new Date(chat.fecha_creacion));
      const path = sessionStorage.rol === 'Soñante' ? `/home/quiron/${chat.id}/` : `/home/pacientes/${this.props.match.params.id}/quiron/${chat.id}/`;
      return (
        <tr key={chat.id}>
          <td>
            <Link to={path} style={{ color: '#000' }}>
              {!chat.titulo ? `Chat del ${fecha}` : chat.titulo} 
            </Link>
            <p className='letraChica'>
              Sobre {chat.cantidad_suenios} sueños
              {chat.titulo && <span> - {fecha}</span>}
            </p>
          </td>
        </tr>
      )
    });

    return (
      <div>
        <Form inline>
          <IconButton onClick={this.volverAtras.bind(this)} aria-label="volver" size="medium" className="mt-2">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <div className="ml-2 mr-2">
            <h4 className={`display-4 mt-0 mt-md-3 ${sessionStorage.rol === 'Soñante' ? '' : 'mb-0'}`}>Conversaciones con Quirón</h4>
            {this.state.paciente && <h2 className="lead">Sobre los sueños de {this.state.paciente}</h2>}
          </div>
        </Form>
        {this.state.errorMsg && <Alert color="danger" isOpen={this.state.errorMsg} toggle={this.toggleErrorMsg.bind(this)}>{this.state.error}</Alert>}
        {(!this.state.cargado) ? <Spinner style={{ width: '3rem', height: '3rem' }}/>
        : !this.state.error &&
        <>
        {(chats.length === 0 && this.state.cargado) && (<p>Aún no se ha iniciado ninguna conversación con Quirón.</p>)}
        <div className="scrollbarListas" id='bordeScrollbar'>
          <Table>
            <tbody>
              {chats}
            </tbody>
          </Table>
        </div>
        <div className="leftbutton">
          <Button outline color="success" size="lg" className="margenAbajo" onClick={this.crearChat.bind(this)}>+</Button>
        </div> 
        </>}
      </div>
    );
  }
}

export default withRouter(ListaChats);