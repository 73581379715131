import React from 'react';
import '../Recursos.css';
import { Toast, ToastHeader, ToastBody, Spinner, Row, Col, Button, Input, Form, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {Link, Redirect, withRouter} from "react-router-dom";

class Comentarios extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      comentarios: [],
      cargado: false,
      nuevoComentarioData: {
        comentario: '',
        tipo: {tipo: 'comentario'},
      },
      agregarComentario: false,
      borrarComentarioModal: false,
      comentarioSeleccionado: null,
    };
  }

  componentDidMount(){this.obtenerComentarios()}

  obtenerComentarios() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/comentarios/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/comentarios/`;
    //Hacer get
    this.props.authAxios.get(endpoint)
    .then((Response) => {
      this.setState({
        comentarios: Response.data,
        cargado: true
      });
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    });  
  }

  agregarComentario() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.id}/comentarios/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/comentarios/`;
    this.props.authAxios.post(endpoint, this.state.nuevoComentarioData).then((Response => {
      let { comentarios } = this.state;
      comentarios.push(Response.data); 
      this.setState({agregarComentario: false, comentarios});
    }))
    .catch((error => {
      //Resolver después
    }))
  }

  borrarComentario() {
    //Determinar rol (soñante o analista)
    let endpoint = `/pacientes/${this.props.suenio.paciente.id}/suenios/${this.props.suenio.idSuenio}/comentarios/${this.state.comentarioSeleccionado}/`;
    if(sessionStorage.rol === 'Soñante') endpoint = `/suenios/${this.props.suenio.id}/comentarios/${this.state.comentarioSeleccionado}/`;
    this.props.authAxios.delete(endpoint).then((Response => {
      let { comentarios } = this.state;
      comentarios = this.state.comentarios.filter(c => c.id !== this.state.comentarioSeleccionado);
      this.setState({comentarios, borrarComentarioModal: false});
    }))
    .catch((error => {
      //No debería darse nunca, dirigirse a InternalServerError
      this.props.history.push('/ErrorInterno')
    }))
  }

  toggleBorrarComentarioModal() {
    this.setState({
      borrarComentarioModal: !this.state.borrarComentarioModal
    });
  }

  render() {

    //Cargar comentarios
    let comentarios = this.state.comentarios.sort((a, b) => a.fecha_creacion < b.fecha_creacion ? 1 : -1).map((comentario) => {
      //Color en función del tipo
      let colorTipo = 'rgba(0, 255, 255, 0.4)';
      if (comentario.tipo.tipo === 'asociación') colorTipo = 'rgba(255, 0, 0, 0.4)';
      if (comentario.tipo.tipo === 'amplificación') colorTipo = 'rgba(255, 255, 0, 0.4)';
      if (comentario.tipo.tipo === 'interpretación') colorTipo = 'rgba(0, 255, 0, 0.4)';

      //Fecha en formato lindo
      let fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit"}).format(new Date(comentario.fecha_creacion))
      
      //Ver si puede o no borrar el comentario
      let es_analista = true;
      if (sessionStorage.rol === 'Soñante') es_analista = false;
      let btnBorrar = null;
      if (es_analista === comentario.es_analista) btnBorrar = (
      <div>
      <Button outline color="danger" size='sm' onClick={() => 
        {this.setState({comentarioSeleccionado: comentario.id}); this.toggleBorrarComentarioModal(this)}
      }>🗑</Button>
      </div>);

      return (
      <Toast key={comentario.id} style={{'maxWidth': '100%', 'backgroundColor':colorTipo}}>
        <ToastHeader>
            {comentario.tipo.tipo} - {comentario.es_analista ? "terapeuta" : "soñante"}
        </ToastHeader>
        <ToastBody>
        <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{comentario.comentario}</p>
        <div className='derecha'>
          <p className='letraChica mr-sm-2 my-auto'>{comentario.usuario} - {fecha}</p>
          {btnBorrar}
        </div>
        </ToastBody>
      </Toast>)
    });

    //Ver si cargó lista de comentarios
    let cargando = <Spinner style={{ width: '3rem', height: '3rem' }}/>;
    if (this.state.cargado) cargando = null;

    //Botón de agregar comentario, y al clickearlo, espacio para escribir comentario
    let agregarComentario = <div><Button color="link" onClick={() => 
      this.setState({agregarComentario: true, 
        nuevoComentarioData: {
          comentario: '',
          tipo: {tipo: 'comentario'},
    }})}>Agregar comentario...</Button></div>
    if (this.state.agregarComentario) agregarComentario = (
      <div className='margenAbajo'>
        <Input type="textarea" name="comentario" id="comentario" className='margenAbajo' onChange={(e) => {
          let { nuevoComentarioData } = this.state;
          nuevoComentarioData.comentario = e.target.value;
          this.setState({ nuevoComentarioData });
        }}/>
        <div className='derecha'>
          <Input type="select" size='sm' name="tipo" id="tipo" style={{width:'30%'}} className='mr-sm-2' onChange={(e) => {
            let { nuevoComentarioData } = this.state;
            nuevoComentarioData.tipo.tipo = e.target.value;
            this.setState({ nuevoComentarioData })}
          }>
            <option>comentario</option>
            <option>asociación</option>
            <option>amplificación</option>
            <option>interpretación</option>
          </Input>
          <Button color="primary" size='sm' className='mr-sm-2' onClick={this.agregarComentario.bind(this)}>Agregar</Button>
          <Button color="secondary" size='sm' onClick={() => this.setState({agregarComentario: false})}>Cancelar</Button>
        </div>
      </div>
    );

    return (
      <div>
        {cargando}
        {comentarios}
        {agregarComentario}
        <Modal isOpen={this.state.borrarComentarioModal} toggle={this.toggleBorrarComentarioModal.bind(this)}>
          <ModalBody>
            ¿Estás seguro que deseas borrar el comentario?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.borrarComentario.bind(this)}>Confirmar</Button>{' '}
            <Button color="secondary" onClick={this.toggleBorrarComentarioModal.bind(this)}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Comentarios);