import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Badge, Form, Spinner } from 'reactstrap';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import InfoIcon from '@material-ui/icons/Info';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import VerSuenio from '../suenio/VerSuenio';

const transform = (node) => {
  if (node.nombre) {
    node.nombre = node.nombre.replace(/"/g, "");
  } else if (!node.hijos) {
    node.nombre = "Sueño " + node.id;
  }
  if (node.hijos) {
    node.children = node.hijos.map(transform);
  } else {
    return { name: node.nombre, id: node.id };
  }
  return { name: node.nombre, children: node.children, analisis: node.analisis, elementos: node.elementos, simbolos: node.simbolos };
};

function ArbolJerarquico(props) {
  const history = useHistory();
  const params = useParams()
  const [tree, setTree] = useState({});
  const [verSuenioModal, setVerSuenioModal] = useState(false);
  const [suenioSeleccionado, setSuenioSeleccionado] = useState({});
  const [drawer, setDrawer] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [cargando, setCargando] = useState(true);
  const [instrucciones, setInstrucciones] = useState(true);

  useEffect(() => {
    getTreeData();
  }, []);

  let simbolos = null;
  if (selectedNode.simbolos) {
    simbolos = selectedNode.simbolos.map((simbolo) => {
      return (<h5 key={simbolo.id}><Badge color="primary" className="mr-2" pill>{ simbolo.nombre }</Badge></h5>)
    });
  }

  const getTreeData = () => {
    //Determinar rol (soñante o analista)
    let endpoint =  `/cupido/clustering_jerarquico/${params.idCluster}/`;
    if(sessionStorage.rol === 'Analista') endpoint = `/cupido/paciente/${params.idPaciente}/clustering_jerarquico/${params.idCluster}/`;
    //Hacer get
    props.authAxios.get(endpoint)
    .then((Response) => {
      let treeData = Response.data['arbol'];
      treeData = transform(treeData);
      setTree(treeData);
      setCargando(false);
    })
    .catch((error) => {
        //No debería darse nunca, dirigirse a InternalServerError
        history.push('/ErrorInterno')
    });
  }

  const toggleDrawer = () => {
    setDrawer(!drawer);
  }

  const toggleVerSuenioModal = () => {
    setVerSuenioModal(!verSuenioModal);
  }

  const onNodeClick = (node) => {
    if (!node.children) {
      cargarSuenioSeleccionado(node.id);
    } else if (node.name || node.analisis || node.elementos || (node.simbolos && node.simbolos.length > 0) ) {
      setSelectedNode(node);
      toggleDrawer();
    }
  }

  //Recibe el suenio seleccionado, la descripción ya procesada y la fecha ya convertida correctamente.
  const cargarSuenioSeleccionado = (id) => {
    let suenio = null;
    //Determinar rol (soñante o analista)
    let endpoint = `/suenios/${id}/`;
    //Hacer get del sueño
    props.authAxios.get(endpoint)
    .then((Response) => {
      suenio = Response.data;
      //Sacar los símbolos utilizados para ocultar información del sueño
      let desc = suenio.descripcion.replace(/<\^/g, '').replace(/\^>/g, '');
      //Fecha en formato lindo
      const fecha = new Intl.DateTimeFormat("es-ES", {year: "numeric", month: "short", day: "2-digit"}).format(new Date(suenio.fecha + 'T00:00:00'));

      //Setear el sueño seleccionado
      let suenioSeleccionado = {};
      suenioSeleccionado.id = suenio.id;
      suenioSeleccionado.descripcion = desc;
      suenioSeleccionado.nombre = suenio.nombre;
      suenioSeleccionado.fecha = fecha;
      suenioSeleccionado.es_pasivo = suenio.es_pasivo;
      suenioSeleccionado.paciente = suenio.paciente;
      suenioSeleccionado.simbolos = suenio.simbolos;
      suenioSeleccionado.sentimientos = suenio.sentimientos;
      suenioSeleccionado.contextos = suenio.contextos;
      setSuenioSeleccionado(suenioSeleccionado);
      toggleVerSuenioModal();
    })
    .catch((error) => {
      //No debería darse nunca, dirigirse a InternalServerError
      history.push('/ErrorInterno');
    });
  }

  if (cargando) {
    return (
      <div className="mt-2">
        <Spinner style={{ width: '3rem', height: '3rem' }} />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <MuiAlert icon={<InfoIcon />} severity="info" className="mt-2" onClose={() => setInstrucciones(false)} hidden={!instrucciones}>
        <strong>Instrucciones:</strong> Puedes mover el árbol con el mouse, hacer zoom con la rueda y hacer click en los nodos para expandir o contraer el árbol.
      </MuiAlert>
      <Paper variant="outlined" style={{ height: '75vh' }} className='my-2'>
        <Tree
          data={tree}
          separation={{ siblings: 1, nonSiblings: 1 }}
          nodeSize={{ x: 400, y: 200 }}
          translate={{ x:10, y: window.innerHeight/2 }}
          onNodeClick={(node) => onNodeClick(node.data, history)}
          zoom={0.3}
        />
      </Paper>
      <Modal isOpen={verSuenioModal} toggle={toggleVerSuenioModal}>
          <ModalHeader toggle={toggleVerSuenioModal}>{suenioSeleccionado.nombre ? suenioSeleccionado.nombre : "Sueño " + suenioSeleccionado.id}<p className='letraChica'>{suenioSeleccionado.fecha} - <a href={sessionStorage.rol === 'Soñante'? `/home/suenios/${suenioSeleccionado.id}/` : `/home/pacientes/${params.idPaciente}/suenios/${suenioSeleccionado.id}/`}>Ver resumen</a></p></ModalHeader>
          <ModalBody>
            <VerSuenio suenio={suenioSeleccionado} authAxios={props.authAxios}/>
          </ModalBody>
        </Modal>
        <SwipeableDrawer
          anchor={'right'}
          open={drawer}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          <div className="ml-4 mr-4 mt-4 mb-4" style={{maxWidth: '40vw'}}>
            <h3>{selectedNode ? selectedNode.name : ""}</h3>
            <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{selectedNode ? selectedNode.analisis : ""}</p>
            <h6>Elementos</h6>
            <p className="text-justify" style={{'whiteSpace': 'pre-line'}}>{selectedNode ? selectedNode.elementos : ""}</p>
            <h6>Símbolos</h6>
            {simbolos && simbolos.length > 0 ? <Form inline>{simbolos}</Form> : <p>El nodo no tiene símbolos asociados</p>}
          </div>
        </SwipeableDrawer>
    </div>
  );
}

export default ArbolJerarquico;